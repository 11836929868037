import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UserConfigurationsService } from '../user-configurations.service';
import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { TokenService } from 'src/app/core/token/token.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './config-delete-account.component.html',
    styleUrls: ['./config-delete-account.component.scss']
})
export class ConfigDeleteAccountComponent implements OnInit {
    configForm: FormGroup;
    
    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastService: ToastService,
        private tokenService: TokenService,
        private userStorageService: UserStorageService,
        private userPreferencesService: UserPreferencesService,
        private userConfigurationsService: UserConfigurationsService) { }

    ngOnInit(): void {
        this.setFormValidations();
    }

    deleteAccount(){
        let password = this.configForm.get('password').value;

        this.userConfigurationsService.deleteAccount(password).subscribe(
            () => {
                this.tokenService.removeToken();
                this.userStorageService.removeUser();
                this.userPreferencesService.removeUserPreferences();
                this.toastService.success('Conta removida com sucesso.');
                this.router.navigate(['web', 'signin']);
            }
        )
    }

    setFormValidations() {
        this.configForm = this.formBuilder.group({
            password: ['', [Validators.required]]
        })
    }
}