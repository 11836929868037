import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class SearchMusiciansService {

    constructor(private http: HttpClient){}

    getAllInstruments(){
        return this.http.get(`instruments`);
    }

    search(idCity:string, instrument:string){
        let queryParams = new HttpParams()
        .set('idCity', idCity)
        .set('instrument', instrument);

        return this.http.get('user/searchuserbyinstrument', {params: queryParams});
    }
}