import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialLoginModule, AuthServiceConfig, LoginOpt } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";

const fbLoginOptions: LoginOpt = {
    scope: 'email, user_birthday',
    return_scopes: true,
    enable_profile_selector: true,
    redirect_uri: 'https://www.facebook.com/connect/login_success.html'    
  };

let config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("1633976300245651", fbLoginOptions)
    }
  ]);

  export function provideConfig() {
    return config;
  }

@NgModule({
    imports:[
        CommonModule,
        SocialLoginModule
    ],
    providers:[
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ]   
})
export class FacebookAuthModule{

}