import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EventModel } from 'src/app/core/models/event';
import { DateEventDto } from 'src/app/core/dtos/dateEventDto';

import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { UserPreferences } from 'src/app/core/user-preferences/user-preferences';
import { HomeUserEventsService } from './home-user-events.service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { LocationModalService } from 'src/app/shared/modals/components/location-modal/location-modal.service';
import { GooglePlaceDto } from 'src/app/core/dtos/google-place-dto';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { HomeUserFiltersModalService } from '../home-user-filters-modal/home-user-filters-modal.service';

@Component({
    selector: 'mscope-home-user-events',
    templateUrl: './home-user-events.component.html',
    styleUrls: ['./home-user-events.component.scss']
})
export class HomeUserEventsComponent implements OnInit, OnDestroy {
    dateEventList: DateEventDto[] = [];
    userPreferences: UserPreferences;
    changeLocationSubscription:Subscription;
    changeFiltersSubscription:Subscription;
    isScreenLargerThan710: boolean = false;

    constructor(
        public translate:TranslateService,
        private userStorageService:UserStorageService,
        private userPreferencesService: UserPreferencesService,
        private homeUserEventsService: HomeUserEventsService,
        private router:Router,
        private modalsService: ModalsService) { }

    ngOnInit(): void {
        this.userPreferences = this.userPreferencesService.getUserPreferences();
        this.loadEvents(this.userPreferences.idCity, this.userPreferences.filterByGenresLike ? this.userPreferences.genresValue : null, this.userPreferences.filterIncludeLives);
        this.subscribeChangeLocation();
        this.subscribeChangeFilters();
        this.isScreenLargerThan710 = window.innerWidth > 710;

    window.addEventListener("resize", () => {
      this.isScreenLargerThan710 = window.innerWidth > 710;
    });
    };

    ngOnDestroy(): void {
        
    };

    private loadEvents(idCity:string, genres:string[], includeLives: boolean){
        this.homeUserEventsService.getEventsList(idCity, genres, includeLives)
            .subscribe(
                (response: ScopeListDto<EventModel>) => {
                    this.dateEventList = this.homeUserEventsService.generateEventList(response);
                }
            );        
    }

    refresh(){    
        this.loadEvents(this.userPreferences.idCity, this.userPreferences.filterByGenresLike ? this.userPreferences.genresValue : null, this.userPreferences.filterIncludeLives);
    }

    openModalChangeLocation(){
      this.modalsService.openLocationModal();        
    }
    
    subscribeChangeLocation(){
        this.changeLocationSubscription = LocationModalService.cityChangedEvent.subscribe((city:GooglePlaceDto) => {
            this.userPreferencesService.setCity(city.id, city.description);
            this.updateUserPreferences();
            this.modalsService.closeModal();
            this.refresh();            
        })
    }

    subscribeChangeFilters(){
        this.changeFiltersSubscription = HomeUserFiltersModalService.filtersChangedEvent.subscribe(() => {
            console.log('foi');
            this.updateUserPreferences();
            this.modalsService.closeModal();
            this.refresh();            
        })
    }

    updateUserPreferences(){
        this.userPreferences = this.userPreferencesService.getUserPreferences();
    }

    openEvent(id:number){
        this.router.navigate(['web', 'home', 'event', id]);
    }

    openFilters(){
        this.modalsService.eventsFilter(true);
    }
}