import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    language:string

    constructor(public translate:TranslateService){}

    ngOnInit(): void {
        this.language = this.translate.currentLang;
    }
}