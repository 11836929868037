import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateService } from 'src/app/shared/helpers/date.service';
import { EventService } from '../event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventModel } from 'src/app/core/models/event';
import { ProjectService } from 'src/app/project/project.service';
import { Project } from 'src/app/core/models/project';
import { StringService } from 'src/app/shared/helpers/string.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    styleUrls: ['./event-import.component.scss'],
    templateUrl: './event-import.component.html'
})
export class EventImportComponent {
    eventForm: FormGroup;
    options: object;
    projectId: number;
    genres:string;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private eventService: EventService,
        private projectService:ProjectService,
        private stringService:StringService,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.projectId = +params['id'];
            this.getProject();
        });

        this.setFormValidations();

        this.options = {
            types: ['(cities)']
        }
    }


    setFormValidations() {
        this.eventForm = this.formBuilder.group({
            name: [{value: '', disabled: true}, [Validators.required]],
            phone: [''],
            cost: ['0'],
            eventLive: [false],
            url: [''],
            contactEmail: ['', [Validators.email]],
            startDate: [new Date(), [Validators.required]],
            startHour: ['12:00', [Validators.required]],
            endDate: [new Date(), [Validators.required]],
            endHour: ['12:01', [Validators.required]],
            description: [{value: '', disabled: true}],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            address: [''],
            neighborhood: [''],
            cep: [''],
            place: ['']
        })
    }

    getProject(){
        this.projectService.getProjectDetail(this.projectId).subscribe(
            (project:Project) => {
                this.eventForm.get('name').patchValue(project.name);
                this.eventForm.get('description').patchValue(project.description);
                this.genres = this.stringService.joinGenres(project.genres.map(x => x.name));
            }
        )
    }

    import(){
        let event = this.generateEvent();
        this.eventService.importEvent(this.projectId, event).subscribe(
                (event: EventModel) => {
                    this.router.navigate(['web', 'home', 'event', event.id]);
                }
            )
    }

    generateEvent(): EventModel {
        let event: EventModel = this.eventForm.getRawValue();                
        event.eventDate = this.dateService.joinDateAndTime(
            this.eventForm.get('startDate').value,
            this.eventForm.get('startHour').value
        );

        event.endDate = this.dateService.joinDateAndTime(
            this.eventForm.get('endDate').value,
            this.eventForm.get('endHour').value
        );

        if(!this.eventForm.get('cost').value){
            event.cost = '0';
        }

        event.eventTypeEnum = this.eventForm.get('eventLive').value ? 2 : 1;

        return event;
    }

    handleAddressChange(param) {
        this.eventForm.get('idCity').patchValue(param.place_id);
        this.eventForm.get('descriptionCity').patchValue(param.formatted_address);
    }

    resetCityData() {
        this.eventForm.get('idCity').patchValue('');
        this.eventForm.get('descriptionCity').patchValue('');
    }
}