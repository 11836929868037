import { Component, OnInit, OnDestroy } from '@angular/core';
import { Service } from 'src/app/core/models/service';
import { Gallery } from 'src/app/core/models/gallery';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ServiceService } from '../service.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { TypeMediaEnum } from 'src/app/shared/enums/type-media-enum';
import { AdTypeEnum } from 'src/app/shared/enums/ad-type-enum';
import { AdsService } from '../../ads.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';

@Component({
    templateUrl: './service-detail.component.html',
    styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
    author: boolean = false;
    id: number;
    service: Service;
    images: Gallery[];
    videos: Gallery[];
    rowImages: any[] = [];
    expirationDate:string;

    updateProfileSubscription: Subscription;
    renewSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private adsService:AdsService,
        private serviceService: ServiceService,
        private userStorageService: UserStorageService,
        private toastService: ToastService,
        private uploadImageModalService: UploadImageModalService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.loadServiceDetails();
        });
    }

    ngOnDestroy(): void {
        if (this.updateProfileSubscription) {
            this.updateProfileSubscription.unsubscribe();
        }        

        if (this.renewSubscription) {
            this.renewSubscription.unsubscribe();
        }
    }

    loadServiceDetails() {
        this.serviceService.getServiceDetail(this.id).subscribe(
            (service: Service) => {
                this.author = service.author!.id == this.userStorageService.getUser().id;                
                this.service = service;                
                this.expirationDate = moment(this.service.expirationDate).format("DD/MM/YYYY HH:mm");
                this.images = service.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.image).slice(-4);

                if (this.images) {
                    this.rowImages = this.groupColumns(this.images);
                }

                this.videos = service.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.video);
            }
        )
    }

    groupColumns(photos: Gallery[]) {
        const newRows = [];

        for (let index = 0; index < photos.length; index += 2)
            newRows.push(photos.slice(index, index + 2));

        return newRows;
    }

    editService() {
        this.router.navigate(['web', 'home', 'ads', 'service', this.id, 'edit']);
    }

    deleteService() {
        let title = this.translate.instant('ExcluirServico');
        let text = this.translate.instant('PerguntaExcluirServico');
        
        this.modalService.openDeleteModal(title, text, DeleteTypeEnum.service, this.id, ['web', 'home', 'ads', 'list']);
    }

    changeProfileImage(){
        if(!this.author) return;
        
        this.updateProfileSubscription =  this.uploadImageModalService.updateProfileImageEvent.subscribe((blobImage:Blob) => {
            this.serviceService.updateProfileImage(this.id, blobImage).subscribe(
                () => {
                    this.loadServiceDetails();
                }
            );
        });

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemPerfil'), 4/3, UploadImageTypeEnum.profile);
    }

    openImage(id:number){
        this.router.navigate(['web', 'home', 'ads', 'service', this.id, 'image', id]);
    }

    openImages(){
        this.router.navigate(['web', 'home', 'ads', 'service', this.id, 'images']);
    }

    openVideo(id:number){
        this.router.navigate(['web', 'home', 'ads', 'service', this.id, 'video', id]);
    }

    openVideos(){
        this.router.navigate(['web', 'home', 'ads', 'service', this.id, 'videos']);
    }
    
    openSellerProfile(){
        this.router.navigate(['web', 'home','user', this.service.author.id]);
    }

    renew(){
        this.renewSubscription = this.adsService.renewServiceSubject.subscribe(() => {
            this.loadServiceDetails();
        });

        this.modalService.renewAd(this.id, AdTypeEnum.service, true);
    }

    complaint(){
        this.modalService.complaint(OwnerEnum.Service, this.id, true);
    }

    openUrl() {
        let website = this.service.website;
        
        if (website.substring(0, 4) !== 'http') {
            website = 'http://' + website;
        }

        window.open(website, '_blank');
    }
}