import { EventEmitter, Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class HomeUserFiltersModalService {
    static filtersChangedEvent = new EventEmitter<string>();

    constructor(){
        
    }

    changeFilters(){
        HomeUserFiltersModalService.filtersChangedEvent.emit();
    }
}