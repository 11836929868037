import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { ImagesService } from '../images.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Gallery } from 'src/app/core/models/gallery';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './create-edit-image.component.html',
    styleUrls: ['./create-edit-image.component.scss']
})
export class CreateEditImageComponent implements OnInit, OnDestroy {
    createImageSubscription: Subscription;
    blob: Blob;
    ownerId: number;
    ownerEnum: OwnerEnum;
    urlImage: string;
    imageForm:FormGroup;
    id:number;
    comeBackTitle:string;

    constructor(
        public translate:TranslateService,
        private uploadImageModalService: UploadImageModalService,
        private imagesService: ImagesService,
        private toastService: ToastService,
        private formBuilder:FormBuilder,
        private router: Router,
        private route:ActivatedRoute
    ) { }

    ngOnInit(): void {     
        this.route.params.subscribe(params => {            
            this.initForm();
            this.id = +params['id'];
            this.ownerId = +params['ownerId'];            
            if(this.id){                
                this.imagesService.get(this.id, this.getOwnerEnum()).subscribe(
                    (response:Gallery) => {
                        this.urlImage = response.urlMedia;
                        this.ownerEnum = this.getOwnerEnum();
                        this.getComeBackTitle();
                        this.imageForm.get('description').patchValue(response.description);
                    }
                )
            }else{                
                this.createImageSubscription = this.uploadImageModalService.createImageToGallerySubject.subscribe(
                    (response: any) => {
                        this.blob = response.blob;
                        this.ownerEnum = response.ownerEnum;
                        this.getComeBackTitle();
                        this.urlImage = response.base64Image;
                    });
            }            
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getComeBackTitle();
        })
    }

    ngOnDestroy(): void {
        
    }

    initForm(){
        this.imageForm = this.formBuilder.group({
            description: ['']
        });
    }

    createOrUpdate() {
        let description = this.imageForm.get('description').value;

        if(this.id){
            this.imagesService.edit(this.id, this.getOwnerEnum(), description).subscribe(
                (response:any) => {
                    this.toastService.success('Imagem editada com sucesso');
                    let url = this.getBaseRedirectionUrl();
                    url.push('images');                
                    this.router.navigate(url);
                }
            )
        }else{
            this.imagesService.create(this.ownerId, this.ownerEnum, description, this.blob).subscribe(
                () => {
                    this.toastService.success('Imagem salva com sucesso');                    
                    let url = this.getBaseRedirectionUrl();
                    url.push('images');                
                    this.router.navigate(url);                    
                }
            );
        }        
    }

    private getOwnerEnum(){
        let url = this.router.url;
        if(url.indexOf('event') > -1){
            return OwnerEnum.Event;
        }else if(url.indexOf('project') > -1){
            return OwnerEnum.Project;
        }else if(url.indexOf('group') > -1){
            return OwnerEnum.Group;
        }else if(url.indexOf('product') > -1){
            return OwnerEnum.Product;
        }else if(url.indexOf('service') > -1){
            return OwnerEnum.Service;
        }else{
            return OwnerEnum.User;
        }
    }

    private getBaseRedirectionUrl(): string[] {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return ['web', 'home', 'event', this.ownerId.toString()];
            case OwnerEnum.Project:
                return ['web', 'home', 'project', this.ownerId.toString()];
            case OwnerEnum.Group:
                return ['web', 'home', 'group', this.ownerId.toString()];
            case OwnerEnum.Service:
                return ['web', 'home', 'ads', 'service', this.ownerId.toString()];
            case OwnerEnum.Product:
                return ['web', 'home', 'ads', 'product', this.ownerId.toString()];
            case OwnerEnum.User:
                return ['web', 'home', 'user', this.ownerId.toString()];
        }
    }

    getComeBackTitle() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.translate.get("VoltarEvento").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Project:
                this.translate.get("VoltarProjeto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Group:
                this.translate.get("VoltarGrupo").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Service:
                this.translate.get("VoltarServico").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Product:
                this.translate.get("VoltarProduto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.User:
                this.translate.get("VoltarUsuario").subscribe(x => this.comeBackTitle =  x);
        }
    }

    comeBack() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.router.navigate(['web', 'home', 'event', this.ownerId]);
                break;
            case OwnerEnum.Project:
                this.router.navigate(['web', 'home', 'project', this.ownerId]);
                break;
            case OwnerEnum.Group:
                this.router.navigate(['web', 'home', 'group', this.ownerId]);
                break;
            case OwnerEnum.Service:
                this.router.navigate(['web', 'home', 'ads', 'service', this.ownerId]);
                break;
            case OwnerEnum.Product:
                this.router.navigate(['web', 'home', 'ads', 'product', this.ownerId]);
                break;
            case OwnerEnum.User:
                this.router.navigate(['web', 'home', 'user', this.ownerId]);
                break;
        }
    }
}