import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventModel } from 'src/app/core/models/event';
import { DateService } from 'src/app/shared/helpers/date.service';
import { EventService } from '../event.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { StringService } from 'src/app/shared/helpers/string.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './event-new-edit.component.html',
    styleUrls: ['./event-new-edit.component.scss']
})
export class EventNewEditComponent implements OnInit {
    eventForm: FormGroup;
    options: object;
    id: number;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private eventService: EventService,
        private stringService: StringService,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();

        if (this.id) {
            this.getEvent(this.id);
        }

        this.options = {
            types: ['(cities)']
        }
    }

    setFormValidations() {
        this.eventForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            phone: [''],
            cost: ['0'],
            eventLive: [false],
            url: [''],
            contactEmail: ['', [Validators.email]],
            startDate: [new Date(), [Validators.required]],
            startHour: ['12:00', [Validators.required]],
            endDate: [new Date(), [Validators.required]],
            endHour: ['12:01', [Validators.required]],
            description: [''],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            address: [''],
            neighborhood: [''],
            cep: [''],
            place: ['']
        })
    }

    saveOrEdit() {
        if (this.id) {
            this.eventService.editEvent(this.id, this.generateEvent())
                .subscribe((event: EventModel) => {
                    this.router.navigate(['web', 'home', 'event', this.id]);
                });
        } else {
            this.eventService.createEvent(
                this.generateEvent()).subscribe(
                    (event: EventModel) => {
                        this.router.navigate(['web', 'home', 'event', event.id, 'genres']);
                    }
                )
        }
    }

    handleAddressChange(param) {
        console.log(param);
        this.eventForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.eventForm.get('descriptionCity').patchValue(city);
        this.eventForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.eventForm.get('idCity').patchValue('');
        this.eventForm.get('descriptionCity').patchValue('');
    }

    generateEvent(): EventModel {
        let event: EventModel = this.eventForm.getRawValue();
        event.eventDate = this.dateService.joinDateAndTime(
            this.eventForm.get('startDate').value,
            this.eventForm.get('startHour').value
        );

        event.endDate = this.dateService.joinDateAndTime(
            this.eventForm.get('endDate').value,
            this.eventForm.get('endHour').value
        );

        if (!this.eventForm.get('cost').value) {
            event.cost = '0';
        }

        event.eventTypeEnum = this.eventForm.get('eventLive').value ? 2 : 1;

        return event;
    }

    getEvent(id: number) {
        this.eventService.getEventForEdit(id).subscribe(
            (event: EventModel) => {
                this.setValuesInForm(event);
            }
        )
    }

    setValuesInForm(event: EventModel) {
        this.eventForm.patchValue({
            ...event,
            startDate: event.eventDate,
            startHour: moment(event.eventDate).format('HH:mm'),
            endHour: moment(event.endDate).format('HH:mm'),
            city: event.descriptionCity,
            eventLive: event.eventTypeEnum == 2
        });
    }
}