import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { Message } from 'src/app/core/models/message';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { MessageService } from '../message.service';

@Component({
    templateUrl: './message-detail.component.html',
    styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {
    messages: Message[] = [];
    userId: number;
    ownerId: number;
    messageForm: FormGroup;

    @ViewChildren("messageDiv") messageDivs: QueryList<ElementRef>;

    constructor(
        public translate:TranslateService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private userStorageService: UserStorageService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.userId = +params['id'];
            this.ownerId = this.userStorageService.getUser().id;
        });

        this.loadMessages();
        this.initForm();
    }

    loadMessages() {
        this.messageService.getMessageUser(this.userId).subscribe(
            (response: ScopeListDto<Message>) => {
                this.messages = response.items.sort((d1, d2) => {
                    return d1.creationDate > d2.creationDate ? 1 : -1;
                });;
                
                this.messageDivs.changes.subscribe(() => {
                    if (this.messageDivs && this.messageDivs.last) {
                        this.messageDivs.last.nativeElement.scrollIntoView();
                    }
                  });

            }
        )
    }

    sendMessage() {
        let message = this.messageForm.get('message').value;
        if (!message || message == '') return;

        this.messageService.sendMessage(this.userId, message).subscribe(
            (message: Message) => {
                this.messages.push(message);
                this.messageForm.get('message').patchValue('');
                this.messageDivs.changes.subscribe(() => {
                    if (this.messageDivs && this.messageDivs.last) {
                        this.messageDivs.last.nativeElement.scrollIntoView();
                    }
                });
            }
        )
    }

    initForm() {
        this.messageForm = this.formBuilder.group({
            message: [''],
        });
    }

    openUser() {
        this.router.navigate(['web', 'home', 'user', this.userId]);
    }

    removeMessage(messageId: number) {
        this.messageService.removeMessage(messageId).subscribe(
            (response) => {
                let index = this.messages.findIndex(x => x.id == messageId);
                this.messages.splice(index, 1);
            }
        )
    }

    messageNotIsFromOwner(id: number): boolean {
        return this.ownerId == id;
    }
}