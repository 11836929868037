import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeUserComponent } from './home-user.component';
import { EventDetailComponent } from '../event/event-detail/event-detail.component';
import { EventListComponent } from '../event/event-list/event-list.component';
import { EventNewEditComponent } from '../event/event-new-edit/event-new-edit.component';
import { GenreComponent } from '../genre/genre.component';
import { AuthHomeUserGuard } from '../core/auth/auth-home-user.guard';
import { ImageListComponent } from '../shared/components/images/image-list/image-list.component';
import { ViewImageComponent } from '../shared/components/images/view-image/view-image.component';
import { CreateEditImageComponent } from '../shared/components/images/create-edit-image/create-edit-image.component';
import { VideoListComponent } from '../shared/components/videos/video-list/video-list.component';
import { WatchVideoComponent } from '../shared/components/videos/watch-video/watch-video.component';
import { CreateVideoComponent } from '../shared/components/videos/create-video/create-video.component';
import { ProjectListComponent } from '../project/project-list/project-list.component';
import { ProjectNewEditComponent } from '../project/project-new-edit/project-new-edit.component';
import { ProjectDetailComponent } from '../project/project-detail/project-detail.component';
import { EventImportComponent } from '../event/event-import/event-import.component';
import { GroupListComponent } from '../group/group-list/group-list.component';
import { GroupNewEditComponent } from '../group/group-new-edit/group-new-edit.component';
import { GroupDetailComponent } from '../group/group-detail/group-detail.component';
import { UserEditComponent } from '../user/user-edit/user-edit.component';
import { UserDetailComponent } from '../user/user-detail/user-detail.component';
import { UserInstrumentsComponent } from '../user/user-instruments/user-instruments.component';
import { ServiceNewEditComponent } from '../ads/services/service-new-edit/service-new-edit.component';
import { ServiceDetailComponent } from '../ads/services/service-detail/service-detail.component';
import { ProductNewEditComponent } from '../ads/products/product-new-edit/product-new-edit.component';
import { ProductDetailComponent } from '../ads/products/product-detail/product-detail.component';
import { AdsListComponent } from '../ads/ads-list/ads-list.component';
import { EventStoredListComponent } from '../event/event-stored-list/event-stored-list.component';
import { UserFavoriteListComponent } from '../user/user-favorite-list/user-favorite-list.component';
import { FilterSearchMusiciansComponent } from '../search-musicians/filter-search-musicians/filter-search-musicians.component';
import { ResultSearchMusiciansComponent } from '../search-musicians/result-search-musicians/result-search-musicians.component';
import { UserConfigurationsComponent } from '../user-configurations/user-configurations.component';
import { ConfigChangeEmailComponent } from '../user-configurations/config-change-email/config-change-email.component';
import { ConfigChangePasswordComponent } from '../user-configurations/config-change-password/config-change-password.component';
import { ConfigDeleteAccountComponent } from '../user-configurations/config-delete-account/config-delete-account.component';
import { ConfigReportErrorComponent } from '../user-configurations/config-report-error/config-report-error.component';
import { ConfigBlockedListComponent } from '../user-configurations/config-blocked-list/config-blocked-list.component';
import { NotificationListComponent } from '../notification/notification-list/notification-list.component';
import { MusicalAffinitiesComponent } from '../musical-affinities/musical-affinities.component';
import { BusinessPageComponent } from '../business/business-page/business-page.component';
import { UserGalleryComponent } from '../user-gallery/user-gallery.component';
import { MessageListComponent } from '../message/message-list/message-list.component';
import { MessageDetailComponent } from '../message/message-detail/message-detail.component';
import { PaymentDoneComponent } from '../shared/components/payment-done/payment-done.component';

const routes: Routes = [
  {
    path: '',
    component: HomeUserComponent
  },
  {
    path: 'event',
    canActivate: [AuthHomeUserGuard],
    children: [
      {
        path: 'list',
        component: EventListComponent
      },
      {
        path: 'new',
        component: EventNewEditComponent
      },
      {
        path: ':id/edit',
        component: EventNewEditComponent
      },
      {
        path: ':id/genres',
        component: GenreComponent
      },
      {
        path: ':id/images',
        component: ImageListComponent
      },
      {
        path: ':ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: ':ownerId/videos',
        component: VideoListComponent
      },
      {
        path: ':ownerId/video/new',
        component: CreateVideoComponent
      },
      {
        path: ':ownerId/video/:id',
        component: WatchVideoComponent
      },
      {
        path: 'import/:id',
        component: EventImportComponent
      },
      {
        path: 'stored-events',
        component: EventStoredListComponent
      },
      {
        path: ':id',
        component: EventDetailComponent
      }
    ]
  },
  {
    path: 'project',
    canActivate: [AuthHomeUserGuard],
    children: [      
      {
        path: 'list',
        component: ProjectListComponent
      },
      {
        path: 'new',
        component: ProjectNewEditComponent
      },
      {
        path: ':id/edit',
        component: ProjectNewEditComponent
      },
      {
        path: ':id/genres',
        component: GenreComponent
      },      
      {
        path: ':id/images',
        component: ImageListComponent
      },
      {
        path: ':ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: ':ownerId/videos',
        component: VideoListComponent
      },
      {
        path: ':ownerId/video/new',
        component: CreateVideoComponent
      },
      {
        path: ':ownerId/video/:id',
        component: WatchVideoComponent
      },
      {
        path: ':id',
        component: ProjectDetailComponent
      }
    ]
  },
  {
    path: 'group',
    canActivate: [AuthHomeUserGuard],
    children: [      
      {
        path: 'list',
        component: GroupListComponent
      },
      {
        path: 'new',
        component: GroupNewEditComponent
      },
      {
        path: ':id/edit',
        component: GroupNewEditComponent
      },
      {
        path: ':id/genres',
        component: GenreComponent
      },
      {
        path: ':id/images',
        component: ImageListComponent
      },
      {
        path: ':ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: ':ownerId/videos',
        component: VideoListComponent
      },
      {
        path: ':ownerId/video/new',
        component: CreateVideoComponent
      },
      {
        path: ':ownerId/video/:id',
        component: WatchVideoComponent
      },
      {
        path: ':id',
        component: GroupDetailComponent
      }
    ]
  },
  {
    path: 'user',
    canActivate: [AuthHomeUserGuard],
    children: [      
      {
        path: ':id/edit',
        component: UserEditComponent
      },
      {
        path: ':id/genres',
        component: GenreComponent
      },
      {
        path: ':id/genres-user-like',
        component: GenreComponent
      },
      {
        path: ':id/work-genres',
        component: GenreComponent
      },
      {
        path: ':id/gallery',
        component: UserGalleryComponent
      },
      {
        path: ':id/images',
        component: ImageListComponent
      },
      {
        path: ':ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: ':ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: ':ownerId/videos',
        component: VideoListComponent
      },
      {
        path: ':ownerId/video/new',
        component: CreateVideoComponent
      },
      {
        path: ':ownerId/video/:id',
        component: WatchVideoComponent
      },
      {
        path: ':id/instruments',
        component: UserInstrumentsComponent
      },
      {
        path: 'favorites',
        component: UserFavoriteListComponent
      },
      {
        path: ':id',
        component: UserDetailComponent
      }
    ]
  },
  {
    path: 'ads',
    canActivate: [AuthHomeUserGuard],
    children: [
      {
        path: 'all',
        component: BusinessPageComponent
      },
      {
        path: 'list',
        component: AdsListComponent
      },      
      {
        path: 'service/new',
        component: ServiceNewEditComponent
      },
      {
        path: 'service/:id/edit',
        component: ServiceNewEditComponent
      },
      {
        path: 'service/:id/images',
        component: ImageListComponent
      },
      {
        path: 'service/:ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: 'service/:ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: 'service/:ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: 'service/:ownerId/videos',
        component: VideoListComponent
      },
      {
        path: 'service/:ownerId/video/new',
        component: CreateVideoComponent
      },
      {
        path: 'service/:ownerId/video/:id',
        component: WatchVideoComponent
      },
      {
        path: 'service/:id',
        component: ServiceDetailComponent
      },
      {
        path: 'product/new',
        component: ProductNewEditComponent
      },
      {
        path: 'product/:id/edit',
        component: ProductNewEditComponent
      },
      {
        path: 'product/:id/images',
        component: ImageListComponent
      },
      {
        path: 'product/:ownerId/image/new',
        component: CreateEditImageComponent
      },
      {
        path: 'product/:ownerId/image/:id/edit',
        component: CreateEditImageComponent
      },
      {
        path: 'product/:ownerId/image/:id',
        component: ViewImageComponent
      },
      {
        path: 'product/:id',
        component: ProductDetailComponent
      }
    ]
  },
  {
    path: 'search-musicians',
    canActivate: [AuthHomeUserGuard],
    children: [
      {
        path: 'filter',
        component: FilterSearchMusiciansComponent
      },
      {
        path: 'result',
        component: ResultSearchMusiciansComponent
      }
    ]
  },
  {
    path: 'settings',
    canActivate: [AuthHomeUserGuard],
    children: [
      {
        path: '',
        component: UserConfigurationsComponent
      },
      {
        path: 'blockeds',
        component: ConfigBlockedListComponent
      },
      {
        path: 'change-email',
        component: ConfigChangeEmailComponent
      },
      {
        path: 'change-password',
        component: ConfigChangePasswordComponent
      },
      {
        path: 'delete-account',
        component: ConfigDeleteAccountComponent
      },
      {
        path: 'report-error',
        component: ConfigReportErrorComponent
      }
    ]
  }, 
  {
    path: 'notifications',
    component: NotificationListComponent,
    canActivate: [AuthHomeUserGuard],
  },
  {
    path: 'musical-affinities',
    component: MusicalAffinitiesComponent,
    canActivate: [AuthHomeUserGuard],
  },
  {
    path: 'musical-affinities/genres',
    component: GenreComponent,
    canActivate: [AuthHomeUserGuard],
  },
  {
    path: 'message',
    component: MessageListComponent,
    canActivate: [AuthHomeUserGuard],
  },
   {
    path: 'message/:id',
    component: MessageDetailComponent,
    canActivate: [AuthHomeUserGuard],
  },
  {
    path: 'payment-done',
    component: PaymentDoneComponent,
    canActivate: [AuthHomeUserGuard],
  }
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeUserRoutingModule { }