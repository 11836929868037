import { Injectable, EventEmitter } from '@angular/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UploadImageModalService {
    updateCoverImageEvent = new EventEmitter<Blob>();
    updateProfileImageEvent = new EventEmitter<Blob>();
    createImageToGallerySubject = new BehaviorSubject(null);

    constructor(){
        
    }

    
    createImageToGallery(ownerId:number, ownerEnum:OwnerEnum, base64Image: string){
      let blob = this.dataURItoBlob(base64Image);
      this.createImageToGallerySubject.next({
        ownerId,
        ownerEnum,
        base64Image,
        blob
      });
    }

    updateCoverImage(base64Image: string){
        let blob = this.dataURItoBlob(base64Image);
        this.updateCoverImageEvent.emit(blob);
    }

    updateProfileImage(base64Image: string){
      let blob = this.dataURItoBlob(base64Image);
      this.updateProfileImageEvent.emit(blob);
    }

    private dataURItoBlob(dataURI) {
        let block = dataURI.split(";");
        let contentType = block[0].split(":")[1];
        let realData = block[1].split(",")[1];

        const byteString = atob(realData);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: contentType });    
        return blob;
     }
}