export enum NotificationEnum {
    savedEventEdited = 1,
    eventCancelled = 2,
    userAddedImage = 3,
    userAddedAudio = 4,
    userAddedVideo = 5,
    userEditedBiography = 6,
    userAddedProduct = 7,
    userAddedService = 8,
    userAddedGroup = 9,
    userAddedProject = 10,
    userAddedEvent = 11,
    userChangedProfilePicture = 12,
    userCommentedPost = 13,
    userLikedPost = 14,
    userSentMessage = 15,
    userFollowed = 16,
    userAddedYouOnEvent = 17,
    userAddedYouOnGroup = 18, 
    userAddedYouOnProject = 19,
    eventWillHappen = 20,
    savedEventWillHappen = 21,
    adExpiring = 22,
    userInactive = 23,
    groupAddedOnEvent = 24,
    groupAddedOnProject = 25,
    imageAddedOnProject = 26,
    videoAddedOnProject = 27,
    projectDescriptionChanged = 28,
    imageAddedOnEvent = 29,
    videoAddedOnEvent = 30
}