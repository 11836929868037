import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchMusiciansService } from '../search-musicians.service';
import { User } from 'src/app/core/models/user';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './result-search-musicians.component.html',
    styleUrls: ['./result-search-musicians.component.scss']
})
export class ResultSearchMusiciansComponent implements OnInit {
    users:User[];
    idCity:string;
    descriptionCity:string;
    instrument:string;
    descriptionInstrument: string;
    orderByNameAsc:boolean = false;
    orderByFollowersAsc:boolean = false;

    constructor(
        public translate:TranslateService,
        private router: Router,
        private activateRoute:ActivatedRoute,
        private searchMusiciansService:SearchMusiciansService){}

    ngOnInit(): void {
        this.activateRoute.queryParams.subscribe(params => {
            this.idCity = params['idCity'];
            this.descriptionCity = params['descriptionCity'];
            this.instrument = params['instrument'];
            this.descriptionInstrument = params['descriptionInstrument'];

            this.search(this.idCity, this.instrument);
        });
    }

    search(idCity:string, instrument:string){
        this.searchMusiciansService.search(idCity, instrument).subscribe(
            (response:ScopeListDto<User>) => {
                this.users = response.items;
            }
        )
    }

    openUser(id:number){
        this.router.navigate(['web', 'home', 'user', id]);
    }

    orderByName(){
        if(this.orderByNameAsc){
            this.users = this.users.sort((a, b) => a.fullName.localeCompare(b.fullName));
            this.orderByNameAsc = true;
        }else{
            this.users = this.users.sort((a, b) => b.fullName.localeCompare(a.fullName));
            this.orderByNameAsc = false;
        }
    }

    orderByFollower(){
        if(this.orderByFollowersAsc){
            this.users = this.users.sort((a, b) => a.numberFollowers - b.numberFollowers);
            this.orderByFollowersAsc = true;
        }else{
            this.users = this.users.sort((a, b) => b.numberFollowers - a.numberFollowers);
            this.orderByFollowersAsc = false;
        }
    }
}