import { Injectable } from '@angular/core';
import { UserPreferences } from './user-preferences';
import { User } from '../models/user';

const KEY = 'userPreferences';

@Injectable({providedIn: 'root'})
export class UserPreferencesService {
    setUserPreferences(user:User){
        let userPreferences:UserPreferences = new UserPreferences();
        userPreferences.idCity = user.idCity;
        userPreferences.descriptionCity = user.descriptionCity;
        userPreferences.filterByGenresLike = true;
        userPreferences.genresDescription = user.genresUserLike && user.genresUserLike.length > 0 ? user.genresUserLike!.map(x=> x.name) : null;
        userPreferences.genresValue = user.genresUserLike && user.genresUserLike.length > 0 ? user.genresUserLike!.map(x=> x.key) : null;
        userPreferences.filterIncludeLives = true;

        this.updateUserPreferences(userPreferences);
    }
    
    setCity(idCity: string, descriptionCity: string): void {
        let userPreferences = this.getUserPreferences();
        userPreferences.descriptionCity = descriptionCity;
        userPreferences.idCity = idCity;
        
        this.updateUserPreferences(userPreferences);
    }

    setFilterByGenresLike(filter:boolean): void {
        let userPreferences = this.getUserPreferences();
        userPreferences.filterByGenresLike = filter;
        this.updateUserPreferences(userPreferences);
    }

    setFilterIncludeLives(filter:boolean){
        let userPreferences = this.getUserPreferences();
        userPreferences.filterIncludeLives = filter;

        this.updateUserPreferences(userPreferences);
    }

    setGenres(genresDescription:string[], genresValue:string[]): void {
        let userPreferences = this.getUserPreferences();
        userPreferences.genresDescription = genresDescription;
        userPreferences.genresValue = genresValue;
        
        this.updateUserPreferences(userPreferences);
    }

    getUserPreferences(): UserPreferences {
        let userPreferences:UserPreferences = JSON.parse(window.localStorage.getItem(KEY));
        return userPreferences;
    }

    removeUserPreferences(): void{
        window.localStorage.removeItem(KEY);
    }



    private updateUserPreferences(userPreferences:UserPreferences){
        window.localStorage.removeItem(KEY);
        window.localStorage.setItem(KEY, JSON.stringify(userPreferences));
    }
}