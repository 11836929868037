import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../event.service';
import { EventModel } from 'src/app/core/models/event';
import { StringService } from 'src/app/shared/helpers/string.service';
import * as moment from 'moment';
import { Gallery } from 'src/app/core/models/gallery';
import { TypeMediaEnum } from 'src/app/shared/enums/type-media-enum';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { Subscription } from 'rxjs';
import { ParticipationEnum } from 'src/app/shared/enums/participation-enum';
import { ParticipationsService } from 'src/app/shared/modals/components/participations/participations.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';

@Component({
    selector: "mscope-event-detail",
    styleUrls: ['./event-detail.component.scss'],
    templateUrl: './event-detail.component.html'
})
export class EventDetailComponent implements OnInit, OnDestroy {
    author: boolean = false;
    id: number;
    event: EventModel;
    participations: string;
    genres: string;
    fullEventDate: string;
    fullAddress: string;
    images: Gallery[];
    rowImages: any[] = [];
    videos: Gallery[];
    cost: string;
    isLive: boolean;

    updateCoverSubscription: Subscription;
    updateProfileSubscription: Subscription;
    updateParticipationsSubscription: Subscription;

    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private eventService: EventService,
        private userStorageService: UserStorageService,
        private stringService: StringService,
        private toastService: ToastService,
        private participationService: ParticipationsService,
        private uploadImageModalService: UploadImageModalService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.loadEventDetails();
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.participations = this.getParticipations();
                this.genres = this.setGenres();
            });
        });
    }

    ngOnDestroy(): void {
        if (this.updateCoverSubscription) {
            this.updateCoverSubscription.unsubscribe();
        }

        if (this.updateProfileSubscription) {
            this.updateProfileSubscription.unsubscribe();
        }

        if (this.updateParticipationsSubscription) {
            this.updateParticipationsSubscription.unsubscribe();
        }
    }

    loadEventDetails() {
        this.eventService.getEventDetail(this.id).subscribe(
            (event: EventModel) => {
                this.author = event.author!.id == this.userStorageService.getUser().id;
                this.event = event;
                this.participations = this.getParticipations();
                this.setGenres();
                this.cost = parseFloat(this.event.cost).toFixed(2);
                this.fullEventDate = `${moment(this.event.eventDate).format("DD/MM HH:mm")} - ${moment(this.event.endDate).format("DD/MM HH:mm")}`
                this.fullAddress = this.getFullAddress();
                this.isLive = event.eventTypeEnum == 2;

                this.images = event.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.image).slice(-4);

                if (this.images) {
                    this.rowImages = this.groupColumns(this.images);
                }

                this.videos = event.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.video);
            }
        )
    }

    setGenres() {
        this.genres = this.event && this.event.genres && this.event.genres.length > 0 ? this.stringService.joinGenres(this.event.genres.map(x => x.name)) : this.translate.instant('NenhumGeneroVinculado');
        return this.genres;
    }

    getFullAddress(): string {
        let fullAddress = '';
        if (this.event.address && this.event.address != '') {
            fullAddress = `${fullAddress} ${this.event.address} - `;
        }

        if (this.event.neighborhood && this.event.neighborhood != '') {
            fullAddress = `${fullAddress} ${this.event.neighborhood}, `;
        }

        if (this.event.descriptionCity && this.event.descriptionCity != '') {
            fullAddress = `${fullAddress} ${this.event.descriptionCity}, `;
        }

        if (this.event.cep && this.event.cep != '') {
            fullAddress = `${fullAddress} CEP. ${this.event.cep}`;
        }

        return fullAddress;
    }

    groupColumns(photos: Gallery[]) {
        const newRows = [];

        for (let index = 0; index < photos.length; index += 2)
            newRows.push(photos.slice(index, index + 2));

        return newRows;
    }

    editEvent() {
        this.router.navigate(['web', 'home', 'event', this.id, 'edit']);
    }

    deleteEvent() {
        let title = this.translate.instant('ExcluirEvento');
        let text = this.translate.instant('PerguntaExcluirEvento');

        this.modalService.openDeleteModal(title, text, DeleteTypeEnum.event, this.id, ['web', 'home', 'event', 'list']);
    }

    changeGenres() {
        if (this.author) {
            this.router.navigate(['web', 'home', 'event', this.id, 'genres']);
        }

    }

    getParticipations(): string {
        if (this.author && this.event.usersEvent.length <= 0 && this.event.groupsEvent.length <= 0) {
            return this.translate.instant('AdicionarParticipacaoGrupo');
        } else {
            return this.stringService.joinParticipations(this.event.usersEvent.map(x => x.fullName), this.event.groupsEvent.map(x => x.name));
        }
    }

    openMap() {
        let fullAddress = `${this.event.address} ${this.event.neighborhood} ${this.event.descriptionCity} ${this.event.cep}`;
        let url = `https://www.google.com.br/maps/place/${fullAddress}`;
        window.open(url, "_blank");
    }

    changeCoverImage() {
        if (!this.author) return;

        this.updateCoverSubscription = this.uploadImageModalService.updateCoverImageEvent.subscribe((blobImage: Blob) => {
            this.eventService.updateCoverImage(this.id, blobImage).subscribe(
                () => {
                    this.loadEventDetails();
                }
            );
        })

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemCapa'), 21 / 9, UploadImageTypeEnum.cover);
    }

    changeProfileImage() {
        if (!this.author) return;

        this.updateProfileSubscription = this.uploadImageModalService.updateProfileImageEvent.subscribe((blobImage: Blob) => {
            this.eventService.updateProfileImage(this.id, blobImage).subscribe(
                () => {
                    this.loadEventDetails();
                }
            );
        });

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemPerfil'), 4 / 3, UploadImageTypeEnum.profile);
    }

    openParticipations() {
        this.updateParticipationsSubscription = this.participationService.updateParticipationEvent.subscribe(x => {
            this.loadEventDetails();
        });

        this.modalService.openParticipationsModal(this.id, ParticipationEnum.event, this.author, false);
    }

    openImage(id: number) {
        this.router.navigate(['web', 'home', 'event', this.id, 'image', id]);
    }

    openVideo(id: number) {
        this.router.navigate(['web', 'home', 'event', this.id, 'video', id]);
    }

    openImages() {
        this.router.navigate(['web', 'home', 'event', this.id, 'images']);
    }

    openVideos() {
        this.router.navigate(['web', 'home', 'event', this.id, 'videos']);
    }

    openUrl() {
        let url = this.event.url;
        
        if (url.substring(0, 4) !== 'http') {
            url = url = 'http://' + url;
        }

        window.open(url, '_blank');
    }

    toggleSavedEvent() {
        this.event.isSavedEvent = !this.event.isSavedEvent;
        this.eventService.togglesavedEvent(this.id, !this.event.isSavedEvent).subscribe(
            (response: any) => {

            }
        )
    }

    complaint(){
        this.modalService.complaint(OwnerEnum.Event, this.id, true);
    }
}