import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { Group } from 'src/app/core/models/group';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { TranslateService } from '@ngx-translate/core';
import { UserFavorite } from 'src/app/core/models/user-favorite';

@Component({
    templateUrl: './user-favorite-list.component.html',
    styleUrls: ['./user-favorite-list.component.scss']
})
export class UserFavoriteListComponent implements OnInit {
    musicians:UserFavorite[];
    users:UserFavorite[];
    groups:UserFavorite[];

    constructor(
        public translate:TranslateService,
        private userService:UserService,
        private router:Router){}

    ngOnInit(): void {
        this.getFavorites();
    }

    getFavorites(){
        this.userService.getFavoriteList().subscribe(
            (response) => {
                const userResponse = <ScopeListDto<UserFavorite>>response[0];
                const groupResponse = <ScopeListDto<UserFavorite>>response[1];

                this.musicians = userResponse.items.filter(x => x.isMusician);
                this.users = userResponse.items.filter(x => !x.isMusician);
                this.groups = groupResponse.items;
            }
        )
    }

    openUser(id:number){
        this.router.navigate(['web', 'home', 'user', id]);
    }

    openGroup(id:number){
        this.router.navigate(['web', 'home', 'group', id]);
    }
}