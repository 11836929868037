import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../core/models/user';

@Injectable({providedIn: 'root'})
export class UserConfigurationsService{
    constructor(private httpclient:HttpClient){}

    getBlockedList(){
        return this.httpclient.get('userblocked');
    }
    
    changeEmail(email:string){
        return this.httpclient.post('user/changeemail', {email: email});
    }

    changePassword(oldPassword:string, newPassword:string){
        let body = {oldPassword, newPassword};

        return this.httpclient.post('user/changepassword', body);
    }

    reportError(description:string){
        return this.httpclient.post('reportissueweb', {description: description});
    }

    deleteAccount(password:string){
        return this.httpclient.post('user/deleteuser', {password});
    }

    getConfig(){
        return this.httpclient.get('user/settings');
    }

    saveConfig(user:User){
        return this.httpclient.put('user/settings', user);
    }
}