import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { SignInComponent } from './signin/signin.component';
import { ChangePasswordComponent } from './forgot-password/change-password/change-password.component';
import { AuthGuard } from '../core/auth/auth.guard';
import { RegisterComponent } from './register/register.component';
import { RegisterByFacebookComponent } from './register-by-facebook/register-by-facebook.component';

const routes: Routes = [
    {
      path: '',
      component: SignInComponent
    },
    {
      path: 'web',
      component: HomeComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          redirectTo: 'signin',
          pathMatch: 'full'
        },
        {
          path: 'signin',
          component: SignInComponent,
        },
        {
          path: 'change-password',
          component: ChangePasswordComponent,
        },
        {
          path: 'register',
          component: RegisterComponent
        },
        {
          path: 'register-by-facebook',
          component: RegisterByFacebookComponent
        }        
      ]
    }
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class HomeRoutingModule { }