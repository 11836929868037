import { NgModule } from '@angular/core';
import { ImageListComponent } from './image-list/image-list.component';
import { CreateEditImageComponent } from './create-edit-image/create-edit-image.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatInputModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations:[
        ImageListComponent,
        CreateEditImageComponent,
        ViewImageComponent        
    ],

    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        TranslateModule
    ]
})
export class ImagesModule {

}