import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports:[
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-full-width',
            preventDuplicates: true,
            closeButton: true            
        })
    ]
})
export class ToastModule{

}