import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { YoutubeItemDto } from 'src/app/core/dtos/youtube-api/youtube-item-dto';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { VideosService } from '../videos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { YoutubeApiDto } from 'src/app/core/dtos/youtube-api/youtube-api-dto';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './create-video.component.html',
    styleUrls: ['./create-video.component.scss']
})
export class CreateVideoComponent implements OnInit {
    videoList: YoutubeItemDto[];
    ownerId: number;
    ownerEnum: OwnerEnum;
    searchUpdate = new Subject<string>();
    video: YoutubeItemDto;
    comeBackTitle: string;
    videoForm: FormGroup;
    pageToken: string;

    constructor(
        public translate: TranslateService,
        private videosService: VideosService,
        private router: Router,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);

        this.initForm();
        this.route.params.subscribe(params => {
            this.ownerEnum = this.getOwnerEnum();
            this.getComeBackTitle();
            this.ownerId = +params['ownerId'];
        });

        this.searchUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.search();
            });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getComeBackTitle();
        })
    }

    initForm() {
        this.videoForm = this.formBuilder.group({
            search: ['']
        })
    }

    search() {
        let search: string = this.videoForm.get('search').value;
        if (search.length <= 2) {
            return;
        }

        this.videosService.searchVideoByYoutube(search).subscribe(
            (response: YoutubeApiDto) => {
                this.videoList = response.items;
                this.pageToken = response.nextPageToken;
            }
        )
    }

    handleVideo(video: YoutubeItemDto) {
        this.video = video;
    }

    create() {
        let gallery = {
            ownerId: this.ownerId,
            ownerEnum: this.ownerEnum,
            enumTypeMedia: 4,
            description: this.video.snippet.description,
            name: this.video.snippet.title,
            thumbnail: this.video.snippet.thumbnails.medium.url,
            urlMedia: this.video.id.videoId
        };

        this.videosService.create(gallery).subscribe(
            () => {
                this.toastService.success('Imagem salva com sucesso');
                let url = this.getBaseRedirectionUrl();
                url.push('videos');

                this.router.navigate(url);
            }
        );
    }

    private getOwnerEnum() {
        let url = this.router.url;
        if (url.indexOf('event') > -1) {
            return OwnerEnum.Event;
        } else if (url.indexOf('project') > -1) {
            return OwnerEnum.Project;
        } else if (url.indexOf('group') > -1) {
            return OwnerEnum.Group;
        } else if (url.indexOf('product') > -1) {
            return OwnerEnum.Product;
        } else if (url.indexOf('service') > -1) {
            return OwnerEnum.Service;
        } else {
            return OwnerEnum.User;
        }
    }

    private getBaseRedirectionUrl(): string[] {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return ['web', 'home', 'event', this.ownerId.toString()];
            case OwnerEnum.Project:
                return ['web', 'home', 'project', this.ownerId.toString()];
            case OwnerEnum.Group:
                return ['web', 'home', 'group', this.ownerId.toString()];
            case OwnerEnum.Service:
                return ['web', 'home', 'ads', 'service', this.ownerId.toString()];
            case OwnerEnum.Product:
                return ['web', 'home', 'ads', 'product', this.ownerId.toString()];
            case OwnerEnum.User:
                return ['web', 'home', 'user', this.ownerId.toString()];
        }
    }

    getComeBackTitle() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.translate.get("VoltarEvento").subscribe(x => this.comeBackTitle = x);
            case OwnerEnum.Project:
                this.translate.get("VoltarProjeto").subscribe(x => this.comeBackTitle = x);
            case OwnerEnum.Group:
                this.translate.get("VoltarGrupo").subscribe(x => this.comeBackTitle = x);
            case OwnerEnum.Service:
                this.translate.get("VoltarServico").subscribe(x => this.comeBackTitle = x);
            case OwnerEnum.Product:
                this.translate.get("VoltarProduto").subscribe(x => this.comeBackTitle = x);
            case OwnerEnum.User:
                this.translate.get("VoltarUsuario").subscribe(x => this.comeBackTitle = x);
        }
    }

    comeBack() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.router.navigate(['web', 'home', 'event', this.ownerId]);
                break;
            case OwnerEnum.Project:
                this.router.navigate(['web', 'home', 'project', this.ownerId]);
                break;
            case OwnerEnum.Group:
                this.router.navigate(['web', 'home', 'group', this.ownerId]);
                break;
            case OwnerEnum.Service:
                this.router.navigate(['web', 'home', 'ads', 'service', this.ownerId]);
                break;
            case OwnerEnum.Product:
                this.router.navigate(['web', 'home', 'ads', 'product', this.ownerId]);
                break;
            case OwnerEnum.User:
                this.router.navigate(['web', 'home', 'user', this.ownerId]);
                break;
        }
    }

    loadMoreVideos() {
        if (!this.pageToken) {
            return;
        }

        let search: string = this.videoForm.get('search').value;
        if (search.length <= 2) {
            return;
        }

        this.videosService.searchVideoByYoutube(search, this.pageToken).subscribe(
            (response: YoutubeApiDto) => {
                response.items.forEach(x => this.videoList.push(x));
                this.pageToken = response.nextPageToken;
            }
        )
    }
}