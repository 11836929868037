import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Group } from '../core/models/group';
import { MemberGroup } from '../core/models/member-group';

@Injectable({providedIn: 'root'})
export class GroupService {
    updateMemberEvent = new EventEmitter<any>();


    constructor(private http: HttpClient){}

    getGroupDetail(id:number){
        return this.http.get(`group/${id}`);
    }

    getGroupByUser(){
        return this.http.get('group');
    }

    createGroup(group:Group){
        return this.http.post('group', group);
    }

    editGroup(id:number, group:Group){
        return this.http.put(`group/${id}`, group);
    }

    delete(id:number){
        console.log('deletando');
        return this.http.delete(`group/${id}`);
    }

    updateCoverImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'group-image-cover');

        return this.http.post(`group/updatepicture/${id}/cover`, formData);
    }

    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'group-image-profile');

        return this.http.post(`group/updatepicture/${id}/profile`, formData);
    }

    toggleFollowGroup(id:number){
        let queryParams = new HttpParams().set('groupId', id.toString());
        return this.http.patch('follow/togglefollowgroup', '', {params: queryParams});
    }

    getMembers(groupId:number){
        return this.http.get(`group/membergroup/${groupId}`);
    }

    deleteMember(id:number){
        return this.http.delete(`group/membergroup/${id}`);
    }

    createMember(memberGroup:MemberGroup){
        return this.http.post(`group/membergroup`, memberGroup);
    }

    searchMember(search: string) {
        const params = new HttpParams().set('search', search);
        return this.http.get(`searchusers`, { params });
    }

    toggleFavorite(groupId:number){
        return this.http.patch(`favorite/groupfavorite/${groupId}`, null);
    }

    acceptOrRefuseMemberGroup(groupId:number, accept:boolean){
        return this.http.patch(`group/membergroup/${groupId}/accept/${accept}`, null);
    }
}