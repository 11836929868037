import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const KEY = 'scope-language';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(private httpClient:HttpClient){}

    setLanguage(lang:string): void {
        let language = window.localStorage.getItem(KEY);
        if(!language){
            window.localStorage.setItem(KEY, lang);
        }else{     
            window.localStorage.setItem(KEY, lang);
        }
    }

    getlanguage(){
        return window.localStorage.getItem(KEY);;
    }

    updateUserLanguage(lang:string){
        let body = { language: lang};

        return this.httpClient.post('user/updatelanguage', body);
    }
}