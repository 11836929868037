import { Component } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { Gallery } from 'src/app/core/models/gallery';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StringService } from 'src/app/shared/helpers/string.service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { UserService } from '../user.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { TypeMediaEnum } from 'src/app/shared/enums/type-media-enum';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';

@Component({
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {
    author: boolean = false;
    id: number;
    user: User;
    members: string;
    genresLike: string;
    genresUserWork: string;
    instruments:string;
    images: Gallery[];
    rowImages: any[] = [];
    videos: Gallery[];

    updateCoverSubscription: Subscription;
    updateProfileSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private userService: UserService,
        private stringService: StringService,
        private userStorageService:UserStorageService,
        private toastService: ToastService,
        private modalsService:ModalsService,
        private uploadImageModalService: UploadImageModalService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.loadUserDetails();
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.instruments = this.getInstruments();
            this.genresLike = this.getGenres();
        });
    }

    ngOnDestroy(): void {
        if (this.updateCoverSubscription) {
            this.updateCoverSubscription.unsubscribe();
        }

        if (this.updateProfileSubscription) {
            this.updateProfileSubscription.unsubscribe();
        }        
    }

    loadUserDetails() {
        this.userService.getUser(this.id).subscribe(
            (user: User) => {
                this.author = user.id == this.userStorageService.getUser().id;
                this.user = user;                
                this.getGenres();
                this.getInstruments();
                this.genresUserWork = user.genresUserWork && user.genresUserWork.length > 0 ? this.stringService.joinGenres(user.genresUserWork.map(x => x.name)) : "";
                
                this.images = user.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.image).slice(-4);
                
                if (this.images) {
                    this.rowImages = this.userColumns(this.images);
                }

                this.videos = user.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.video).slice(-4);
            }
        )
    }

    getInstruments(){
        if(this.author){
            this.instruments = this.user && this.user.instruments && this.user.instruments.length > 0 ? this.stringService.joinGenres(this.user.instruments.map(x => x.name)) : this.translate.instant('AdicionarInstrumentoMusical');
        }else{
            this.instruments = this.user && this.user.instruments && this.user.instruments.length > 0 ? this.stringService.joinGenres(this.user.instruments.map(x => x.name)) : "";
        }
       
        return this.instruments;
    }

    getGenres(){
        this.genresLike = this.user.genresUserLike && this.user.genresUserLike.length > 0 ? this.stringService.joinGenres(this.user.genresUserLike.map(x => x.name)) : this.translate.instant('NenhumGeneroVinculado');
        return this.genresLike;
    }

    userColumns(photos: Gallery[]) {
        const newRows = [];

        for (let index = 0; index < photos.length; index += 2)
            newRows.push(photos.slice(index, index + 2));

        return newRows;
    }

    editUser() {
        this.router.navigate(['web', 'home', 'user', this.id, 'edit']);
    }

    changeGenres(){
        if(this.author){
            this.router.navigate(['web', 'home', 'user', this.id, 'genres']);
        }
    }

    changeWorkGenres(){
        if(this.author){
            this.router.navigate(['web', 'home', 'user', this.id, 'work-genres']);
        }
    }

    changeCoverImage(){
        if(!this.author) return;
        
        this.updateCoverSubscription = this.uploadImageModalService.updateCoverImageEvent.subscribe((blobImage:Blob) => {
            this.userService.updateCoverImage(this.id, blobImage).subscribe(
                () => {
                    this.toastService.success('Capa alterada com sucesso.');                    
                    this.loadUserDetails();
                }
            );
        })

        this.modalsService.openUploadImageModal(this.translate.instant('AlterarImagemCapa'), 21/9, UploadImageTypeEnum.cover);
    }

    changeProfileImage(){
        if(!this.author) return;
        
        this.updateProfileSubscription =  this.uploadImageModalService.updateProfileImageEvent.subscribe((blobImage:Blob) => {
            this.userService.updateProfileImage(this.id, blobImage).subscribe(
                () => {
                    this.toastService.success('Imagem de perfil alterada com sucesso.');                    
                    this.loadUserDetails();
                }
            );
        });

        this.modalsService.openUploadImageModal(this.translate.instant('AlterarImagemPerfil'), 4/3, UploadImageTypeEnum.profile);
    }

    openMessage(){
        if(this.author){
            this.router.navigate(['web', 'home', 'message']);
        }else{
            this.router.navigate(['web', 'home', 'message', this.id]);
        }
    }

    openImage(id:number){
        this.router.navigate(['web', 'home', 'user', this.id, 'image', id]);
    }

    openVideo(id:number){
        this.router.navigate(['web', 'home', 'user', this.id, 'video', id]);
    }

    openImages(){
        this.router.navigate(['web', 'home','user', this.id, 'images']);
    }

    openVideos(){
        this.router.navigate(['web', 'home','user', this.id, 'videos']);
    }

    openProject(projectId:number){
        this.router.navigate(['web', 'home','project', projectId]);
    }

    openGroup(groupId:number){
        this.router.navigate(['web', 'home','group', groupId]);
    }

    openEvent(eventId:number){
        this.router.navigate(['web', 'home','event', eventId]);
    }

    toggleIsFollowing(){
        this.user.isFollowing = !this.user.isFollowing;
        this.userService.toggleFollowUser(this.id).subscribe();
    }
    
    toggleIsFavorite(){
        this.user.isFavorite = !this.user.isFavorite;
        this.userService.toggleFavorite(this.id).subscribe();
    }

    toggleIsBlocked(){
        this.user.isBlocked = !this.user.isBlocked;
        this.userService.toggleBlocked(this.id).subscribe();
    }

    openInstruments(){
        if(!this.author) return;

        this.router.navigate(['web', 'home', 'user', this.id, 'instruments']);
    }

    complaint(){
        this.modalsService.complaint(OwnerEnum.User, this.id, true);
    }

    openUrl() {
        if (this.user.url.substring(0, 4) !== 'http') {
            this.user.url = 'http://' + this.user.url;
        }

        window.open(this.user.url, '_blank');
    }
}