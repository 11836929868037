import { Component } from '@angular/core';
import { EventsUserDto } from 'src/app/core/dtos/events-user-dto';
import { EventService } from '../event.service';
import { EventModel } from 'src/app/core/models/event';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss']
})
export class EventListComponent {
    events: EventModel[];
    eventsRequest: EventModel[];
    eventsGroupRequest: EventModel[];

    constructor(
        public translate:TranslateService,
        private eventService:EventService,
        private router: Router){}
    
    ngOnInit(): void {
        this.loadEvents();
    }

    loadEvents(){
        this.eventService.getEventsByUser().subscribe(
            (events: EventsUserDto) => {
                this.events = events.events;
                this.eventsRequest = events.eventsRequest;
                this.eventsGroupRequest = events.eventsGroupRequest;
            }
        )
    }

    goDetailEvent(id:number){
        this.router.navigate(['web', 'home','event', id]);
    }

    newEvent(){
        this.router.navigate(['web', 'home','event', 'new']);
    }

    acceptOrRefuse(eventId:number, accept:boolean, isGroup:boolean, groupId?:number){
        this.eventService.acceptOrRefuseParticipation(eventId, accept, isGroup, groupId).subscribe(
            () => {
                this.loadEvents();
            }
        );
    }
}