import { Component, OnInit } from "@angular/core";
import { Service } from "src/app/core/models/service";
import { Product } from "src/app/core/models/product";
import { Router } from "@angular/router";
import { AdsService } from "../ads.service";
import { TranslateService } from "@ngx-translate/core";
import { ServiceService } from "../services/service.service";
import { ProductService } from "../products/product.service";

@Component({
  templateUrl: "./ads-list.component.html",
  styleUrls: ["./ads-list.component.scss"],
})
export class AdsListComponent implements OnInit {
  services: Service[];
  products: Product[];
  renderNewServices: Service[] = [];
  renderNewProducts: Product[] = [];

  constructor(
    public translate: TranslateService,
    private adsService: AdsService,
    private router: Router,
    private serviceDel: ServiceService,
    private productDel: ProductService
  ) {}

  ngOnInit() {
    this.getAds();
  }

  getAds() {
    this.adsService.getAdsFromUser().subscribe((response) => {
      this.services = <Service[]>response[0];
      this.products = <Product[]>response[1];
     /* this.renderNewProducts = this.renderItems(
        this.products,
        "expirationDate"
      );
      this.renderNewServices = this.renderItems(
        this.services,
        "expirationDate"
      );*/
     // this.deleteIdsService();
     // this.deleteIdsProduct();
    });
  }

  renderItems(items: any[], filterProperty: string): any[] {
    return items.filter((item) => item[filterProperty] == null);
  }

  async deleteIdsService() {
    if (this.services.length === 0) {
      return;
    }
    const deletedIds: number[] = [];
    for (const service of this.services) {
      if (service.expirationDate === null) {
        try {
          const response = await this.serviceDel.delete(service.id).toPromise();
          deletedIds.push(service.id);
        } catch (error) {
          console.error(`Erro ${service.id}:`, error);
        }
      }
    }
    if (deletedIds.length > 0) {
      this.router.navigate(["/web/home"]);
    }
  }

  async deleteIdsProduct() {
    if (this.products.length === 0) {
      return;
    }
    const deletedIds: number[] = [];
    for (const product of this.products) {
      if (product.expirationDate === null) {
        try {
          const response = await this.productDel.delete(product.id).toPromise();
          deletedIds.push(product.id);
        } catch (error) {
          console.error(`Erro ${product.id}:`, error);
        }
      }
    }
    if (deletedIds.length > 0) {
      this.router.navigate(["/web/home"]);
    }
  }

  openService(id: number) {
    this.router.navigate(["web", "home", "ads", "service", id]);
  }

  openProduct(id: number) {
    this.router.navigate(["web", "home", "ads", "product", id]);
  }

  redirectDefault() {
    this.router.navigate(["web", "home", "ads", "list"]);
  }
  newService() {
    this.router.navigate(["web", "home", "ads", "service", "new"]);
  }

  newProduct() {
    this.router.navigate(["web", "home", "ads", "product", "new"]);
  }
}
