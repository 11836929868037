import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '../project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/core/models/project';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './project-new-edit.component.html',
    styleUrls: ['./project-new-edit.component.scss'],
})
export class ProjectNewEditComponent implements OnInit {
    projectForm: FormGroup;
    options: object;
    id: number;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute) { }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();

        if (this.id) {
            this.getEvent(this.id);
        }

        this.options = {
            types: ['(cities)']
        }
    }

    setFormValidations() {
        this.projectForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            description: ['']
        });
    }

    saveOrEdit() {
        if (this.id) {
            this.projectService.editProject(this.id, this.generateEvent())
                .subscribe((project: Project) => {
                    this.router.navigate(['web', 'home', 'project', this.id]);
                });
        } else {
            this.projectService.createProject(
                this.generateEvent()).subscribe(
                    (project: Project) => {
                        this.router.navigate(['web', 'home', 'project', project.id, 'genres']);
                    }
                )
        }
    }

    generateEvent(): Project {
        let project: Project = this.projectForm.getRawValue();

        return project;
    }

    getEvent(id: number) {
        this.projectService.getProjectDetail(id).subscribe(
            (project: Project) => {
                this.setValuesInForm(project);
            }
        );
    }

    setValuesInForm(project: Project) {
        this.projectForm.patchValue({
            ...project            
        });
    }
}