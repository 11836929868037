import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from 'src/app/core/models/service';

@Injectable({providedIn: 'root'})
export class ServiceService{
    constructor(private http: HttpClient){}

    getServiceDetail(id:number){
        return this.http.get(`service/${id}`);
    }

    getServicesByUser(){
        return this.http.get('service/getallbyuser');
    }

    createService(event:Service){
        return this.http.post('service', event);
    }

    editService(id:number, event:Service){
        return this.http.put(`service/${id}`, event);
    }

    delete(id:number){
        return this.http.delete(`service/${id}`);
    }
    
    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'service-image-profile');

        return this.http.post(`service/updatepicture/${id}`, formData);
    }

    getServiceCategories(){
        return this.http.get(`categoriesservice`);
    }    
}