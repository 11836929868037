import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule, MatTabsModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UserGalleryComponent } from './user-gallery.component';

@NgModule({
    declarations:[
        UserGalleryComponent
    ],
    imports:[
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        TranslateModule
    ]
})
export class UserGalleryModule{
    
}