import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { LocationModalComponent } from './components/location-modal/location-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { DeleteTypeEnum } from '../enums/delete-type-enum';
import { ForgotPasswordModalComponent } from 'src/app/home/forgot-password/modal/forgot-password-modal.component';
import { UploadImageModalComponent } from './components/upload-image/upload-image-modal.component';
import { UploadImageTypeEnum } from '../enums/upload-image-type.enum';
import { ParticipationListModalComponent } from './components/participations/participation-list-modal/participation-list-modal.component';
import { ParticipationEnum } from '../enums/participation-enum';
import { CreateParticipationModalComponent } from './components/participations/create-participation-modal/create-participation-modal.component';
import { OwnerEnum } from '../enums/owner-enum';
import { MembersListComponent } from 'src/app/group/members/members-list/members-list.component';
import { MembersNewComponent } from 'src/app/group/members/members-new/members-new.component';
import { AdTypeEnum } from '../enums/ad-type-enum';
import { AdsRenewComponent } from 'src/app/ads/ads-renew/ads-renew.component';
import { EditCommentModalComponent } from './components/edit-comment-modal/edit-comment-modal.component';
import { DeleteCommentModalComponent } from './components/delete-comment-modal/delete-comment-modal.component';
import { ComplaintModalComponent } from './components/complaint-modal/complaint-modal.component';
import { HomeUserFiltersModalComponent } from 'src/app/home-user/home-user-filters-modal/home-user-filters-modal.component';

@Injectable({providedIn: 'root'})
export class ModalsService {
    constructor(private dialog: MatDialog){}

    openLocationModal(){
        const dialogRef = this.dialog.open(LocationModalComponent, {
            width: '500px',
            height: '300px'            
          });
    }

    openDeleteModal(title:string, text:string, type: DeleteTypeEnum, id:number, redirection?:string[], ownerEnum?:OwnerEnum){
        const dialogRef = this.dialog.open(DeleteModalComponent, {
            width: '500px',
            height: 'auto',
            data: {
                title,
                text,
                type: type,
                id,
                redirection,
                ownerEnum
            }            
          });
    }

    openForgotPasswordModal(){
        const dialogRef = this.dialog.open(ForgotPasswordModalComponent, {
            width: '500px',
            height: '250px'            
          });
    }

    openUploadImageModal(
        title:string,
        aspectRatio:number, 
        uploadTypeEnum:UploadImageTypeEnum, 
        redirectUrl?:string[],
        ownerId?:number,
        ownerEnum?:OwnerEnum,
        mainCropped?:boolean        
        ){
        const dialogRef = this.dialog.open(UploadImageModalComponent, {
            width: 'auto',
            height: 'auto',
            data: {
                title,
                aspectRatio,
                uploadTypeEnum,
                ownerId,
                ownerEnum,
                redirectUrl,
                mainCropped
            }            
          });
    }

    openParticipationsModal(id:number, participationEnum:ParticipationEnum, author:boolean, closeAll:boolean){
        if(closeAll){
            this.dialog.closeAll();
        }

        const dialogRef = this.dialog.open(ParticipationListModalComponent, {
            width: '500px',
            height: 'auto',
            data: {
                ownerId: id,
                participationEnum: participationEnum,
                author
            }            
          });
    }

    openCreateParticipationModal(id:number, participationEnum:ParticipationEnum, author:boolean, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(CreateParticipationModalComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                ownerId: id,
                participationEnum: participationEnum,
                author
            }            
          });
    }

    openMembersGroupModal(groupId:number, author:boolean, closeAll:boolean){
        if(closeAll){
            this.dialog.closeAll();
        }

        const dialogRef = this.dialog.open(MembersListComponent, {
            width: '500px',
            height: 'auto',
            data: {
                groupId: groupId,
                author
            }            
          });
    }

    openCreateMember(groupId:number, author:boolean, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(MembersNewComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                groupId,
                author
            }            
          });
    }

    renewAd(ownerId:number, adTypeEnum:AdTypeEnum, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(AdsRenewComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                ownerId,
                adTypeEnum
            }            
          });
    }

    editComment(id:number, oldDescription:string, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(EditCommentModalComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                id,
                oldDescription
            }            
          });
    }

    deleteComment(id:number, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(DeleteCommentModalComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                id
            }            
          });
    }

    complaint(ownerEnum:OwnerEnum, ownerId:number, closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(ComplaintModalComponent, {
            width: '500px',
            height: 'auto', 
            data: {
                ownerEnum,
                ownerId
            }            
          });
    }

    eventsFilter(closeall:boolean){
        if(closeall){
            this.dialog.closeAll();
        }
        
        const dialogRef = this.dialog.open(HomeUserFiltersModalComponent, {
            width: '500px',
            height: 'auto'          
          });
    }

    closeModal(){
        this.dialog.closeAll();
    }
}