export class LanguageMenuDto{
    abbreviation:string;
    urlPicture:string;
    description:string;

    constructor(abbreviation:string, urlPicture:string, description:string){
        this.abbreviation = abbreviation;
        this.urlPicture = urlPicture;
        this.description = description;
    }
}