import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LocationModalService } from './location-modal.service';
import { Subject } from 'rxjs';
import { GooglePlaceDto } from 'src/app/core/dtos/google-place-dto';
import { StringService } from 'src/app/shared/helpers/string.service';

@Component({
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})
export class LocationModalComponent implements OnInit, OnDestroy {
  locationForm: FormGroup;
  debounce: Subject<string> = new Subject<string>();
  @Output() onTyping = new EventEmitter<string>();
  options: object;

  constructor(
    private locationService: LocationModalService,
    private stringService:StringService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.setFormValidations();

    this.options = {
      types: ['(cities)']
    }
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  setFormValidations() {
    this.locationForm = this.formBuilder.group({
      city: ['',
        [Validators.required]
      ],
      cityId: ['',
        [Validators.required]
      ],
      descriptionCity: ['',
        [Validators.required]
      ]
    });
  }

  handleAddressChange(param){
    this.locationForm.get('cityId').patchValue(param.place_id);
    let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
    this.locationForm.get('descriptionCity').patchValue(city);
    this.locationForm.get('city').patchValue(city);
  }

  resetCityData(){
    this.locationForm.get('cityId').patchValue('');
    this.locationForm.get('descriptionCity').patchValue('');
  }

  sendCityChanged(){
    const city:GooglePlaceDto = {
      description: this.locationForm.get('descriptionCity').value,
      id: this.locationForm.get('cityId').value,
      place_id: null
    }
    
    this.locationService.sendCity(city);
  }
}