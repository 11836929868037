import { Component, OnInit, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { Gallery } from 'src/app/core/models/gallery';
import { Router, ActivatedRoute } from '@angular/router';
import { VideosService } from '../videos.service';
import { Subscription } from 'rxjs';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { Comment } from 'src/app/core/models/comment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './watch-video.component.html',
    styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit {
    id: number;
    ownerEnum: OwnerEnum;
    ownerId: number;
    gallery: Gallery;
    isAuthor: boolean;
    comeBackTitle:string;
    comments: Comment[] = [];
    commentForm: FormGroup;
    totalLikes:number;
    totalComments:number;
    liked:boolean;
    idUser:number;

    @ViewChildren("commentDiv") commentDivs: QueryList<ElementRef>;
    
    updateCommentSubscription:Subscription; 
    deleteCommentSubscription:Subscription; 
    activedRouteuSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private sharedService:SharedService,
        private modalsService: ModalsService,
        private userStorageService: UserStorageService,
        private videosService: VideosService) { }

    ngOnInit(): void {
        this.activedRouteuSubscription = this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.ownerId = +params['ownerId'];
            this.ownerEnum = this.getOwnerEnum();
            this.getComeBackTitle();
            this.idUser = this.userStorageService.getUser().id;
            this.getVideo();
            this.initializeForm();
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getComeBackTitle();
        })
    }

    ngOnDestroy(): void {
        if(this.activedRouteuSubscription){
            this.activedRouteuSubscription.unsubscribe();
        }

        if(this.updateCommentSubscription){
            this.updateCommentSubscription.unsubscribe();
        }

        if(this.deleteCommentSubscription){
            this.deleteCommentSubscription.unsubscribe();
        }
    }

    getVideo() {
        this.videosService.get(this.id, this.ownerEnum).subscribe(
            (response: Gallery) => {
                this.gallery = response;
                this.isAuthor = this.userStorageService.isAuthor(response.idUser);
                const tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                document.body.appendChild(tag);
                this.comments = response.comments;
                this.totalLikes = response.numberLikes;
                this.totalComments = response.numberComments;
                this.liked = response.liked;

                this.commentDivs.changes.subscribe(() => {
                    if (this.commentDivs && this.commentDivs.last) {
                        this.commentDivs.last.nativeElement.scrollIntoView();
                    }
                  });
            }
        )
    }

    delete() {
        let text = this.translate.instant('ExcluirGrupo');
        let url = this.getBaseRedirectionUrl();
        url.push('videos');

        this.modalsService.openDeleteModal(
            this.translate.instant('ExcluirVideo'),
            text,
            DeleteTypeEnum.gallery,
            this.id,
            url,
            this.ownerEnum
        );
    }

    private getOwnerEnum() {
        let url = this.router.url;
        if (url.indexOf('event') > -1) {
            return OwnerEnum.Event;
        } else if (url.indexOf('project') > -1) {
            return OwnerEnum.Project;
        } else if (url.indexOf('group') > -1) {
            return OwnerEnum.Group;
        } else if (url.indexOf('product') > -1) {
            return OwnerEnum.Product;
        } else if (url.indexOf('service') > -1) {
            return OwnerEnum.Service;
        } else {
            return OwnerEnum.User;
        }
    }

    private getBaseRedirectionUrl(): string[] {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return ['web', 'home', 'event', this.ownerId.toString()];
            case OwnerEnum.Project:
                return ['web', 'home', 'project', this.ownerId.toString()];
            case OwnerEnum.Group:
                return ['web', 'home', 'group', this.ownerId.toString()];
            case OwnerEnum.Service:
                return ['web', 'home', 'ads', 'service', this.ownerId.toString()];
            case OwnerEnum.Product:
                return ['web', 'home', 'ads', 'product', this.ownerId.toString()];
            case OwnerEnum.User:
                return ['web', 'home', 'user', this.ownerId.toString()];
        }
    }

    getComeBackTitle() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.translate.get("VoltarEvento").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Project:
                this.translate.get("VoltarProjeto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Group:
                this.translate.get("VoltarGrupo").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Service:
                this.translate.get("VoltarServico").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Product:
                this.translate.get("VoltarProduto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.User:
                this.translate.get("VoltarUsuario").subscribe(x => this.comeBackTitle =  x);
        }
    }

    comeBack() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.router.navigate(['web', 'home', 'event', this.ownerId, 'videos']);
                break;
            case OwnerEnum.Project:
                this.router.navigate(['web', 'home', 'project', this.ownerId, 'videos']);
                break;
            case OwnerEnum.Group:
                this.router.navigate(['web', 'home', 'group', this.ownerId, 'videos']);
                break;
            case OwnerEnum.Service:
                this.router.navigate(['web', 'home', 'ads', 'service', this.ownerId, 'videos']);
                break;
            case OwnerEnum.Product:
                this.router.navigate(['web', 'home', 'ads', 'product', this.ownerId, 'videos']);
                break;
            case OwnerEnum.User:
                this.router.navigate(['web', 'home', 'user', this.ownerId, 'videos']);
                break;
        }
    }

    initializeForm(){
        this.commentForm = this.formBuilder.group({
            description: [''],
        });
    }

    saveComment(){
        let description = this.commentForm.get('description').value;
        if(!description || description == '') return;

        this.sharedService.saveComment(description, this.ownerEnum, this.id, this.getTypeGallery()).subscribe(
            (comment:Comment) => {
                this.comments.push(comment);
                this.totalComments++;
                this.commentForm.get('description').patchValue('');
                this.commentDivs.changes.subscribe(() => {
                    if (this.commentDivs && this.commentDivs.last) {
                        this.commentDivs.last.nativeElement.scrollIntoView();
                    }
                  });
            } 
        )
    }

    editComment(comment:Comment){
        this.updateCommentSubscription = this.sharedService.updateCommentEvent.subscribe(
            (newComment:Comment) => {
            let index = this.comments.findIndex(x => x.id == newComment.id);
            this.comments[index].description = newComment.description;
        });
        
        this.modalsService.editComment(comment.id, comment.description, true);
    }

    deleteComment(id:number){
        this.deleteCommentSubscription = this.sharedService.deleteCommentEvent.subscribe( 
            (id:number) => {
            let index = this.comments.findIndex(x => x.id == id);
            this.comments.splice(index, 1);
            this.totalComments--;
        });

        this.modalsService.deleteComment(id, true);
    }

    openUser(id:number){
        this.router.navigate(['web', 'home', 'user', id]);
    }

    toggleLike(){
        this.sharedService.toggleLike(this.ownerEnum, this.id).subscribe(
            () => {
                if(this.liked){
                    this.totalLikes--;
                }else{
                   this.totalLikes++; 
                }
                this.liked = !this.liked;
            } 
        )
    }

    getTypeGallery() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return 'GalleryEvent';
            case OwnerEnum.Project:
                return 'GalleryProject';
            case OwnerEnum.Group:
                return 'GalleryGroup';
            case OwnerEnum.Service:
                return 'GalleryService';
            case OwnerEnum.Product:
                return 'GalleryProduct';
            case OwnerEnum.User:
                return 'GalleryUser';
        }
    }
}