import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ParticipationsService } from '../participations.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ParticipationDto } from 'src/app/core/dtos/participation-dto';
import { ParticipationEnum } from 'src/app/shared/enums/participation-enum';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UserEvent } from 'src/app/core/models/user-event';
import { GroupEvent } from 'src/app/core/models/group-event';
import { ModalsService } from '../../../modals.service';
import { Router } from '@angular/router';

@Component({
    templateUrl: './participation-list-modal.component.html',
    styleUrls: ['./participation-list-modal.component.scss']
})
export class ParticipationListModalComponent implements OnInit {
    ownerId: number;
    participationEnum: ParticipationEnum;
    participationList: ParticipationDto[] = [];
    author:boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastService: ToastService,
        private dialog: MatDialog,
        private modalService:ModalsService,
        private router:Router,
        private participationsService: ParticipationsService) { }

    ngOnInit(): void {
        this.ownerId = this.data.ownerId;
        this.participationEnum = this.data.participationEnum;
        this.author = this.data.author;
        this.getParticipations();
    }

    getParticipations() {
        this.participationsService.getParticipations(this.ownerId, this.participationEnum).subscribe(
            (response: any) => {
                this.participationList = [];
                this.convertUserEventToParticipation(response[0].items);

                this.convertGroupEventToParticipation(response[1].items);
            }
        );
    }

    deleteParticipation(participation: ParticipationDto) {
        this.participationsService.deleteParticipation(participation, this.participationEnum).subscribe(
            () => {                
                this.toastService.success('Participação removida com sucesso.');
                this.getParticipations();
                this.participationsService.updateParticipationEvent.emit('');
            }
        )
    }

    cancel() {
        this.dialog.closeAll();
    }

    openParticipation(participationId:number, isGroup:boolean){
        console.log(participationId);
        console.log(isGroup);
        if(isGroup){
            this.dialog.closeAll();
            this.router.navigate(['web', 'home', 'group', participationId]);
        }
    }

    createParticipation(){
        this.modalService.openCreateParticipationModal(this.ownerId, this.participationEnum, this.author, true);
    }

    private convertUserEventToParticipation(userEvents: UserEvent[]) {
        userEvents.forEach(ue => {
            this.participationList.push(new ParticipationDto(
                ue.id,
                ue.idEvent,
                ue.name,
                ue.urlProfilePicture,
                false,
                ue.idUser
            ))
        });
    }



    private convertGroupEventToParticipation(groupEvents: GroupEvent[]){
        groupEvents.forEach(ue => {
            this.participationList.push(new ParticipationDto(
                ue.id,
                ue.idEvent,
                ue.name,
                ue.urlProfilePicture,
                true,
                ue.idGroup
            ))
        });
    }
}