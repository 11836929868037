import { Injectable } from '@angular/core';
import { User } from 'src/app/core/models/user';

@Injectable({ providedIn: 'root' })
export class StringService {

    joinParticipations(musicians: string[], groups: string[]): string {
        let participations: string[] = [];
        let participationsString: string;

        musicians.forEach(m => { participations.push(m) });
        groups.forEach(g => { participations.push(g) });

        participationsString = participations.join(", ");

        return participationsString;
    }

    joinGenres(genres: string[]): string {
        
        genres = genres.sort((a, b) => {
            if (a < b) { return -1; }
            if (a > b) { return 1; }

            return 0;
        });
        
        return genres.join(", ");
    }

    joinMembers(members:User[]){
        return members.map(x => x.fullName).join(", ");
    }

    removeAccents(s:string){
        if(!s){
            return s;
        }

        return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    removeNeighborhoodFromPetropolis(city:string){
        return city.replace('Petrópolis - Cascatinha,', '');
    }
}