import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class HandlerResponseInterceptor implements HttpInterceptor{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                return event.clone({ body: this.modifyBody(event.body) });
            }

            return event;
        }));
    }
     
    modifyBody(body: any) {
        if(body && body.data){
            return body.data;
        }
    }
}