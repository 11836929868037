import { Component, OnInit, Inject } from '@angular/core';
import { ParticipationDto } from 'src/app/core/dtos/participation-dto';
import { ParticipationsService } from '../participations.service';
import { ParticipationEnum } from 'src/app/shared/enums/participation-enum';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { Group } from 'src/app/core/models/group';
import { User } from 'src/app/core/models/user';
import { ModalsService } from '../../../modals.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    templateUrl: './create-participation-modal.component.html',
    styleUrls: ['./create-participation-modal.component.scss']
})
export class CreateParticipationModalComponent implements OnInit {
    participationList: ParticipationDto[];
    ownerId: number;
    participationEnum: ParticipationEnum;
    participationForm: FormGroup;
    searchUpdate = new Subject<string>();
    options: any[];
    favoriteOption: any;
    author:boolean;

    constructor(
        private formBuilder: FormBuilder,
        private participationService: ParticipationsService,
        private modalsService: ModalsService,
        private dialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.ownerId = this.data.ownerId;
        this.participationEnum = this.data.participationEnum;
        this.author = this.data.author;
        this.configForm();
        this.options = [
            {
                name: 'Grupo',
                value: true
            },
            {
                name: 'Músico',
                value: false
            },
        ];

        this.searchUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.searchParticipation();
            });
    }

    configForm() {
        this.participationForm = this.formBuilder.group({
            search: ['', [Validators.required]],
            participationId: [''],
            isGroup: [false, Validators.required]
        });
    }

    resetForm() {
        this.participationList = [];
        this.participationForm.get('search').patchValue('');
        this.participationForm.get('participationId').patchValue('');
    }

    handleParticipation(participationId: number) {
        this.participationForm.get('participationId').patchValue(participationId);
    }


    searchParticipation() {
        let search: string = this.participationForm.get('search').value;
        let isGroup = this.participationForm.get('isGroup').value;

        this.participationForm.get('participationId').patchValue('');

        if (!search || search.length < 2) {
            this.participationList = [];
            return;
        }

        this.participationService.searchParticipation(search, isGroup).subscribe(
            (response: ScopeListDto<any>) => {
                if (isGroup) {
                    let groupList: ScopeListDto<Group> = response;
                    this.participationList = groupList.items.map(x =>
                        new ParticipationDto(
                            0,
                            this.ownerId,
                            x.name,
                            x.urlProfilePicture,
                            true,
                            x.id
                        ));
                } else {
                    let userList: ScopeListDto<User> = response;
                    this.participationList = userList.items.map(x =>
                        new ParticipationDto(
                            0,
                            this.ownerId,
                            x.fullName,
                            x.urlProfilePicture,
                            true,
                            x.id
                        ));
                }
            }
        );
    }

    createParticipation() {
        let participation = new ParticipationDto(
            0,
            this.ownerId,
            this.participationForm.get('search').value,
            '',
            this.participationForm.get('isGroup').value,
            this.participationForm.get('participationId').value
        );

        this.participationService.createParticipation(this.participationEnum, participation).subscribe(
            (response) => {                     
                this.modalsService.openParticipationsModal(this.ownerId, this.participationEnum, this.author, true);
                this.participationService.updateParticipationEvent.emit('');
            }
        );
    }

    cancel() {
        this.dialog.closeAll();
    }
}