import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiPrefixInterceptor } from '../core/interceptors/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from '../core/interceptors/error-handler.interceptor';
import { LoaderInterceptor } from '../core/interceptors/loader.interceptor';
import { TokenInterceptor } from '../core/interceptors/token.interceptor';
import { HandlerResponseInterceptor } from '../core/interceptors/handle-response.interceptor';

export function addInterceptor(interceptor: any) {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: interceptor,
    multi: true
  };
}



export const httpInterceptorProviders = [
  addInterceptor(ApiPrefixInterceptor),
  addInterceptor(LoaderInterceptor),
  addInterceptor(TokenInterceptor),
  addInterceptor(ErrorHandlerInterceptor),
  addInterceptor(HandlerResponseInterceptor)
];
