import { Component, Input, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteModalService } from './delete-modal.service';
import { ModalsService } from '../../modals.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';

@Component({
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
    title: string;
    text: string;
    ownerEnum:OwnerEnum;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private router: Router,
        private deleteModalService: DeleteModalService) { }

    ngOnInit(): void {
        this.text = this.data.text;
        this.title = this.data.title;
        this.ownerEnum = this.data.ownerEnum;                
    }

    cancel(){
        this.dialog.closeAll();
    }

    delete() {
        this.deleteModalService.delete(this.data.id, this.data.type, this.ownerEnum).subscribe((obj) => {
            this.router.navigate(this.data.redirection);
            this.dialog.closeAll();
        });
    }
}