import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserConfigurationsService } from "./user-configurations.service";
import { User } from "../core/models/user";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared/shared.service";
import { LanguageService } from "../core/language/language.service";
import { LanguageMenuDto } from "../core/dtos/language-menu-dto";
import { MatSelect } from "@angular/material/select";
@Component({
  templateUrl: "./user-configurations.component.html",
  styleUrls: ["./user-configurations.component.scss"],
})
export class UserConfigurationsComponent implements OnInit {
  configForm: FormGroup;
  languages: LanguageMenuDto[] = [];
  languageSelected: LanguageMenuDto;

  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private router: Router,
    private userConfigurationsService: UserConfigurationsService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.getConfig();
    this.fillLanguages();
  }

  getConfig() {
    this.userConfigurationsService.getConfig().subscribe((response: User) => {
      this.configForm.patchValue({ ...response });
    });
  }

  initializeForm() {
    this.configForm = this.formBuilder.group({
      notificationsEventsEmail: [false, [Validators.required]],
      notificationsEventsSound: [false, [Validators.required]],
      allowBeFoundMusicians: [false, [Validators.required]],
      notificationsEventsAlerts: [false, [Validators.required]],
      notificationsFavoritesEmail: [false, [Validators.required]],
      notificationsFavoritesSound: [false, [Validators.required]],
      notificationsFavoritesAlerts: [false, [Validators.required]],
      notificationsFollowingEmail: [false, [Validators.required]],
      notificationsFollowingSound: [false, [Validators.required]],
      notificationsFollowingAlerts: [false, [Validators.required]],
    });
  }

  saveConfig() {
    let config = this.configForm.getRawValue() as User;
    this.userConfigurationsService.saveConfig(config).subscribe(() => {});
  }

  changeEmail() {
    this.router.navigate(["web", "home", "settings", "change-email"]);
  }

  changePassword() {
    this.router.navigate(["web", "home", "settings", "change-password"]);
  }

  blockeds() {
    this.router.navigate(["web", "home", "settings", "blockeds"]);
  }

  deleteAccount() {
    this.router.navigate(["web", "home", "settings", "delete-account"]);
  }

  reportError() {
    this.router.navigate(["web", "home", "settings", "report-error"]);
  }

  buyPhotos() {
    this.sharedService.buyphotos().subscribe((response) => {
      if (response) {
        window.open(response.toString(), "_self");
      }
    });
  }

  terms() {
    window.open("http://musicscope.com.br/Home/termos-de-uso", "_blank");
  }
  fillLanguages() {
    this.languages.push(new LanguageMenuDto("pt", "brasil.png", "Portuguese"));
    this.languages.push(new LanguageMenuDto("en", "eua.png", "English"));
    this.languages.push(new LanguageMenuDto("es", "espanha.png", "Español"));
    this.languages.push(new LanguageMenuDto("fr", "franca.png", "French"));
    this.languages.push(new LanguageMenuDto("it", "italia.png", "Italian"));
    this.languages.push(new LanguageMenuDto("de", "alemanha.png", "Deutsch"));

    this.languageSelected = this.languages.find(
      (x) => x.abbreviation == this.languageService.getlanguage()
    );
  }
  @ViewChild("selectTrigger", { static: false }) selectTrigger: MatSelect;

  openSelect() {
    this.selectTrigger.open();
  }
  changeLanguage(language: LanguageMenuDto) {
    this.languageSelected = language;
    this.translate.use(language.abbreviation);
    this.languageService.setLanguage(language.abbreviation);
    this.languageService.updateUserLanguage(language.abbreviation).subscribe();
  }
}
