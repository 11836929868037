import { Component } from '@angular/core';
import { Project } from 'src/app/core/models/project';
import { ProjectService } from '../project.service';
import { Router } from '@angular/router';
import { ProjectsUserDto } from 'src/app/core/dtos/projects-user-dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent {
    projects: Project[];
    projectsRequest: Project[];
    projectsGroupRequest: Project[];

    constructor(
        public translate:TranslateService,
        private projectService:ProjectService,
        private router: Router){}
    
    ngOnInit(): void {
        this.loadProjects();
    }

    loadProjects(){
        this.projectService.getProjectsByUser().subscribe(
            (response: ProjectsUserDto) => {
                this.projects = response.projects;
                this.projectsRequest = response.projectsRequest;
                this.projectsGroupRequest = response.projectsGroupRequest;
            }
        )
    }

    goDetailProject(id:number){
        this.router.navigate(['web', 'home','project', id]);
    }

    newProject(){
        this.router.navigate(['web', 'home','project', 'new']);
    }

    acceptOrRefuse(projectId:number, accept:boolean, isGroup:boolean, groupId?:number){
        this.projectService.acceptOrRefuseParticipation(projectId, accept, isGroup, groupId).subscribe(
            () => {
                this.loadProjects();
            }
        );
    }
}