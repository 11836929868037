import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Genre } from '../core/models/genre';
import { MusicalAffinitiesService } from '../musical-affinities/musical-affinities.service';
import { of } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class GenreService {
    constructor(private http:HttpClient,
                private musicalAffinitiesService: MusicalAffinitiesService){}

    getGenres(routeString:string,id?:number){
        if(routeString.indexOf('event') > 0){
            return this.http.get(`genre/genresevent/${id}`);
        }else if(routeString.indexOf('project') > 0){
            return this.http.get(`genre/genresproject/${id}`);
        }else if(routeString.indexOf('group') > 0){
            return this.http.get(`genre/genresgroup/${id}`);
        }else if(routeString.indexOf('work-genres') > 0){
            return this.http.get(`genre/genresuserwork`);
        } else if(routeString.indexOf('user-like') > 0 || routeString.indexOf('user') > 0){
            return this.http.get('genre/genresuserlike');
        } else if(routeString.indexOf('musical-affinities') > 0){
            return this.http.get('genres');
        } else{
            return this.http.get("genres");
        }
    }

    saveGenres(genres:Genre[], routeString:string,id?:number){
        let genresKey = genres.map(x => x.key);
        
        if(routeString.indexOf('event') > 0){
            return this.http.post(`genre/genresevent/${id}`, genresKey);
        }else if(routeString.indexOf('project') > 0){
            return this.http.post(`genre/genresproject/${id}`, genresKey);
        }else if(routeString.indexOf('group') > 0){
            return this.http.post(`genre/genresgroup/${id}`, genresKey);
        }else if(routeString.indexOf('work-genres') > 0){
            return this.http.post('genre/genresuserwork', genresKey);
        }else if(routeString.indexOf('user-like') > 0 || routeString.indexOf('user') > 0){
            return this.http.post('genre/genresuserlike', genresKey);
        }else{
            
        }
    }


}