import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ParticipationEnum } from 'src/app/shared/enums/participation-enum';
import { ParticipationDto } from 'src/app/core/dtos/participation-dto';
import { Observable, forkJoin, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParticipationsService {
    updateParticipationEvent = new EventEmitter<any>();

    constructor(private http: HttpClient) { }

    getParticipations(id: number, participationEnum: ParticipationEnum): Observable<any[]> {
        if (participationEnum == ParticipationEnum.event) {
            const userEventRequest = this.http.get(`event/userevent/${id}`);
            const groupEventRequest = this.http.get(`event/groupevent/${id}`);

            return forkJoin([userEventRequest, groupEventRequest]);
        }

        const userProjectRequest = this.http.get(`project/userproject/${id}`);
        const groupProjectRequest = this.http.get(`project/groupproject/${id}`);

        return forkJoin([userProjectRequest, groupProjectRequest]);
    }

    deleteParticipation(participation: ParticipationDto, participationEnum: ParticipationEnum) {
        if (participationEnum == ParticipationEnum.event) {
            if (participation.isGroup) {
                return this.http.delete(`event/groupevent/${participation.id}`);
            } else {
                return this.http.delete(`event/userevent/${participation.id}`);
            }
        }

        if (participation.isGroup) {
            return this.http.delete(`project/groupproject/${participation.id}`);
        } else {
            return this.http.delete(`project/userproject/${participation.id}`);
        }
    }

    searchParticipation(search: string, isGroup: boolean) {
        const params = new HttpParams().set('search', search);
        if (isGroup) {
            return this.http.get(`searchgroups`, { params });
        }

        return this.http.get(`searchusers`, { params });
    }

    createParticipation(participationEnum: ParticipationEnum, participation: ParticipationDto) {
        let body = {
            name: participation.name,
            idGroup: participation.participationId,
            idUser: participation.participationId,
            idEvent: participation.ownerId,
            idProject: participation.ownerId
        };

        let url = '';

        if(participationEnum == ParticipationEnum.event){
            url = participation.isGroup ? 'event/groupevent' : 'event/userevent';
        }else{
            url = participation.isGroup ? 'project/groupproject' : 'project/userproject';
        }
        
        return this.http.post(url, body);
    }
    
    
}