import { CommonModule } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FaqComponent } from "./faq/faq.component";
import { HowWorksComponent } from "./how-works/how-works.component";
import { TermsUseComponent } from "./terms-use/terms-use.component";

@NgModule({
    declarations:[
        HowWorksComponent,
        FaqComponent,
        TermsUseComponent
    ],
    imports:[
        CommonModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class HomePageModule{

}