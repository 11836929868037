import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Service } from 'src/app/core/models/service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { Period } from 'src/app/core/models/period';
import { CategoryService } from 'src/app/core/models/categoryService';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';

@Component({
    templateUrl: './service-new-edit.component.html',
    styleUrls: ['./service-new-edit.component.scss']
})
export class ServiceNewEditComponent implements OnInit {
    serviceForm: FormGroup;
    options: object;
    id: number;
    categories:CategoryService[];
    periods:Period[] = [];
    selectedPeriod: string;
    service:Service;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private serviceService: ServiceService,
        private stringService:StringService, 
        private router: Router,
        private route: ActivatedRoute) { }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();
        this.getServiceCategories();
        this.getPeriods();        
        if (this.id) {
            this.getService(this.id);
        }

        this.options = {
            types: ['(cities)']
        };

        this.translate.onLangChange.pipe(delay(500)).subscribe((event: LangChangeEvent) => {
            this.getServiceCategories();
            this.periods = [];
            this.getPeriods();
        })
    }

    setFormValidations() {
        this.serviceForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            serviceCategory: ['', Validators.required],
            period: ['', Validators.required],
            subtitle: [''],
            website: [''],
            contactEmail: [''],
            phone: [''],
            description: ['']
        });
    }

    saveOrEdit() {
        if (this.id) {
            this.serviceService.editService(this.id, this.generateService())
                .subscribe((response:any) => {
                    if(!response.data && response.toString().indexOf('http') > -1){
                        window.open(response.toString(), "_self");
                    }
                });
        } else {
            this.serviceService.createService(
                this.generateService()).subscribe(
                    (service: Service) => {
                        window.open(service.paymentUrl, "_self");
                    }
                )
        }
    }

    generateService(): Service {
        let service: Service = this.serviceForm.getRawValue();
        service.categoryService = <CategoryService>{key: this.serviceForm.get('serviceCategory').value};
        service.period = <Period>{key: this.serviceForm.get('period').value};
        service.appStorePrice = "-1";
        return service;
    }

    getService(id: number) {
        this.serviceService.getServiceDetail(id).subscribe(
            (service: Service) => {
                this.setValuesInForm(service);
                this.service = service;
            }
        );
    }

    setValuesInForm(service: Service) {
        this.serviceForm.patchValue({
            ...service,
            city: service.descriptionCity,
            period: 'KeepPeriod',
            serviceCategory: service.categoryService.key             
        });
    }

    handleAddressChange(param) {
        this.serviceForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.serviceForm.get('descriptionCity').patchValue(city);
        this.serviceForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.serviceForm.get('idCity').patchValue('');
        this.serviceForm.get('descriptionCity').patchValue('');
    }

    getServiceCategories(){
        this.serviceService.getServiceCategories().subscribe(
            (response:ScopeListDto<CategoryService>)=> {
                this.categories = response.items;
        });
    }

    getPeriods(){
        if(this.id){
            let fifteen:Period = {key: 'KeepPeriod', name: 'Manter período'} 
            this.periods.push(fifteen);
        }
        
        let fifteen:Period = {key: 'Thirty', name: this.translate.instant('TrintaDias')} 
        this.periods.push(fifteen);

        let thirty:Period = {key: 'Ninety', name: this.translate.instant('NoventaDias')} 
        this.periods.push(thirty);

        let oneYear:Period = {key: 'OneYear', name: this.translate.instant('UmAno')} 
        this.periods.push(oneYear);
    }
}