import { Injectable } from '@angular/core';
import { OwnerEnum } from '../../enums/owner-enum';
import { HttpParams, HttpClient, HttpBackend } from '@angular/common/http';
import { Gallery } from 'src/app/core/models/gallery';

@Injectable({
    providedIn: 'root'
})
export class VideosService {
    constructor(
        private http:HttpClient){}

    get(id:number, ownerEnum:OwnerEnum){
        return this.http.get(`gallery/${Number(ownerEnum)}/${Number(id)}`);
    }

    getAll(ownerId:number, ownerEnum:OwnerEnum){
        let params = new HttpParams()
        .set('ownerId', ownerId.toString())
        .set('ownerEnum', Number(ownerEnum).toString())
        .set('typeMediaEnum', '4');
        
        return this.http.get('gallery', {params: params});
    }

    create(gallery:any){
        return this.http.post(`gallery/addvideo`, gallery);
    }

    edit(id:number, ownerEnum:OwnerEnum, name:string){
        let body = {
            ownerEnum: Number(ownerEnum),
            enumTypeMedia: 2,
            name            
        };
        
        return this.http.put(`gallery/${Number(id)}`, body);
    }

    delete(id:number, ownerEnum:OwnerEnum){
        return this.http.delete(`gallery/${Number(ownerEnum)}/${Number(id)}`);
    }

    searchVideoByYoutube(search:string, pageToken?:string){
        let url = 'https://www.googleapis.com/youtube/v3/search';
        let queryParams = new HttpParams()
        .set('key', 'AIzaSyBfFlZDl2gUbhcnAvZFlpRnp8815RzbOnc')
        .set('part', 'snippet')
        .set('maxResults', '10')
        .set('q', search);

        if(pageToken){
            queryParams = queryParams.set('pageToken', pageToken);
        }

        return this.http.get(url, {params: queryParams});
    }
}