import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordService } from '../forgot-password.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasswordByEmailDto } from 'src/app/core/dtos/change-password-by-email-dto';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { CustomPasswordValidator } from 'src/app/core/validators/custom-password.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    changePasswordForm: FormGroup;
    key: string;

    constructor(
        public translate:TranslateService,
        private forgotPasswordService:ForgotPasswordService,
                private formBuilder: FormBuilder,
                private router: Router,
                private toastService: ToastService,
                private activateRoute: ActivatedRoute){}
    
    ngOnInit(): void {
        this.activateRoute.queryParams.subscribe(params => {
            this.key = params['key'];
        });


        this.setFormValidations();
    }

    setFormValidations() {
        this.changePasswordForm = this.formBuilder.group({
            password: [null, Validators.compose([
                Validators.required,
                CustomPasswordValidator.patternValidator(/\d/, { hasNumber: true }),
                CustomPasswordValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                CustomPasswordValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
                Validators.minLength(6)])
             ],
          confirmPassword: ['',
            [Validators.required]
          ],          
        }, {
            validators: CustomPasswordValidator.passwordMatchValidator 
        });
    }

    changePassword(){
        let dto:ChangePasswordByEmailDto = {
            key: this.key,
            password: this.changePasswordForm.get('password').value
        };
        
        this.forgotPasswordService.changePasswordByEmail(dto).subscribe(
            () => {
                this.router.navigate(['web', 'home', 'signin']);
                this.toastService.success('Senha redefinida com sucesso');
            }
        )
    }
}