import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    templateUrl: './terms-use.component.html',
    styleUrls: ['./terms-use.component.scss']
})
export class TermsUseComponent implements OnInit {
    language:string

    constructor(public translate:TranslateService){}

    ngOnInit(): void {
        this.language = this.translate.currentLang;
    }
}