import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from 'src/app/shared/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-home-user-base',
  templateUrl: './home-user-base.component.html',
  styleUrls: ['./home-user-base.component.scss']
})
export class HomeUserBaseComponent implements OnInit {

  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  
  constructor(private sidenavService: SidenavService){}
  
  ngOnInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

}
