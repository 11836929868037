import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserLoginDto } from "src/app/core/dtos/user-login-dto";

@Injectable({
  providedIn: "root",
})
export class SignInService {
  constructor(private http: HttpClient) {}

  login(userLoginDto: UserLoginDto) {
    return this.http.post("user/login", userLoginDto);
  }
}
