import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageMenuDto } from '../core/dtos/language-menu-dto';
import { LanguageService } from '../core/language/language.service';

@Component({
    selector: 'mscope-home',
    templateUrl: './home.component.html',   
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    languages:LanguageMenuDto[] = [];
    languageSelected:LanguageMenuDto;
    
    constructor(
        private languageService:LanguageService,
        public translate:TranslateService){}
    
    ngOnInit(): void {
        this.fillLanguages();
    }

    fillLanguages(){
        this.languages.push(new LanguageMenuDto('pt', 'brasil.png', 'Portuguese'));
        this.languages.push(new LanguageMenuDto('en', 'eua.png', 'English'));
        this.languages.push(new LanguageMenuDto('es', 'espanha.png', 'Español'));
        this.languages.push(new LanguageMenuDto('fr', 'franca.png', 'French'));
        this.languages.push(new LanguageMenuDto('it', 'italia.png', 'Italian'));
        this.languages.push(new LanguageMenuDto('de', 'alemanha.png', 'Deutsch'));

        this.languageSelected = this.languages.find(x => x.abbreviation == this.languageService.getlanguage());
    }

    changeLanguage(language:LanguageMenuDto){
        this.languageSelected = language;
        this.translate.use(language.abbreviation);
        this.languageService.setLanguage(language.abbreviation);
    }
}