import { HttpClient, HttpParams } from '@angular/common/http';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class MessageService {

    constructor(private httpClent:HttpClient){}

    getAllMessages(){
        return this.httpClent.get('message/getalllastmessagesbyuser');
    }

    messageRead(userId:number){
        return this.httpClent.patch(`message/readmessage/${userId}`, null);
    }

    getMessageUser(userId:number){
        let params = new HttpParams()
            .set('page', '1')
            .set('pagesize', '10000');
        
        return this.httpClent.get(`message/getallmessagesbyuser/${userId}`, {params: params});
    }

    removeMessage(messageId:number){
        return this.httpClent.delete(`message/${messageId}`);
    }

    sendMessage(receiverUserId:number, content:string){
        let message = {
            idUserReceiver: receiverUserId,
            content: content
        };

        return this.httpClent.post(`message`, message);
    }
}