import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "src/app/core/components/toast/toast.service";
import { LanguageMenuDto } from "src/app/core/dtos/language-menu-dto";
import { LanguageService } from "src/app/core/language/language.service";
import { HomePageService } from "../home-page.service";

@Component({
  templateUrl: "./how-works.component.html",
  styleUrls: [
    "./how-works.component.scss",
    "./bootstrap.min.css",
    "./animate.css",
    "./ionicons.min.css",
    "./owl.carousel.css",
    "./owl.theme.css",
    "./style.css",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HowWorksComponent implements OnInit {
  openMenu: boolean = false;
  homePageForm: FormGroup;

  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private homePageService: HomePageService,
    private toastService: ToastService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    console.log('Página "How It Works" inicializada.');
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    console.log(`Tamanho da tela: ${screenWidth} x ${screenHeight}`);
    if (screenWidth <= 768) {
      this.router.navigate(["web", "signin"]);
    }

    this.homePageForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      description: ["", [Validators.required]],
    });
  }

  toggleMenu() {
    this.openMenu = !this.openMenu;
  }

  goToWeb() {
    this.router.navigate(["web", "signin"]);
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.languageService.setLanguage(language);
  }

  sendEmail() {
    let name = this.homePageForm.get("name").value;
    let email = this.homePageForm.get("email").value;
    let description = this.homePageForm.get("description").value;

    this.homePageService
      .sendEmail(name, email, description)
      .subscribe((response) => {
        this.homePageForm.reset();
        if (this.translate.currentLang == "pt") {
          this.toastService.success("Mensagem enviada com sucesso");
        } else {
          this.toastService.success("Message successfully sent");
        }
      });
  }
}
