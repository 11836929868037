import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { LoaderService } from 'src/app/core/components/loader/loader.service';

export class LoaderInterceptor implements HttpInterceptor{

    constructor(private loaderService: LoaderService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(req.url.indexOf('userevent') != -1 ||
        req.url.indexOf('search') != -1 ||
         req.url.indexOf('groupevent') != -1){
          return next.handle(req);  
        }
        
        this.loaderService.show();

        return next.handle(req).pipe(tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loaderService.hide();
            }
          },
          (err: any) => {
            this.loaderService.hide();
          },
        ));
      }
}