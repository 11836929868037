import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { AbstractControlDirective, AbstractControl, FormControl } from '@angular/forms';
import { FormErrorService } from './form-error.service';

@Component({
    selector: 'mscope-form-error',
    templateUrl: './form-error.component.html',
  })
export class FormErrorComponent implements OnInit, DoCheck{
    @Input() control: FormControl;
    public error: { key: string; message: any };
  
    constructor( private errorService: FormErrorService ) {}
  
    ngOnInit() {

    }
  
    ngDoCheck() {
      this.showErrorMessage();
    }
  
    showErrorMessage() {
      this.error = this.errorService.getError(this.control);
    }
}