import { Component, OnInit, OnDestroy } from '@angular/core';
import { Project } from 'src/app/core/models/project';
import { Gallery } from 'src/app/core/models/gallery';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ProjectService } from '../project.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { ParticipationsService } from 'src/app/shared/modals/components/participations/participations.service';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { TypeMediaEnum } from 'src/app/shared/enums/type-media-enum';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { ParticipationEnum } from 'src/app/shared/enums/participation-enum';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    styleUrls: ['./project-detail.component.scss'],
    templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
    author: boolean = false;
    id: number;
    project: Project;
    participations: string;
    genres: string;
    images: Gallery[];
    rowImages: any[] = [];
    videos: Gallery[];

    updateCoverSubscription: Subscription;
    updateProfileSubscription: Subscription;
    updateParticipationsSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private projectService: ProjectService,
        private userStorageService: UserStorageService,
        private stringService: StringService,
        private toastService: ToastService,
        private participationService: ParticipationsService,
        private uploadImageModalService: UploadImageModalService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.loadProjectDetails();
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.genres = this.setGenres();
            });
        });
    }

    ngOnDestroy(): void {
        if (this.updateCoverSubscription) {
            this.updateCoverSubscription.unsubscribe();
        }

        if (this.updateProfileSubscription) {
            this.updateProfileSubscription.unsubscribe();
        }

        if (this.updateParticipationsSubscription) {
            this.updateParticipationsSubscription.unsubscribe();
        }
    }

    loadProjectDetails() {
        this.projectService.getProjectDetail(this.id).subscribe(
            (project: Project) => {
                this.author = project.author!.id == this.userStorageService.getUser().id;
                this.project = project;
                this.participations = this.getParticipations();
                this.setGenres();

                this.images = project.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.image).slice(-4);

                if (this.images) {
                    this.rowImages = this.groupColumns(this.images);
                }

                this.videos = project.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.video);
            }
        )
    }

    setGenres(){
        console.log(this.project);
        this.genres = this.project && this.project.genres && this.project.genres.length > 0 ? this.stringService.joinGenres(this.project.genres.map(x => x.name)) : this.translate.instant('NenhumGeneroVinculado');
        return this.genres;
    }


    groupColumns(photos: Gallery[]) {
        const newRows = [];

        for (let index = 0; index < photos.length; index += 2)
            newRows.push(photos.slice(index, index + 2));

        return newRows;
    }

    editProject() {
        this.router.navigate(['web', 'home', 'project', this.id, 'edit']);
    }

    deleteProject() {
        let title = this.translate.instant('ExcluirProjeto');
        let text = this.translate.instant('PerguntaExcluirProjeto');
        
        this.modalService.openDeleteModal(title, text, DeleteTypeEnum.project, this.id, ['web', 'home', 'project', 'list']);
    }

    changeGenres(){
        if(this.author){
            this.router.navigate(['web', 'home', 'project', this.id, 'genres']);
        }
    }

    getParticipations(): string{
        if(this.author && this.project.usersProject.length <= 0 && this.project.groupsProject.length <= 0){
            return "Adicionar participação de músico/grupo";
        }else{
            return this.stringService.joinParticipations(this.project.usersProject.map(x => x.fullName), this.project.groupsProject.map(x => x.name));
        }            
    }

    changeCoverImage(){
        if(!this.author) return;
        
        this.updateCoverSubscription = this.uploadImageModalService.updateCoverImageEvent.subscribe((blobImage:Blob) => {
            this.projectService.updateCoverImage(this.id, blobImage).subscribe(
                () => {
                    this.toastService.success('Capa alterada com sucesso.');                    
                    this.loadProjectDetails();
                }
            );
        })

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemCapa'), 21/9, UploadImageTypeEnum.cover);
    }

    changeProfileImage(){
        if(!this.author) return;
        
        this.updateProfileSubscription =  this.uploadImageModalService.updateProfileImageEvent.subscribe((blobImage:Blob) => {
            this.projectService.updateProfileImage(this.id, blobImage).subscribe(
                () => {
                    this.toastService.success('Imagem de perfil alterada com sucesso.');                    
                    this.loadProjectDetails();
                }
            );
        });

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemPerfil'), 4/3, UploadImageTypeEnum.profile);
    }

    openParticipations(){
        this.updateParticipationsSubscription = this.participationService.updateParticipationEvent.subscribe(x => {
            this.loadProjectDetails();
        });
        
        this.modalService.openParticipationsModal(this.id, ParticipationEnum.project, this.author, false);
    }

    openImage(id:number){
        this.router.navigate(['web', 'home', 'project', this.id, 'image', id]);
    }

    openVideo(id:number){
        this.router.navigate(['web', 'home', 'project', this.id, 'video', id]);
    }

    openImages(){
        this.router.navigate(['web', 'home','project', this.id, 'images']);
    }

    openVideos(){
        this.router.navigate(['web', 'home','project', this.id, 'videos']);
    }

    toggleIsFollowing(){
        this.project.isFollowing = !this.project.isFollowing;
        this.projectService.toggleFollowProject(this.id).subscribe(
            (response:any) => {

            }
        )
    }

    import(){
        this.router.navigate(['web', 'home', 'event', 'import', this.id]);
    }
}