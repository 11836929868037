import { NgModule } from '@angular/core';
import { HomeUserComponent } from './home-user.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HomeUserEventsComponent } from './home-user-events/home-user-events.component';
import { HomeUserAdsComponent } from './home-user-ads/home-user-ads-component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { HomeUserRoutingModule } from './home-user.routing.module';
import {MatListModule} from '@angular/material/list';
import { HomeUserBaseComponent } from './home-user-base/home-user-base.component';
import { SideBarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import { LocationModalComponent } from '../shared/modals/components/location-modal/location-modal.component';
import { EventModule } from '../event/event.module';
import { DeleteModalComponent } from '../shared/modals/components/delete-modal/delete-modal.component';
import { GenreModule } from '../genre/genre.module';
import { UploadImageModalComponent } from '../shared/modals/components/upload-image/upload-image-modal.component';
import { ParticipationListModalComponent } from '../shared/modals/components/participations/participation-list-modal/participation-list-modal.component';
import { CreateParticipationModalComponent } from '../shared/modals/components/participations/create-participation-modal/create-participation-modal.component';
import { ProjectModule } from '../project/project.module';
import { GroupModule } from '../group/group.module';
import { MembersListComponent } from '../group/members/members-list/members-list.component';
import { MembersNewComponent } from '../group/members/members-new/members-new.component';
import { UserModule } from '../user/user.module';
import { AdsModule } from '../ads/ads.module';
import { AdsRenewComponent } from '../ads/ads-renew/ads-renew.component';
import { SearchMusiciansModule } from '../search-musicians/search-musicians.module';
import { UserConfigurationsModule } from '../user-configurations/user-configurations.module';
import { EditCommentModalComponent } from '../shared/modals/components/edit-comment-modal/edit-comment-modal.component';
import { DeleteCommentModalComponent } from '../shared/modals/components/delete-comment-modal/delete-comment-modal.component';
import { NotificationModule } from '../notification/notification-list/notification.module';
import { MusicalAffinitiesModule } from '../musical-affinities/musical-affinities.module';
import { BusinessModule } from '../business/business.module';
import { UserGalleryModule } from '../user-gallery/user-gallery.module';
import { MessageModule } from '../message/message.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComplaintModalComponent } from '../shared/modals/components/complaint-modal/complaint-modal.component';
import { HomeUserFiltersModalComponent } from './home-user-filters-modal/home-user-filters-modal.component';
import { MatButtonModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations:[
        HomeUserBaseComponent,
        HomeUserComponent,
        HomeUserEventsComponent,
        HomeUserAdsComponent,
        SideBarComponent,
        HomeUserFiltersModalComponent
    ],
    imports:[
        CommonModule,
        SharedModule,
        MatIconModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatListModule,
        HomeUserRoutingModule,
        MatDialogModule,
        EventModule,
        ProjectModule,
        GroupModule,
        UserModule,
        AdsModule,
        GenreModule,
        SearchMusiciansModule,
        UserConfigurationsModule,
        NotificationModule,
        MusicalAffinitiesModule,
        BusinessModule,
        UserGalleryModule,
        MessageModule,
        TranslateModule,
        MatButtonModule,
        ReactiveFormsModule,
    ],
    entryComponents: [
        LocationModalComponent,
        DeleteModalComponent, 
        UploadImageModalComponent,
        ParticipationListModalComponent,
        CreateParticipationModalComponent,
        MembersListComponent,
        MembersNewComponent,
        AdsRenewComponent,
        EditCommentModalComponent,
        DeleteCommentModalComponent,
        ComplaintModalComponent,
        HomeUserFiltersModalComponent
    ]
})
export class HomeUserModule {

}