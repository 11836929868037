import { NgModule } from '@angular/core';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatListModule, MatDatepickerModule, MatCardModule } from '@angular/material';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { UserInstrumentsComponent } from './user-instruments/user-instruments.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UserFavoriteListComponent } from './user-favorite-list/user-favorite-list.component';
import {MatTabsModule} from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';
import { BusinessModule } from '../business/business.module';

@NgModule({
    declarations: [
        UserDetailComponent,
        UserEditComponent,
        UserInstrumentsComponent,
        UserFavoriteListComponent,
        UserSidebarComponent
    ],

    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        GooglePlaceModule,
        MatDatepickerModule,
        MatListModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatCardModule,
        TranslateModule,
        BusinessModule
    ],

    exports:[
        UserSidebarComponent
    ]
})
export class UserModule{
    
}