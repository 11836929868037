import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormErrorComponent } from './form-error.component';

@NgModule({
    declarations:[FormErrorComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule
    ],
    exports:[FormErrorComponent]
    
})
export class FormErrorModule {

}