import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatListModule } from '@angular/material';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AdsListComponent } from './ads-list/ads-list.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductNewEditComponent } from './products/product-new-edit/product-new-edit.component';
import { ServiceDetailComponent } from './services/service-detail/service-detail.component';
import { ServiceNewEditComponent } from './services/service-new-edit/service-new-edit.component';
import {MatSelectModule} from '@angular/material/select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AdsRenewComponent } from './ads-renew/ads-renew.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { BusinessModule } from '../business/business.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations:[
        AdsListComponent,
        ProductDetailComponent,
        ProductNewEditComponent,
        ServiceDetailComponent,
        ServiceNewEditComponent,
        AdsRenewComponent
    ],
    
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        GooglePlaceModule,
        MatListModule,
        NgxMaskModule.forRoot(options),
        MatSelectModule,
        TranslateModule,
        UserModule,
        BusinessModule
    ]
})
export class AdsModule {
    
}