import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OwnerEnum } from '../shared/enums/owner-enum';

@Injectable({providedIn: 'root'})
export class UserGalleryService {

    constructor(private httpclient:HttpClient){}

    getUserGallery(userId:number){
        let queryParams = new HttpParams()
            .set('ownerId', userId.toString())
            .set('ownerenum', (<number>OwnerEnum.User).toString());

        return this.httpclient.get('gallery', {params: queryParams});
    }
}