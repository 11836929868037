import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mscope-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
show = false;
private subscription: Subscription;
mode = 'indeterminate';
value = 50;

constructor(private loaderService: LoaderService,
            private cd:ChangeDetectorRef) { }
ngOnInit() {         
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
                this.cd.detectChanges();
            });
    }
ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}