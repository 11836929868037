import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class BusinessService {

    constructor(private httpClient: HttpClient){}

    getProductCategories(){
        return this.httpClient.get(`categoriesproduct`);
    }   

    getServiceCategories(){
        return this.httpClient.get(`categoriesservice`);
    }

    searchProducts(searchData:string, productCategory: string, cityId:string){
        let queryParams = new HttpParams()
            .set('page', '1')
            .set('pageSize', '1000')
            .set('IdCity', cityId);

            if(searchData){
                queryParams = queryParams.set('searchData', searchData);
            }

            if(productCategory){
                queryParams = queryParams.set('productCategory', productCategory);
            }


        return this.httpClient.get('product', {params: queryParams});
    }

    searchServices(searchData:string, serviceCategory: string, cityId:string){
        let queryParams = new HttpParams()
            .set('page', '1')
            .set('pageSize', '1000')
            .set('IdCity', cityId);

            if(searchData){
                queryParams = queryParams.set('searchData', searchData);
            }

            if(serviceCategory){
                queryParams = queryParams.set('serviceCategory', serviceCategory);
            }

        return this.httpClient.get('service', {params: queryParams});
    }
    
    initialRequests(cityId:string): Observable<any[]>{
        const productCategoryRequest = this.getProductCategories();
        const serviceCategoryRequest = this.getServiceCategories();
        const searchProductRequest = this.searchProducts(null, null, cityId);
        const searchServicesRequest = this.searchServices(null, null, cityId);

        return forkJoin([productCategoryRequest, serviceCategoryRequest, searchProductRequest, searchServicesRequest]);
    }
}