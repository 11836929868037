import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { TokenService } from 'src/app/core/token/token.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { CreateUserResponseDto } from 'src/app/core/dtos/create-user-response-dto';
import { User } from 'src/app/core/models/user';
import { StringService } from 'src/app/shared/helpers/string.service';
import { CustomPasswordValidator } from 'src/app/core/validators/custom-password.validator';
import { SignInService } from '../signin/signin.service';
import { FacebookAuthService } from 'src/app/core/facebook-auth/facebook-auth.service';
import { UserLoginDto } from 'src/app/core/dtos/user-login-dto';
import { UserLoginResultDto } from 'src/app/core/dtos/user-login-result-dto';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/language/language.service';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['../home.component.scss', './register.component.scss']
})
export class RegisterComponent implements OnInit {
    hide = true;
    registerForm: FormGroup;

    constructor(
        public translate: TranslateService,
        private languageService: LanguageService,
        private signService: SignInService,
        private facebookAuthService: FacebookAuthService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private tokenService: TokenService,
        private userPreferences: UserPreferencesService,
        private stringService: StringService,
        private userStorageService: UserStorageService) { }

    ngOnInit(): void {
        this.setFormValidations();
    }

    setFormValidations() {
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: [null, Validators.compose([
                Validators.required,
                CustomPasswordValidator.patternValidator(/\d/, { hasNumber: true }),
                CustomPasswordValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                CustomPasswordValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
                Validators.minLength(6)])
            ],
            confirmPassword: ['',
                [Validators.required]
            ],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            fullName: ['', Validators.required],
            isMusician: [false, Validators.required]
        },
            {
                validators: CustomPasswordValidator.passwordMatchValidator
            });
    }

    create(): void {
        const user = this.registerForm.getRawValue() as User;
        user.language = this.languageService.getlanguage();

        this.userService.createUser(user)
            .subscribe(
                (response: CreateUserResponseDto) => {
                    this.successDefaultLogin(response, user.isMusician);
                }
            );
    }

    private successDefaultLogin(response: CreateUserResponseDto, isMusician: boolean): void {
        this.tokenService.setToken(response.token);
        let user = this.generateUser(response);
        this.userStorageService.setUser(user);
        this.userPreferences.setUserPreferences(user);

        this.router.navigate(['web', 'home', 'user', response.id, 'instruments'], { queryParams: { newUser: true } });
    }

    private successDefaultLoginFacebook(response: UserLoginResultDto): void {
        this.tokenService.setToken(response.token);
        this.userStorageService.setUser(response.user);
        this.userPreferences.setUserPreferences(response.user);
        this.router.navigate(['web', 'home']);
    }

    handleAddressChange(param) {
        this.registerForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.registerForm.get('descriptionCity').patchValue(city);
        this.registerForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.registerForm.get('idCity').patchValue('');
        this.registerForm.get('descriptionCity').patchValue('');
    }

    generateUser(response: CreateUserResponseDto) {
        let user = {
            id: response.id,
            email: response.email,
            fullName: response.fullName,
            urlProfilePicture: response.urlProfilePicture,
            idCity: response.idCity,
            descriptionCity: response.descriptionCity,
            isMusician: response.isMusician
        }

        return <User>user;
    }

    login() {
        this.router.navigate(['web', 'signin']);
    }

    async registerWithFacebook() {
        const userFb = await this.facebookAuthService.signIn();
        if (userFb) {
            const userLogin: UserLoginDto = {
                appId: userFb.id,
                grantType: 'socialnetwork',
                email: userFb.email,
                language: this.languageService.getlanguage(),
                password: null
            };

            this.signService.login(userLogin)
                .subscribe(
                    (response: any) => {
                        console.log(response)
                        if (response && response.user) {
                            this.successDefaultLoginFacebook(response);
                        } else {
                            this.router.navigate(['web', 'register-by-facebook'], {
                                state: {
                                    data: {
                                        id: userFb.id,
                                        fullName: `${userFb.firstName} ${userFb.lastName}`,
                                        photoUrl: `http://graph.facebook.com/${userFb.id}/picture?type=large`,
                                        email: userFb.email
                                    }
                                }
                            });
                        }
                    })
        }
    }
}