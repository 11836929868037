import { Component, OnInit } from '@angular/core';
import { SearchMusiciansService } from '../search-musicians.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { Instrument } from 'src/app/core/models/instrument';
import { Router } from '@angular/router';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { StringService } from 'src/app/shared/helpers/string.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GooglePlaceDto } from 'src/app/core/dtos/google-place-dto';
import { LocationModalService } from 'src/app/shared/modals/components/location-modal/location-modal.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './filter-search-musicians.component.html',
    styleUrls: ['./filter-search-musicians.component.scss']
})
export class FilterSearchMusiciansComponent implements OnInit {
    idCity:string;
    descriptionCity:string;
    instruments:Instrument[] = [];
    originalInstruments: Instrument[] = [];
    debounce: Subject<string> = new Subject<string>();
    changeCitySubscription:Subscription;

    constructor(
       public translate:TranslateService,
       private router: Router,
       private userStorageService:UserStorageService,
       private stringService:StringService,
       private modalsService:ModalsService,
       private searchMusiciansService:SearchMusiciansService){}

    ngOnInit(): void {
        this.setDefaultCity();
        this.getInstruments();
        this.subscribeChangeLocation();

        this.debounce
        .pipe(debounceTime(300))
        .subscribe(filter => {
            this.filterInstruments(filter);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getInstruments();
        })
    }

    setDefaultCity(){
        let user = this.userStorageService.getUser();
        this.idCity = user.idCity;
        this.descriptionCity = user.descriptionCity;
    }

    getInstruments(){
        this.searchMusiciansService.getAllInstruments().subscribe(
            (response:ScopeListDto<Instrument>) => {
                this.instruments = response.items;
                this.originalInstruments = response.items;
            })
    }

    search(instrument:Instrument){
        this.router.navigate(['web', 'home', 'search-musicians', 'result'], { queryParams: { idCity: this.idCity, descriptionCity: this.descriptionCity, instrument: instrument.key, descriptionInstrument: instrument.name }});
    }

    filterInstruments(search: string) {
        const tempInstruments: Instrument[] = [];
        if (!search) {
            this.originalInstruments.forEach(genre => {
                tempInstruments.push(genre);
            });
            this.instruments = this.originalInstruments;
        } else {
            search = this.stringService.removeAccents(search);

            this.originalInstruments.forEach(instrument => {
                if (this.stringService.removeAccents(instrument.name.toLowerCase()).indexOf(search.toLowerCase()) > -1) {
                    tempInstruments.push(instrument);
                }
            });

            this.instruments = tempInstruments;
        }
    }

    subscribeChangeLocation(){
        this.changeCitySubscription = LocationModalService.cityChangedEvent.subscribe((city:GooglePlaceDto) => {
            this.idCity = city.id;
            this.descriptionCity = city.description;
            this.modalsService.closeModal();
        })
    }

    openModalChangeLocation(){
        this.modalsService.openLocationModal();        
      }
}