import { NgModule } from '@angular/core';
import { NotificationListComponent } from './notification-list.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations:[
        NotificationListComponent
    ],

    imports:[
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        TranslateModule
    ]
})
export class NotificationModule{

}