import { Injectable } from '@angular/core';
import { User } from '../models/user';

const KEY = 'userData';

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    setUser(user: User): void {
        window.localStorage.setItem(KEY, JSON.stringify(user));
    }

    removeUser(): void {
        window.localStorage.removeItem(KEY);
    }

    getUser(): User{
        let user:User = JSON.parse(window.localStorage.getItem(KEY));
        return user;
    }

    isAuthor(idUser:number){
        let user:User = JSON.parse(window.localStorage.getItem(KEY));
        return user.id == idUser;
    }   
    
    
}