import { Injectable, EventEmitter } from '@angular/core';
import { GooglePlaceDto } from 'src/app/core/dtos/google-place-dto';

@Injectable({providedIn: 'root'})
export class LocationModalService {
    static cityChangedEvent = new EventEmitter<GooglePlaceDto>();

    constructor(){
        
    }

    sendCity(city: GooglePlaceDto){
        LocationModalService.cityChangedEvent.emit(city);
    }
}