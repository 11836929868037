import { NgModule } from "@angular/core";
import { ConfigBlockedListComponent } from "./config-blocked-list/config-blocked-list.component";
import { ConfigChangeEmailComponent } from "./config-change-email/config-change-email.component";
import { ConfigChangePasswordComponent } from "./config-change-password/config-change-password.component";
import { ConfigDeleteAccountComponent } from "./config-delete-account/config-delete-account.component";
import { ConfigReportErrorComponent } from "./config-report-error/config-report-error.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatSlideToggleModule,
  MatListModule,
  MatSelectModule,
} from "@angular/material";
import { UserConfigurationsComponent } from "./user-configurations.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    ConfigBlockedListComponent,
    ConfigChangeEmailComponent,
    ConfigChangePasswordComponent,
    ConfigDeleteAccountComponent,
    ConfigReportErrorComponent,
    UserConfigurationsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatListModule,
    TranslateModule,
    FormsModule,
    MatSelectModule,
  ],
})
export class UserConfigurationsModule {}
