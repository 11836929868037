import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MenuComponent } from './menu/menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { LocationModalComponent } from './modals/components/location-modal/location-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormErrorModule } from '../core/components/form-error/form-error.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DeleteModalComponent } from './modals/components/delete-modal/delete-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadImageModalComponent } from './modals/components/upload-image/upload-image-modal.component';
import { ParticipationListModalComponent } from './modals/components/participations/participation-list-modal/participation-list-modal.component';
import { CreateParticipationModalComponent } from './modals/components/participations/create-participation-modal/create-participation-modal.component';
import {MatRadioModule} from '@angular/material/radio';
import { ImagesModule } from './components/images/images.module';
import { VideosModule } from './components/videos/videos.module';
import { EditCommentModalComponent } from './modals/components/edit-comment-modal/edit-comment-modal.component';
import { DeleteCommentModalComponent } from './modals/components/delete-comment-modal/delete-comment-modal.component';
import { MatBadgeModule, MatMenuModule, MatSelectModule } from '@angular/material';
import { TranslateModule} from '@ngx-translate/core';
import { PaymentDoneComponent } from './components/payment-done/payment-done.component';
import { ComplaintModalComponent } from './modals/components/complaint-modal/complaint-modal.component';

@NgModule({
    declarations:[
        MenuComponent,
        LocationModalComponent,
        DeleteModalComponent,
        UploadImageModalComponent,
        ParticipationListModalComponent,
        CreateParticipationModalComponent,
        EditCommentModalComponent,
        DeleteCommentModalComponent,
        PaymentDoneComponent,
        ComplaintModalComponent
    ],
    imports:[
        ImagesModule,
        VideosModule,
        CommonModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatFormFieldModule,
        FormErrorModule,
        GooglePlaceModule,
        ImageCropperModule,
        RouterModule,
        MatBadgeModule,
        MatMenuModule,
        MatSelectModule,
        TranslateModule
    ],
    exports:[
        MenuComponent
    ]
})
export class SharedModule {

}