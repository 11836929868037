import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import { ToastService } from 'src/app/core/components/toast/toast.service';
import { ErrorResponseDto } from '../dtos/error-response-dto';

export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private toastService: ToastService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(tap(
        (event: HttpEvent<any>) => {
          // Do Nothing
        },
        (err: any) => {
          if (err.status == 400 || err.status == 500 ) {
            var response = <ErrorResponseDto>err.error;            
            if(response){
              this.toastService.error(response.errorNotifications[0].message);
            }else {
              this.toastService.error('Por favor, tente novamente.');
            }            
          } else if (err.status == 403 || err.status == 401) {
            if(err.status == 403){
              this.toastService.error('E-mail ou senha incorretos.');
            }else{
              this.toastService.error('Permissão negada.');
            }
          }
        },
      ));
    }
}