import { NgModule } from '@angular/core';
import { CreateVideoComponent } from './create-video/create-video.component';
import { WatchVideoComponent } from './watch-video/watch-video.component';
import { VideoListComponent } from './video-list/video-list.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule } from '@angular/material';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    declarations:[
        CreateVideoComponent,
        WatchVideoComponent,
        VideoListComponent
    ],

    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        YouTubePlayerModule,
        TranslateModule,
        InfiniteScrollModule
    ]
})
export class VideosModule {

}