import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordService } from '../forgot-password.service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    
    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
                private forgotPasswordService: ForgotPasswordService,
                private toastService: ToastService,
                private dialog: MatDialog){}
    
    ngOnInit(): void {
        this.setFormValidations();
    }

    setFormValidations() {
        this.forgotPasswordForm = this.formBuilder.group({
          email: ['',
            [Validators.required, Validators.email]
          ],          
        });
    }

    sendForgotPasswordEmail(){
        let email = this.forgotPasswordForm.get('email').value;
        this.forgotPasswordService.forgotPassword(email).subscribe(
            () => {
                this.dialog.closeAll();
                let message = this.translate.instant('EmailEnviadoRedefinicaoSenha');
                message = message.replace('{Email}', email);
                this.toastService.success(message);
            }   
        );
    }
}