import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OwnerEnum } from '../../enums/owner-enum';

@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    constructor(private http:HttpClient){

    }

    get(id:number, ownerEnum:OwnerEnum){
        return this.http.get(`gallery/${Number(ownerEnum)}/${Number(id)}`);
    }

    getAll(ownerId:number, ownerEnum:OwnerEnum){
        let params = new HttpParams()
        .set('ownerId', ownerId.toString())
        .set('ownerEnum', Number(ownerEnum).toString())
        .set('typeMediaEnum', '2');
        
        return this.http.get('gallery', {params: params});
    }

    create(ownerId:number, ownerEnum:OwnerEnum, description:string, blob: Blob){
        let body = {
            ownerId,
            ownerEnum,
            enumTypeMedia: 2,
            description
        };

        let formData = new FormData();
        formData.append('file', blob, 'gallery-image');
        formData.append('dto', JSON.stringify(body));

        return this.http.post(`gallery`, formData);
    }

    edit(id:number, ownerEnum:OwnerEnum, description:string){
        let body = {
            ownerEnum: Number(ownerEnum),
            enumTypeMedia: 2,
            description            
        };
        
        return this.http.put(`gallery/${Number(id)}`, body);
    }

    delete(id:number, ownerEnum:OwnerEnum){
        return this.http.delete(`gallery/${Number(ownerEnum)}/${Number(id)}`);
    }    
}