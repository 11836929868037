import { Genre } from './genre';
import { Author } from './author';
import { EventModel } from './event';
import { Project } from './project';
import { Gallery } from './gallery';
import { Group } from './group';
import { Instrument } from './instrument';

export class User{
    id:number;
    email: string;
    fullName: string;
    url: string;
    creationDate: Date;
    password:string;
    birthDate: Date;
    isMusician: boolean;
    urlProfilePicture: string;
    urlCoverPicture: string;
    idCity: string;
    facebookId: string;
    googleId: string;
    biography: string;
    allowShareSocial: boolean;
    descriptionCity: string;
    androidDeviceId: string; 
    iosDeviceId: string;
    language: string;
    numberFollowers:number;
    isBlocked: boolean;
    isFavorite: boolean;
    isFollowing: boolean;
    numberFollowing:number; 
    appId:string;
    socialNetwork: boolean;

    notificationsEventsEmail: boolean;
    notificationsEventsSound: boolean;
    allowBeFoundMusicians: boolean;
    notificationsEventsAlerts: boolean;
    notificationsFavoritesEmail: boolean;
    notificationsFavoritesSound: boolean;
    notificationsFavoritesAlerts: boolean;
    notificationsFollowingEmail: boolean;
    notificationsFollowingSound: boolean;
    notificationsFollowingAlerts: boolean;

    genresUserWork: Genre[];
    groups:Group[];
    events:EventModel[];
    projects:Project[];
    galleries: Gallery[];
    genresUserLike: Genre[]
    instruments: Instrument[]
}