import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventModel } from '../core/models/event';

@Injectable({providedIn: 'root'})
export class EventService {

    constructor(private http: HttpClient){}

    getEventDetail(id:number){
        return this.http.get(`event/${id}/${true}`);
    }

    getEventForEdit(id:number){
        return this.http.get(`event/${id}/${false}`);
    }

    getEventsByUser(){
        return this.http.get('event/getallbyuser');
    }

    createEvent(event:EventModel){
        return this.http.post('event', event);
    }

    editEvent(id:number, event:EventModel){
        return this.http.put(`event/${id}`, event);
    }

    delete(id:number){
        return this.http.delete(`event/${id}`);
    }

    updateCoverImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'event-image-cover');

        return this.http.post(`event/updatepicture/${id}/cover`, formData);
    }

    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'event-image-profile');

        return this.http.post(`event/updatepicture/${id}/profile`, formData);
    }

    togglesavedEvent(id:number, remove:boolean){
        if(remove){
            return this.http.delete(`event/usersavedevent/${id}`);
        }
        
        return this.http.patch(`event/usersavedevent/${id}`, '');
    }

    importEvent(projectId:number, event:EventModel){
        return this.http.post(`event/import/${projectId}`, event);
    }

    acceptOrRefuseParticipation(eventId:number, accept:boolean, isGroup:boolean, groupId?:number){
        if(isGroup){
            return this.http.patch(`event/groupevent/${eventId}/accept/${accept}?groupid=${groupId}`, null);
        }
        
        return this.http.patch(`event/userevent/${eventId}/accept/${accept}`, null);
    }
    
    getStoredEvents(){
        return this.http.get('event/usersavedevent');
    }
}