import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../core/models/user';
import { Group } from '../core/models/group';
import { MusicalAffinitiesService } from './musical-affinities.service';
import { Genre } from '../core/models/genre';
import { UserStorageService } from '../core/user/user-storage.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LocationModalService } from '../shared/modals/components/location-modal/location-modal.service';
import { GooglePlaceDto } from '../core/dtos/google-place-dto';
import { ModalsService } from '../shared/modals/modals.service';
import { StringService } from '../shared/helpers/string.service';
import { filter } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './musical-affinities.component.html',
    styleUrls: ['./musical-affinities.component.scss']
})
export class MusicalAffinitiesComponent implements OnInit {
    users: User[] = [];
    musicians: User[] = [];
    groups: Group[] = [];
    genres: Genre[];
    cityId:string;
    descriptionCity:string;
    changeLocationSubscription:Subscription;
    genresString:string;
    overview:string;

    constructor(
        public translate:TranslateService,
        private userStorageService:UserStorageService,
        private router:Router,
        private stringService:StringService,
        private modalsService:ModalsService,
        private musicalAffinitiesService:MusicalAffinitiesService){}

    ngOnInit(): void {
        this.initialize();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.overview = this.getOverview(this.users.length + this.musicians.length, this.groups.length, event.lang);
            this.genresString = !this.genres || this.genres.length == 0 ? this.genresString = this.translate.instant('EntreGeneroMusical') : this.genresString;
        })
    }

    initialize(){
        let user = this.userStorageService.getUser();
        let filters = this.musicalAffinitiesService.getFilters();
        if(!filters){
            filters = this.musicalAffinitiesService.setFilters(null, user.idCity, user.descriptionCity);
        }

        this.genres = filters.genres;
        this.genresString = !this.genres || this.genres.length == 0 ? this.translate.instant('EntreGeneroMusical') : this.stringService.joinGenres(this.genres.map(x => x.name));
        this.cityId = filters.cityId;
        this.descriptionCity = filters.descriptionCity;

        if(this.genres && this.genres.length > 0){
            this.search(this.genres, this.cityId);
        }
    }

    search(genres:Genre[], cityId:string){
        this.musicalAffinitiesService.search(genres.map(x => x.key), cityId).subscribe(
            (response:any) => {
                this.users = <User[]>response[0].items;
                this.musicians = <User[]>response[1].items;
                this.groups = <Group[]>response[2].items;

                this.overview = this.getOverview(this.users.length + this.musicians.length, this.groups.length, this.translate.currentLang);
            }
        )
    }

    changeCity(){
        this.changeLocationSubscription = LocationModalService.cityChangedEvent.subscribe((city:GooglePlaceDto) => {
            this.musicalAffinitiesService.setCityFilter(city.id, city.description);
            this.cityId = city.id;
            this.descriptionCity = city.description;
            this.search(this.genres, city.place_id);
            this.modalsService.closeModal();          
        });

        this.modalsService.openLocationModal();
    }
    
    changeGenres(){
        this.router.navigate(['web', 'home', 'musical-affinities', 'genres']);
    }

    openUser(id:number){
        this.router.navigate(['web', 'home', 'user', id]);
    }

    openGroup(id:number){
        this.router.navigate(['web', 'home', 'group', id]);
    }

     getOverview(users:number, groups:number, language:string = 'pt')
    {
        switch (language)
        {
            case "en":
                return `Total: ${users} persons and ${groups} musical groups are interested in:`;
            case "pt":
                return `Total: ${users} pessoas e ${groups} grupos musicais se interessam por:`;
            case "es":
                return `Total: ${users} personas y ${groups} grupos Musicales están interesados en:`;
            case "fr":
                return `Total: ${users} personnes et ${groups} groupes de musique sont intéressés par:`;
            case "it":
                return `Totale: ${users} persone e ${groups} gruppi musicali sono interessati a:`;
            case "de":
                return `Ergebnis: ${users} Personen und ${groups} Musikgruppen sind interessiert an:`;
            default:
                return `Total: ${users} people and ${groups} musical groups are interested in:`;
        }
    }
}