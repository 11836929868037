import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UserConfigurationsService } from '../user-configurations.service';
import { CustomPasswordValidator } from 'src/app/core/validators/custom-password.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './config-change-password.component.html',
    styleUrls: ['./config-change-password.component.scss']
})
export class ConfigChangePasswordComponent implements OnInit {
    configForm: FormGroup;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastService: ToastService,
        private userConfigurationsService: UserConfigurationsService) { }

    ngOnInit(): void {
        this.setFormValidations();
    }

    changePassword() {
        let oldPassword = this.configForm.get('oldPassword').value;
        let newPassword = this.configForm.get('password').value;

        this.userConfigurationsService.changePassword(oldPassword, newPassword).subscribe(
            () => {
                this.toastService.success('Senha alterada com sucesso.');
                this.router.navigate(['web', 'home', 'settings']);
            }
        )
    }

    setFormValidations() {
        this.configForm = this.formBuilder.group({
            oldPassword: ['', [Validators.required]],
            password: [null, Validators.compose([
                Validators.required,
                CustomPasswordValidator.patternValidator(/\d/, { hasNumber: true }),
                CustomPasswordValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                CustomPasswordValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
                Validators.minLength(6)])
            ],
            confirmPassword: ['',
                [Validators.required]
            ],
        },
        {
            validators: CustomPasswordValidator.passwordMatchValidator
        });
    }
}