import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    templateUrl: './complaint-modal.component.html',
    styleUrls: ['./complaint-modal.component.scss']
})
export class ComplaintModalComponent implements OnInit {
    ownerEnum:OwnerEnum;
    ownerId:number;
    complaintForm: FormGroup;
    title:string;

    constructor(
        public translate: TranslateService,
        private toastService: ToastService,
        private formBuilder: FormBuilder,
        private sharedService: SharedService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }


    ngOnInit(): void {
        this.ownerId = this.data.ownerId;
        this.ownerEnum = this.data.ownerEnum;
        this.initForm();
        this.getTitle();
    }

    initForm(){
        this.complaintForm = this.formBuilder.group({
            description: ['', [Validators.required]],
        });
    }

    send() {
        let description = this.complaintForm.get('description').value;
        
        this.sharedService.sendComplaint(description, this.ownerEnum, this.ownerId).subscribe(
            (response) => {
                this.toastService.success(this.translate.instant('MensagemDenunciaSucesso'));
                this.dialog.closeAll();
            }
        )
    }

    getTitle(){
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.title = this.translate.instant('DenunciarEvento');
                break;
            case OwnerEnum.Group:
                this.title = this.translate.instant('DenunciarGrupo');
                break;
            case OwnerEnum.Product:
                this.title = this.translate.instant('DenunciarAnuncio');
                break;
                case OwnerEnum.Service:
                    this.title = this.translate.instant('DenunciarServico');
                break;
                case OwnerEnum.User:
                    this.title = this.translate.instant('DenunciarUsuario');
                break;
        }
    }
}