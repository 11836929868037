import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class HomePageService {
    constructor(private http:HttpClient){}

    sendEmail(name:string, email:string, description:string){
        let body = {
            name: name,
            email: email,
            description: description
        };

        return this.http.post('sendemail', body);
    }
}