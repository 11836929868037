import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Component({
  templateUrl: "./home-user.component.html",
  styleUrls: ["./home-user.component.scss"],
})
export class HomeUserComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.checkUserData();
  }

  checkUserData(): void {
    const userData = localStorage.getItem("userData");

    if (!userData) {
      this.router.navigate(["/web"]);
    }
  }
}
