import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { MatIconModule } from '@angular/material/icon';
import { EventListComponent } from './event-list/event-list.component';
import { EventNewEditComponent } from './event-new-edit/event-new-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EventImportComponent } from './event-import/event-import.component';
import { EventStoredListComponent } from './event-stored-list/event-stored-list.component';
import { MatSlideToggleModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { BusinessModule } from '../business/business.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        EventDetailComponent,
        EventListComponent,
        EventNewEditComponent,
        EventImportComponent,
        EventStoredListComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        GooglePlaceModule,
        MatMomentDateModule,
        NgxMaterialTimepickerModule.setLocale('pt-BR'),
        MatAutocompleteModule,
        MatButtonModule,
        MatSlideToggleModule,
        NgxMaskModule.forRoot(options),
        TranslateModule,
        UserModule,
        BusinessModule        
    ],
    exports: []
})
export class EventModule {
   
}