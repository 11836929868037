import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { SignInService } from './signin.service';
import { UserLoginDto } from 'src/app/core/dtos/user-login-dto';
import { FacebookAuthService } from 'src/app/core/facebook-auth/facebook-auth.service';
import { UserLoginResultDto } from 'src/app/core/dtos/user-login-result-dto';
import { TokenService } from 'src/app/core/token/token.service';
import { Router } from '@angular/router';
import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AuthService } from 'angularx-social-login';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/language/language.service';

@Component({
    selector: 'mscope-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['../home.component.scss', './signin.component.scss']
})
export class SignInComponent implements OnInit {
    hide = true;
    signForm: FormGroup;

    constructor(
        public translate:TranslateService,
        private languageService:LanguageService,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private signService: SignInService,
        private facebookAuthService: FacebookAuthService,
        private router: Router,
        private tokenService: TokenService,
        private userStorageService: UserStorageService,
        private modalService: ModalsService,
        private authService: AuthService,
        private userPreferences: UserPreferencesService) { }

    ngOnInit(): void {
        this.setFormValidations();

        this.authService.authState.subscribe((user) => {
        });
    }

    login(): void {
        const userLogin = this.signForm.getRawValue() as UserLoginDto;
        userLogin.grantType = 'password';
        userLogin.language = this.languageService.getlanguage();

        this.signService.login(userLogin)
            .subscribe(
                (response: UserLoginResultDto) => {
                    this.successDefaultLogin(response);
                },
                () => {
                    this.signForm.get('password').patchValue('');
                }
            );
    }

    private successDefaultLogin(response: UserLoginResultDto): void {
        this.tokenService.setToken(response.token);
        this.userStorageService.setUser(response.user);
        this.userPreferences.setUserPreferences(response.user);
        this.router.navigate(['web', 'home']);
    }

    async loginWithFacebook() {
        const userFb = await this.facebookAuthService.signIn();
        if (userFb) {
            const userLogin: UserLoginDto = {
                appId: userFb.id,
                grantType: 'socialnetwork',
                email: userFb.email,
                language: this.languageService.getlanguage(),
                password: null
            };

            this.signService.login(userLogin)
                .subscribe(
                    (response:any) => {
                        console.log(response);
                        if (response && response.user) {
                            this.successDefaultLogin(response);
                        } else {
                            this.router.navigate(['web', 'register-by-facebook'], {
                                state: {
                                    data: {
                                        id: userFb.id,
                                        fullName: `${userFb.firstName} ${userFb.lastName}`,
                                        photoUrl: `http://graph.facebook.com/${userFb.id}/picture?type=large`,
                                        email: userFb.email
                                    }
                                }
                            });
                        }
                    })
        }
    }

    setFormValidations() {
        this.signForm = this.formBuilder.group({
            email: ['',
                [Validators.required, Validators.email]
            ],
            password: ['',
                [Validators.required]
            ]
        });
    }

    openForgotPassword() {
        this.modalService.openForgotPasswordModal();
    }

    register() {
        this.router.navigate(['web', 'register']);
    }
}