import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordByEmailDto } from 'src/app/core/dtos/change-password-by-email-dto';

@Injectable({providedIn:'root'})
export class ForgotPasswordService {

    constructor(private http: HttpClient){}

    forgotPassword(email:string){
        return this.http.get(`user/forgotpasswordv2/${email}`);
    }

    changePasswordByEmail(dto:ChangePasswordByEmailDto){
        return this.http.post('user/changepasswordbyemail', dto);
    }
}