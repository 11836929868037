import { Component, OnDestroy, OnInit } from '@angular/core';
import { Group } from 'src/app/core/models/group';
import { Gallery } from 'src/app/core/models/gallery';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { GroupService } from '../group.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UploadImageModalService } from 'src/app/shared/modals/components/upload-image/upload-image-modal-service';
import { TypeMediaEnum } from 'src/app/shared/enums/type-media-enum';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';

@Component({
    templateUrl: './group-detail.component.html',
    styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit, OnDestroy {
    author: boolean = false;
    id: number;
    group: Group;
    members: string;
    genres: string;
    images: Gallery[];
    rowImages: any[] = [];
    videos: Gallery[];

    updateCoverSubscription: Subscription;
    updateProfileSubscription: Subscription;
    updateMembersSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private groupService: GroupService,
        private userStorageService: UserStorageService,
        private stringService: StringService,
        private toastService: ToastService,
        private uploadImageModalService: UploadImageModalService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
            this.loadGroupDetails();
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.members = this.setMembers();
                this.genres = this.setGenres();
            });
        });

        
    }

    ngOnDestroy(): void {
        if (this.updateCoverSubscription) {
            this.updateCoverSubscription.unsubscribe();
        }

        if (this.updateProfileSubscription) {
            this.updateProfileSubscription.unsubscribe();
        }

        if (this.updateMembersSubscription) {
            this.updateMembersSubscription.unsubscribe();
        }
    }

    loadGroupDetails() {
        this.groupService.getGroupDetail(this.id).subscribe(
            (group: Group) => {
                this.author = group.author!.id == this.userStorageService.getUser().id;
                this.group = group;
                
                this.images = group.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.image).slice(-4);
                this.setGenres();
                this.setMembers();
                
                if (this.images) {
                    this.rowImages = this.groupColumns(this.images);
                }

                this.videos = group.galleries.filter(x => x.enumTypeMedia == TypeMediaEnum.video);
            }
        )
    }

    setGenres(){
        this.genres = this.group && this.group.genres && this.group.genres.length > 0 ? this.stringService.joinGenres(this.group.genres.map(x => x.name)) : this.translate.instant('NenhumGeneroVinculado');
        return this.genres;
    }

    setMembers(){
        if(this.group && this.group.members){
            let members = this.stringService.joinMembers(this.group.members);
            this.members = members ? members : this.translate.instant('AdicioneIntegrante');   
        }
        
        return this.members;
    }

    groupColumns(photos: Gallery[]) {
        const newRows = [];

        for (let index = 0; index < photos.length; index += 2)
            newRows.push(photos.slice(index, index + 2));

        return newRows;
    }

    editGroup() {
        this.router.navigate(['web', 'home', 'group', this.id, 'edit']);
    }

    deleteGroup() {
        let title = this.translate.instant('ExcluirGrupo');
        let text = this.translate.instant('PerguntaExcluirGrupo');;
        
        this.modalService.openDeleteModal(title, text, DeleteTypeEnum.group, this.id, ['web', 'home', 'group', 'list']);
    }

    changeGenres(){
        if(this.author){
            this.router.navigate(['web', 'home', 'group', this.id, 'genres']);
        }
    }

    openMessage(){
        if(!this.author){
            this.router.navigate(['web', 'home', 'message', this.group.author.id]);
        }
    }

    getMembers(): string{
        return '';
    }

    changeCoverImage(){
        if(!this.author) return;
        
        this.updateCoverSubscription = this.uploadImageModalService.updateCoverImageEvent.subscribe((blobImage:Blob) => {
            this.groupService.updateCoverImage(this.id, blobImage).subscribe(
                () => {
                    this.loadGroupDetails();
                }
            );
        })

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemCapa'), 21/9, UploadImageTypeEnum.cover);
    }

    changeProfileImage(){
        if(!this.author) return;
        
        this.updateProfileSubscription =  this.uploadImageModalService.updateProfileImageEvent.subscribe((blobImage:Blob) => {
            this.groupService.updateProfileImage(this.id, blobImage).subscribe(
                () => {
                    this.loadGroupDetails();
                }
            );
        });

        this.modalService.openUploadImageModal(this.translate.instant('AlterarImagemPerfil'), 4/3, UploadImageTypeEnum.profile);
    }

    openMembers(){
        this.updateMembersSubscription = this.groupService.updateMemberEvent.subscribe(x => {
            this.loadGroupDetails();
        });
        
        this.modalService.openMembersGroupModal(this.id, this.author, false);
    }

    openImage(id:number){
        this.router.navigate(['web', 'home', 'group', this.id, 'image', id]);
    }

    openVideo(id:number){
        this.router.navigate(['web', 'home', 'group', this.id, 'video', id]);
    }

    openImages(){
        this.router.navigate(['web', 'home','group', this.id, 'images']);
    }

    openVideos(){
        this.router.navigate(['web', 'home','group', this.id, 'videos']);
    }

    openProject(projectId:number){
        this.router.navigate(['web', 'home','project', projectId]);
    }

    openEvent(eventId:number){
        this.router.navigate(['web', 'home','event', eventId]);
    }

    toggleIsFollowing(){
        this.group.isFollowing = !this.group.isFollowing;
        this.groupService.toggleFollowGroup(this.id).subscribe();
    }
    
    toggleIsFavorite(){
        this.group.isFavorite = !this.group.isFavorite;
        this.groupService.toggleFavorite(this.id).subscribe();
    }

    complaint(){
        this.modalService.complaint(OwnerEnum.Group, this.id, true);
    }

    openUrl() {
        if (this.group.url.substring(0, 4) !== 'http') {
            this.group.url = 'http://' + this.group.url;
        }

        window.open(this.group.url, '_blank');
    }
}