import { Injectable } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormErrorService{
    private errorMessages = {
        required: () => 'Este campo é obrigatório.',
        email: () => 'Informe um e-mail válido.',
        minlength: params => `Esse campo precisa ter no mínimo 6 caracteres.`,
        maxlength: params => `Esse campo pode ter no máximo 12 caracteres.`,
        notAcceptNegatives: () => 'Este campo aceita não aceita números negativos.',
        invalidEmail: () => 'Este e-mail não está disponível.',
        noPassswordMatch: () => 'As senhas estão diferentes.',
        onlyNumbers: () => 'Este campo só aceita números.',
        hasNumber: () => 'A senha deve conter número',
        hasCapitalCase: () => 'A senha deve conter letra maíuscula',
        hasSmallCase: () => 'A senha deve conter letra minúscula'
      };

      public getError(control: AbstractControlDirective | AbstractControl): any {
        if (control && control.touched && control.invalid) {
          const error = this.getFirstError(control);
          if (error) {
            return {
              key: error,
              message: this.getMessage(error, control.errors[error]),
            };
          }
        }
        return null;
      }

      private getFirstError(control: AbstractControlDirective | AbstractControl ): string {
        const errors = this.getErrors(control);
        return errors && errors[0];
      }
    
      private getErrors(control: AbstractControlDirective | AbstractControl): string[] {
        return Object.keys(control.errors);
      }
    
      private getMessage(type: string, params: any) {
        return this.errorMessages[type] && this.errorMessages[type](params);
      }
}