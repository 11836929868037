import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/core/token/token.service';
import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { SidenavService } from 'src/app/shared/sidenav.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mscope-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SideBarComponent implements OnInit{
    photoUrl: string;
    fullName: string;
    city: string;
    id:number;

    constructor(
        public translate:TranslateService,
        private router:Router,
        private sidenavService: SidenavService,
        private tokenService: TokenService,
        private userStorageService: UserStorageService,
        private userPreferencesService: UserPreferencesService){}
        
        ngOnInit(): void {
            let user = this.userStorageService.getUser();            
            this.photoUrl = user.urlProfilePicture;
            this.fullName = user.fullName;
            this.city = user.descriptionCity;
            this.id = user.id;
        }


    viewProfile(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'user', this.id])
    }

    yourEvents(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'event', 'list'])
    }

    yourProjects(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'project', 'list'])
    }

    yourGroups(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'group', 'list'])
    }

    yourStoredEvents(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'event', 'stored-events'])
    }

    favorites(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'user', 'favorites']);
    }

    yourAds(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'ads', 'list'])
    }

    openProfile(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'user', this.userStorageService.getUser().id]);   
    }

    searchMusicians(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'search-musicians', 'filter']);   
    }

    settings(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'settings']);   
    }

    notifications(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'notifications']);   
    }

    musicalAffinities(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'musical-affinities']);   
    }

    business(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'ads', 'all']);   
    }

    gallery(){
        this.sidenavService.close();
        this.router.navigate(['web', 'home', 'user', this.id, 'gallery']);   
    }
    
    exit(){
        this.tokenService.removeToken();
        this.userStorageService.removeUser();
        this.userPreferencesService.removeUserPreferences();
        window.sessionStorage.clear();
        this.router.navigate(['web', 'signin']);
    }
}