import { NgModule } from '@angular/core';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectNewEditComponent } from './project-new-edit/project-new-edit.component';
import { ɵangular_packages_common_common_a, CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatIconModule, MatFormFieldModule, MatButtonModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { BusinessModule } from '../business/business.module';

@NgModule({
    declarations:[
        ProjectDetailComponent,
        ProjectListComponent,
        ProjectNewEditComponent
    ],
    
    imports:[
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        TranslateModule,
        UserModule,
        BusinessModule
    ]
})
export class ProjectModule {

}