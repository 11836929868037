import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UploadImageModalService } from './upload-image-modal-service';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { Router } from '@angular/router';

@Component({
    templateUrl: './upload-image-modal.component.html',
    styleUrls: ['./upload-image-modal.component.scss']
})
export class UploadImageModalComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: string;
    title: string = '';
    aspectRatio: number;
    uploadTypeEnum: UploadImageTypeEnum;
    ownerId?: number;
    ownerEnum?: number;
    redirectUrl?: string[];
    mainCropped: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private uploadImageService: UploadImageModalService,
        private router: Router,
        private toastService: ToastService) { }

    ngOnInit(): void {
        this.title = this.data.title;
        this.aspectRatio = this.data.aspectRatio;
        this.uploadTypeEnum = this.data.uploadTypeEnum;
        this.ownerEnum = this.data.ownerEnum;
        this.ownerId = this.data.ownerId;
        this.redirectUrl = this.data.redirectUrl;
        this.mainCropped = this.data.mainCropped;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        this.toastService.error('Não foi possível carregar a imagem');
    }
    confirm() {
        if (this.redirectUrl) {
            this.router.navigate(this.redirectUrl);
        }
        
        this.emitEvent();
        this.dialog.closeAll();
    }
    cancel() {
        this.dialog.closeAll();
    }

    private emitEvent() {
        switch (this.uploadTypeEnum) {
            case UploadImageTypeEnum.profile:
                this.uploadImageService.updateProfileImage(this.croppedImage);
                break;
            case UploadImageTypeEnum.cover:
                this.uploadImageService.updateCoverImage(this.croppedImage);
                break;
            case UploadImageTypeEnum.media:
                this.uploadImageService.createImageToGallery(this.ownerId, this.ownerEnum, this.croppedImage);
                break;
        }
    }
}