import { NgModule } from '@angular/core';
import { BusinessPageComponent } from './business-page/business-page.component';
import { BusinessSidebarComponent } from './business-sidebar/business-siderbar.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatTabsModule, MatFormFieldModule, MatSelectModule, MatInputModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        BusinessPageComponent,
        BusinessSidebarComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule
    ],
    exports:[
        BusinessSidebarComponent
    ]
})
export class BusinessModule {

}