export class ParticipationDto {
    id: number;
    ownerId: number;
    name: string;
    urlProfilePicture: string;
    isGroup:boolean;
    participationId?:number;
    
    constructor(
        id: number,
        ownerId: number,
        name: string,
        urlProfilePicture: string,
        isGroup:boolean,
        participationId?:number){

            this.id = id;
            this.ownerId = ownerId;
            this.name = name;
            this.urlProfilePicture = urlProfilePicture;
            this.isGroup = isGroup;
            this.participationId = participationId;
    }
}