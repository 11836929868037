import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/core/models/gallery';
import { VideosService } from '../videos.service';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './video-list.component.html',
    styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {    
    videoList:Gallery[];
    ownerId:number;
    ownerEnum:OwnerEnum;
    isAuthor:boolean;
    comeBackTitle:string;
    
    constructor(
        public translate:TranslateService,
        private userStorageService:UserStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private videoService:VideosService
    ){}

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.ownerId = +params['ownerId'];
            this.ownerEnum = this.getOwnerEnum();
            this.getComeBackTitle();
            this.getVideos();
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getComeBackTitle();
        })
    }

    getVideos(){
        this.videoService.getAll(this.ownerId, this.ownerEnum).subscribe(
            (response:ScopeListDto<Gallery>) => {
                this.videoList = response.items;
                this.isAuthor = response.isAuthor;
            }
        );
    }

    getVideo(id:number){
        let url = this.getBaseRedirectionUrl();
        url.push(id.toString());
        
        this.router.navigate(url);        
    }

    createVideo(){
        let url = this.getBaseRedirectionUrl();
        url.push('new');
        
        this.router.navigate(url);        
    }

    private getOwnerEnum(){
        let url = this.router.url;
        if(url.indexOf('event') > -1){
            return OwnerEnum.Event;
        }else if(url.indexOf('project') > -1){
            return OwnerEnum.Project;
        }else if(url.indexOf('group') > -1){
            return OwnerEnum.Group;
        }else if(url.indexOf('product') > -1){
            return OwnerEnum.Product;
        }else if(url.indexOf('service') > -1){
            return OwnerEnum.Service;
        }else{
            return OwnerEnum.User;
        }
    }

    private getBaseRedirectionUrl(): string[] {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return ['web', 'home', 'event', this.ownerId.toString(), 'video'];
            case OwnerEnum.Project:
                return ['web', 'home', 'project', this.ownerId.toString(), 'video'];
            case OwnerEnum.Group:
                return ['web', 'home', 'group', this.ownerId.toString(), 'video'];
            case OwnerEnum.Service:
                return ['web', 'home', 'ads', 'service', this.ownerId.toString(), 'video'];
            case OwnerEnum.Product:
                return ['web', 'home', 'ads', 'product', this.ownerId.toString(), 'video'];
            case OwnerEnum.User:
                return ['web', 'home', 'user', this.ownerId.toString(), 'video'];
        }
    }

    getComeBackTitle() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.translate.get("VoltarEvento").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Project:
                this.translate.get("VoltarProjeto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Group:
                this.translate.get("VoltarGrupo").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Service:
                this.translate.get("VoltarServico").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Product:
                this.translate.get("VoltarProduto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.User:
                this.translate.get("VoltarUsuario").subscribe(x => this.comeBackTitle =  x);
        }
    }

    comeBack() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.router.navigate(['web', 'home', 'event', this.ownerId]);
                break;
            case OwnerEnum.Project:
                this.router.navigate(['web', 'home', 'project', this.ownerId]);
                break;
            case OwnerEnum.Group:
                this.router.navigate(['web', 'home', 'group', this.ownerId]);
                break;
            case OwnerEnum.Service:
                this.router.navigate(['web', 'home', 'ads', 'service', this.ownerId]);
                break;
            case OwnerEnum.Product:
                this.router.navigate(['web', 'home', 'ads', 'product', this.ownerId]);
                break;
            case OwnerEnum.User:
                this.router.navigate(['web', 'home', 'user', this.ownerId]);
                break;
        }
    }
}