import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Genre } from '../core/models/genre';
import { MusicalAffinitiesFiltersDto } from '../core/dtos/musical-affinities-filters-dto';
import { Injectable } from '@angular/core';

const KEY = 'musicalAffinitiesFilters';

@Injectable({providedIn: 'root'})
export class MusicalAffinitiesService {

    constructor(private httpClient:HttpClient){}

    search(genres: string[], cityId:string): Observable<any[]> {
        const usersRequest = this.getAllUsers(genres, cityId, false);
        const musiciansRequest = this.getAllUsers(genres, cityId, true);
        const groupsRequest = this.getAllGroups(genres, cityId);

        return forkJoin([usersRequest, musiciansRequest, groupsRequest]);
    }

    setFilters(genres:Genre[], cityId:string, descriptionCity: string){
        let filters = new MusicalAffinitiesFiltersDto();
        filters.cityId = cityId;
        filters.genres = genres;
        filters.descriptionCity = descriptionCity;

        window.sessionStorage.setItem(KEY, JSON.stringify(filters));
        return this.getFilters();
    }

    setGenresFilter(genres:Genre[]){
        let filters:MusicalAffinitiesFiltersDto = JSON.parse(window.sessionStorage.getItem(KEY));
        filters.genres = genres;

        this.removeFilter();
        this.setFilters(filters.genres, filters.cityId, filters.descriptionCity);
    }

    setCityFilter(cityId:string, descriptionCity:string){
        let filters:MusicalAffinitiesFiltersDto = JSON.parse(window.sessionStorage.getItem(KEY));
        filters.cityId = cityId;
        filters.descriptionCity = descriptionCity;

        this.removeFilter();
        this.setFilters(filters.genres, filters.cityId, filters.descriptionCity);
    }

    getFilters(): MusicalAffinitiesFiltersDto{
        let filters:MusicalAffinitiesFiltersDto = JSON.parse(window.sessionStorage.getItem(KEY));
        return filters;
    }

    removeFilter(){
        window.sessionStorage.removeItem(KEY);
    }

    private getAllUsers(genres: string[], cityId:string, isMusician:boolean){
        let params = new HttpParams()
        .append('idcity', cityId)
        .append('pageSize', "100000")
        .append('page', "1")
        .append('IsMusician', isMusician.toString());
        
        genres.forEach(genre => {
            params = params.append('genres', genre);
        });
        
        return this.httpClient.get('getallusersmusicalaffinities', {params: params});
    }

    private getAllGroups(genres: string[], cityId:string){
        let params = new HttpParams()
        .append('idcity', cityId)
        .append('pageSize', "100000")
        .append('page', "1");

        genres.forEach(genre => {
            params = params.append('genres', genre);
        });
        
        return this.httpClient.get('getallgroupsmusicalaffinities', {params: params});
    }
}