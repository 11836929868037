import { componentFactoryName } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: 'payment-done.component.html',
    styleUrls: ['payment-done.component.scss']
})
export class PaymentDoneComponent implements OnInit {

    constructor(
        public translate:TranslateService,
        private router:Router
    ){}

    ngOnInit(): void {
        
    }

    goHome(){
        this.router.navigate(['web', 'home']);
    }
}