import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { UserConfigurationsService } from '../user-configurations.service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './config-blocked-list.component.html',
    styleUrls: ['./config-blocked-list.component.scss']
})
export class ConfigBlockedListComponent implements OnInit {
    users:User[] = [];

    constructor(
        public translate:TranslateService,
        private router:Router,
        private userConfigurationsService:UserConfigurationsService){}

    ngOnInit(): void {
        this.getBlockedUsers();
    }

    getBlockedUsers(){
        this.userConfigurationsService.getBlockedList().subscribe(
            (response:ScopeListDto<User>) => {
                this.users = response.items;
            }
        )
    }

    openUser(id:string){
        this.router.navigate(['web', 'home', 'user', id]);
    }
}