import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../core/models/user';
import { forkJoin } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService {
    static urlProfilePictureUpdatedEvent = new EventEmitter<string>();

    constructor(private http: HttpClient) {}

    getUser(id: number) {
        return this.http.get(`user/${id}`);
    }

    updateUser(user: User) {
        return this.http.put('user', user);
    }

    updateCity(idCity: string, descriptionCity: string) {
        const body = {
            idCity,
            descriptionCity
        }

        return this.http.post('user/updatecity', body);
    }

    getInstruments(){
        return this.http.get('user/instruments');
    }

    updateInstruments(instruments: string[]){
        return this.http.post('user/instruments', instruments);
    }

    updateCoverImage(id: number, blob: Blob){
        const formData = new FormData();
        formData.append('file', blob, 'event-image-cover');

        return this.http.post(`user/updatepicture/cover`, formData);
    }

    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'event-image-profile');

        return this.http.post(`user/updatepicture/profile`, formData);
    }

    toggleFavorite(userId:number){
        return this.http.patch(`favorite/userfavorite/${userId}`, null);
    }

    toggleBlocked(userId:number){
        return this.http.patch(`userblocked/${userId}`, null);
    }

    toggleFollowUser(id:number){
        let queryParams = new HttpParams().set('userId', id.toString());
        return this.http.patch('follow/togglefollowuser', '', {params: queryParams});
    }

    getFavoriteList(){
        const userFavorite = this.http.get('favorite/userfavorite');
        const groupFavorite = this.http.get('favorite/groupfavorite');

        return forkJoin([userFavorite, groupFavorite]);
    }

    createUser(user:User){
        return this.http.post('user', user);
    }
}