import { Injectable } from '@angular/core';
import { DeleteTypeEnum } from 'src/app/shared/enums/delete-type-enum';
import { EventService } from 'src/app/event/event.service';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { ImagesService } from 'src/app/shared/components/images/images.service';
import { ProjectService } from 'src/app/project/project.service';
import { GroupService } from 'src/app/group/group.service';
import { ServiceService } from 'src/app/ads/services/service.service';
import { ProductService } from 'src/app/ads/products/product.service';

@Injectable({
    providedIn: 'root'
})
export class DeleteModalService {
    constructor(
        private eventService: EventService,
        private projectService: ProjectService,
        private imagesService:ImagesService,
        private groupService:GroupService,
        private serviceService:ServiceService,
        private productService:ProductService
        ) { }

    delete(id: number, type: DeleteTypeEnum, ownerEnum?:OwnerEnum) {
        switch (type) {
            case DeleteTypeEnum.event:
                return this.eventService.delete(id);
            case DeleteTypeEnum.gallery:
                return this.imagesService.delete(id, ownerEnum);
            case DeleteTypeEnum.project:
                return this.projectService.delete(id);
            case DeleteTypeEnum.group:
                return this.groupService.delete(id);
            case DeleteTypeEnum.service:
                return this.serviceService.delete(id);
            case DeleteTypeEnum.ad:
                return this.productService.delete(id);
            default:
                return;
        }
    }

}