import { Component, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { Instrument } from "src/app/core/models/instrument";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "src/app/core/components/toast/toast.service";
import { StringService } from "src/app/shared/helpers/string.service";
import { debounceTime } from "rxjs/operators";
import { MatListOption } from "@angular/material";
import { UserService } from "../user.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./user-instruments.component.html",
  styleUrls: ["./user-instruments.component.scss"],
})
export class UserInstrumentsComponent implements OnInit, OnDestroy {
  id: number;
  currentRoute: string;
  instruments: Instrument[];
  originalInstruments: Instrument[];
  selectedInstruments: Instrument[];
  onTyping = new EventEmitter<string>();
  debounce: Subject<string> = new Subject<string>();
  newUser: boolean;
  isScreenLargerThan550: boolean = false;

  constructor(
    public translate: TranslateService,
    private routeActivated: ActivatedRoute,
    private toastService: ToastService,
    private stringService: StringService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isScreenLargerThan550 = window.innerWidth > 550;

    window.addEventListener("resize", () => {
      this.isScreenLargerThan550 = window.innerWidth > 550;
    });
    this.routeActivated.params.subscribe((params) => {
      this.id = +params.id;
      this.currentRoute = this.router.url;
      this.loadInstruments();
    });

    this.debounce.pipe(debounceTime(300)).subscribe((filter) => {
      this.filterInstruments(filter);
    });

    this.routeActivated.queryParams.subscribe((params) => {
      this.newUser = params["newUser"];
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadInstruments();
    });
  }

  loadInstruments() {
    this.userService.getInstruments().subscribe((genres: Instrument[]) => {
      this.instruments = genres.sort((a, b) => {
        return a.name.localeCompare(b.name, "pt");
      });
      /*
                this.instruments = genres.sort((a, b) => {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }

                    return 0;
                });*/
      this.originalInstruments = this.instruments;
    });
  }

  saveInstruments() {
    this.userService
      .updateInstruments(
        this.originalInstruments.filter((x) => x.checked).map((x) => x.key)
      )
      .subscribe(() => {
        if (this.newUser) {
          this.getRedirect();
        } else {
          this.toastService.success("Instrumentos atualizados com sucesso.");
          this.router.navigate(["web", "home", "user", this.id.toString()]);
        }
      });
  }

  clearInstruments() {
    const tempGenres: Instrument[] = [];
    this.originalInstruments.forEach((genre) => {
      genre.checked = false;
      tempGenres.push(genre);
    });

    this.instruments = tempGenres;
    this.originalInstruments = tempGenres;
  }

  filterInstruments(search: string) {
    const tempGenres: Instrument[] = [];
    if (!search) {
      this.originalInstruments.forEach((genre) => {
        tempGenres.push(genre);
      });
      this.instruments = this.originalInstruments;
    } else {
      search = this.stringService.removeAccents(search);

      this.originalInstruments.forEach((genre) => {
        if (
          this.stringService
            .removeAccents(genre.name.toLowerCase())
            .indexOf(search.toLowerCase()) > -1
        ) {
          tempGenres.push(genre);
        }
      });

      this.instruments = tempGenres;
    }
  }

  onInstrumentsChange(genresSelected: MatListOption[]) {
    const keys = genresSelected.map((x) => x.value);

    this.originalInstruments.forEach((genre) => {
      if (keys.some((x) => x === genre.key) && !genre.checked) {
        genre.checked = true;
      }

      if (
        !keys.some((x) => x === genre.key) &&
        genre.checked &&
        this.instruments.some((x) => x.key === genre.key)
      ) {
        genre.checked = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  getRedirect() {
    if (this.newUser) {
      this.router.navigate(["web", "home", "user", this.id, "work-genres"], {
        queryParams: { newUser: true },
      });
    } else {
      this.router.navigate(["web", "home", "user", this.id]);
    }
  }

  skip() {
    this.router.navigate(["web", "home", "user", this.id, "genres-user-like"], {
      queryParams: { newUser: true },
    });
  }
}
