import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent {
    userForm: FormGroup;
    options: object;
    genresUserWork:string;
    genresUserLike:string;
    id: number;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private stringService: StringService,
        private router: Router,
        private route: ActivatedRoute) { }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();

        if (this.id) {
            this.getUser(this.id);
        }

        this.options = {
            types: ['(cities)']
        }
    }

    setFormValidations() {
        this.userForm = this.formBuilder.group({
            fullName: ['', [Validators.required]],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            definition: [''],
            url: [''],
            isMusician: [false, Validators.required],
            biography: [''],
            birthDate: ['']
        });
    }

    edit() {
        this.userService.updateUser(this.generateUser())
            .subscribe((user: User) => {
                this.router.navigate(['web', 'home', 'user', this.id]);
            });
    }

    generateUser(): User {
        let user: User = this.userForm.getRawValue();

        return user;
    }

    getUser(id: number) {
        this.userService.getUser(id).subscribe(
            (user: User) => {
                this.setValuesInForm(user);
                this.genresUserLike = user.genresUserLike && user.genresUserLike.length > 0 ? this.stringService.joinGenres(user.genresUserLike.map(x => x.name)) : "Adicione gêneros que você gosta";
                this.genresUserWork = user.genresUserWork && user.genresUserWork.length > 0 ? this.stringService.joinGenres(user.genresUserWork.map(x => x.name)) : "Adicione gêneros que você trabalha";
            }
        );
    }

    setValuesInForm(user: User) {
        this.userForm.patchValue({
            ...user,
            city: user.descriptionCity
        });
    }

    handleAddressChange(param) {
        this.userForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.userForm.get('descriptionCity').patchValue(city);
        this.userForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.userForm.get('idCity').patchValue('');
        this.userForm.get('descriptionCity').patchValue('');
    }

    openGenresUserLike(){
        return this.router.navigate(['web', 'home', 'user', this.id, 'genres']);
    }

    openGenresUserWork(){
        return this.router.navigate(['web', 'home', 'user', this.id, 'work-genres']);
    }
}