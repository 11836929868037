import { Author } from './author';

export class Comment {
    id:number;
    creationDate: Date;
    description: string;
    idGallery: number;
    typeGallery: string;

    author:Author;
}