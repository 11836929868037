import { Component, OnInit, Inject } from '@angular/core';
import { AdTypeEnum } from 'src/app/shared/enums/ad-type-enum';
import { AdsService } from '../ads.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Period } from 'src/app/core/models/period';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';

@Component({
    templateUrl: './ads-renew.component.html',
    styleUrls: ['./ads-renew.component.scss']
})
export class AdsRenewComponent implements OnInit {
    ownerId:number;
    adTypeEnum:AdTypeEnum;
    adForm: FormGroup;
    periods:Period[] = [];

    constructor(
        public translate:TranslateService,
        private adsService:AdsService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastService:ToastService,
        private dialog: MatDialog){}

    ngOnInit(){
        this.ownerId = this.data.ownerId;
        this.adTypeEnum = this.data.adTypeEnum;   
        this.initForm();
        this.getPeriods();

        this.translate.onLangChange.pipe(delay(500)).subscribe((event: LangChangeEvent) => {
            this.periods = [];
            this.getPeriods();
        })
    }

    initForm(){
        this.adForm = this.formBuilder.group({
            period:['', Validators.required]
        });
    }

    renewAd(){
        const period = this.adForm.get('period').value;
        
        this.adsService.renewAd(this.ownerId, this.adTypeEnum, period).subscribe(
            (response:any) => {
                if(this.adTypeEnum == AdTypeEnum.product){
                    this.adsService.renewProductSubject.emit('');
                }else{
                    this.adsService.renewServiceSubject.emit('');
                }                
                this.dialog.closeAll();
                window.open(response.toString(), "_self");
            }
        );
    }

    getPeriods(){
        let fifteen:Period = {key: 'Thirty', name: this.translate.instant('TrintaDias')} 
        this.periods.push(fifteen);

        let thirty:Period = {key: 'Ninety', name: this.translate.instant('NoventaDias')} 
        this.periods.push(thirty);

        let oneYear:Period = {key: 'OneYear', name: this.translate.instant('UmAno')} 
        this.periods.push(oneYear);
    }

    cancel(){
        this.dialog.closeAll();
    }
}