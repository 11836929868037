import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { GroupService } from '../../group.service';
import { MemberGroup } from 'src/app/core/models/member-group';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { TranslateService } from '@ngx-translate/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './members-list.component.html',
    styleUrls: ['./members-list.component.scss']
})
export class MembersListComponent{
    groupId: number;
    memberList: MemberGroup[] = [];
    author:boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate:TranslateService,
        private router:Router,
        private toastService: ToastService,
        private dialog: MatDialog,
        private modalService:ModalsService,
        private groupService:GroupService
        ) { }

    ngOnInit(): void {
        this.groupId = this.data.groupId;
        this.author = this.data.author;
        this.getMembers();
    }

    getMembers() {
        this.groupService.getMembers(this.groupId).subscribe(
            (response: ScopeListDto<MemberGroup>) => {
                this.memberList = response.items;
            }
        );
    }

    deleteMember(id: number) {
        this.groupService.deleteMember(id).subscribe(
            () => {                
                this.toastService.success('Integrante removido com sucesso.');
                this.getMembers();
                this.groupService.updateMemberEvent.emit('');
            }
        )
    }

    cancel() {
        this.dialog.closeAll();
    }

    openMember(member:MemberGroup){
        if(member.idUser){
            this.dialog.closeAll();
            this.router.navigate(['web', 'home', 'user', member.idUser]);
        }   
    }

    createMember(){
        this.modalService.openCreateMember(this.groupId, this.author, true);
    }
}