import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeUserBaseComponent } from './home-user/home-user-base/home-user-base.component';
import { CoreModule } from './core/core.module';
import { HomeUserComponent } from './home-user/home-user.component';
import { HowWorksComponent } from './home-page/how-works/how-works.component';
import { FaqComponent } from './home-page/faq/faq.component';
import { TermsUseComponent } from './home-page/terms-use/terms-use.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './home/signin/signin.component';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HowWorksComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'terms-use',
    component: TermsUseComponent
  },
  {
    path: 'web',
    redirectTo: 'web/signin'
  },
  {
    path: 'web',
    children:[
      {
        path: 'signin',
        component: HomeComponent,
        loadChildren: './home/home.module#HomeModule',
      },
      {
        path: 'home',
        component: HomeUserBaseComponent,
        loadChildren: './home-user/home-user.module#HomeUserModule'
      }
    ]
  } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
