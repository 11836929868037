import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NotificationTypeEnum } from "src/app/shared/enums/notification-type-enum";
import { Notification } from "src/app/core/models/notification";
import { NotificationEnum } from "src/app/shared/enums/notification-enum";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class NotificationService {
  constructor(private httpclient: HttpClient, private router: Router) {}

  getTotalNotifications() {
    return this.httpclient.get(`notification`);
  }

  getAll(pageSize: number = 100000) {
    return this.httpclient.get(
      `notification/getallbyuser?PageSize=${pageSize}&page=1`
    );
  }

  notificationRead(id: number) {
    return this.httpclient.patch(`notification/${id}/isread`, "");
  }

  isNew() {
    return this.httpclient.patch(`notification/isnew`, "");
  }

  goToReference(notification: Notification) {
    switch (<NotificationTypeEnum>notification.type) {
      case NotificationTypeEnum.user:
        this.rulesToUser(notification);
        break;
      case NotificationTypeEnum.group:
        this.rulesToGroup(notification);
        break;
      case NotificationTypeEnum.project:
        this.rulesToProject(notification);
        break;
      case NotificationTypeEnum.event:
        this.rulesToEvent(notification);
        break;
      case NotificationTypeEnum.service:
        this.rulesToService(notification);
        break;
      case NotificationTypeEnum.product:
        this.rulesToProduct(notification);
        break;
    }
  }

  private rulesToUser(notification:Notification){
    console.log(notification)
    if(notification.idGallery && notification.idGallery != 0){
        if(notification.enumNotification == NotificationEnum.userCommentedPost || notification.enumNotification == NotificationEnum.userLikedPost){
            if(notification.referenceGallery.indexOf('GalleryEvent') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1){
                    this.router.navigate(['web', 'home', 'event', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'event', notification.idReference, 'video', notification.idGallery]);
                }
            }

            if(notification.referenceGallery.indexOf('GalleryGroup') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1 ){
                    this.router.navigate(['web', 'home', 'group', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'group', notification.idReference, 'video', notification.idGallery]);
                }
            }

            if(notification.referenceGallery.indexOf('GalleryProject') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1 ){
                    this.router.navigate(['web', 'home', 'project', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'project', notification.idReference, 'video', notification.idGallery]);
                }
            }

            if(notification.referenceGallery.indexOf('GalleryService') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1 ){
                    this.router.navigate(['web', 'home', 'ads', 'service', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'ads', 'service', notification.idReference, 'video', notification.idGallery]);
                }
            }

            if(notification.referenceGallery.indexOf('GalleryUser') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1 ){
                    this.router.navigate(['web', 'home', 'user', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'user', notification.idReference, 'video', notification.idGallery]);
                }
            }

            if(notification.referenceGallery.indexOf('GalleryProduct') > -1){
                if(notification.typeNotification.indexOf('Image') > -1 || notification.referenceGallery.indexOf('Image') > -1 ){
                    this.router.navigate(['web', 'home', 'ads', 'product', notification.idReference, 'image', notification.idGallery]);
                }else{
                    this.router.navigate(['web', 'home', 'ads', 'product', notification.idReference, 'video', notification.idGallery]);
                }
            }
        }
    }if (notification.enumNotification === NotificationEnum.userSentMessage) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['web', 'home', 'message', notification.idUser]);
        });
    } else if (notification.enumNotification === NotificationEnum.userFollowed) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['web', 'home', 'user', notification.idUser]);
        });
    } else if (notification.enumNotification === NotificationEnum.userAddedVideo || notification.referenceGallery === 'Video') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['web', 'home', 'user', notification.idReference, 'video', notification.idGallery]);
        });
    } else if (notification.enumNotification === NotificationEnum.userAddedImage || notification.referenceGallery === 'Image') {
        this.router.navigate(['web', 'home', 'user', notification.idReference, 'image', notification.idGallery]);
    } else {
        this.router.navigate(['web', 'home', 'user', notification.idReference, 'video', notification.idGallery]);
    }
}

  private rulesToGroup(notification: Notification) {
    if (notification.idGallery && notification.idGallery != 0) {
      if (notification.referenceGallery == "Image") {
        this.router.navigate([
          "web",
          "home",
          "group",
          notification.idReference,
          "image",
          notification.idGallery,
        ]);
      } else {
        this.router.navigate([
          "web",
          "home",
          "group",
          notification.idReference,
          "video",
          notification.idGallery,
        ]);
      }
    } else {
      switch (notification.typeNotification) {
        case "UserAddedYouOnGroup":
          this.router.navigate(["web", "home", "group", "list"]);
          break;
        case "GroupAddedOnProject":
          this.router.navigate(["web", "home", "project", "list"]);
          break;
        case "GroupAddedOnEvent":
          this.router.navigate(["web", "home", "event", "list"]);
          break;
        default:
          this.router.navigate([
            "web",
            "home",
            "group",
            notification.idReference,
          ]);
      }
    }
  }

  private rulesToProject(notification: Notification) {
    if (notification.idGallery && notification.idGallery != 0) {
      if (
        notification.enumNotification == NotificationEnum.imageAddedOnProject ||
        notification.referenceGallery == "Image"
      ) {
        this.router.navigate([
          "web",
          "home",
          "project",
          notification.idReference,
          "image",
          notification.idGallery,
        ]);
      } else {
        this.router.navigate([
          "web",
          "home",
          "project",
          notification.idReference,
          "video",
          notification.idGallery,
        ]);
      }
    } else {
      switch (notification.typeNotification) {
        case "GroupAddedOnProject":
        case "UserAddedYouOnProject":
          this.router.navigate(["web", "home", "project", "list"]);
          break;
        default:
          this.router.navigate([
            "web",
            "home",
            "project",
            notification.idReference,
          ]);
      }
    }
  }

  private rulesToEvent(notification: Notification) {
    if (notification.idGallery && notification.idGallery != 0) {
      if (
        notification.enumNotification == NotificationEnum.imageAddedOnEvent ||
        notification.referenceGallery == "Image"
      ) {
        this.router.navigate([
          "web",
          "home",
          "event",
          notification.idReference,
          "image",
          notification.idGallery,
        ]);
      } else {
        this.router.navigate([
          "web",
          "home",
          "event",
          notification.idReference,
          "video",
          notification.idGallery,
        ]);
      }
    } else {
      switch (notification.typeNotification) {
        case "GroupAddedOnEvent":
        case "UserAddedYouOnEvent":
          this.router.navigate(["web", "home", "event", "list"]);
          break;
        default:
          this.router.navigate([
            "web",
            "home",
            "event",
            notification.idReference,
          ]);
      }
    }
  }

  private rulesToService(notification: Notification) {
    if (notification.idGallery && notification.idGallery != 0) {
      if (notification.referenceGallery == "Image") {
        this.router.navigate([
          "web",
          "home",
          "ads",
          "service",
          notification.idReference,
          "image",
          notification.idGallery,
        ]);
      } else {
        this.router.navigate([
          "web",
          "home",
          "ads",
          "service",
          notification.idReference,
          "video",
          notification.idGallery,
        ]);
      }
    } else {
      this.router.navigate([
        "web",
        "home",
        "ads",
        "service",
        notification.idReference,
      ]);
    }
  }

  private rulesToProduct(notification: Notification) {
    if (notification.idGallery && notification.idGallery != 0) {
      if (notification.referenceGallery == "Image") {
        this.router.navigate([
          "web",
          "home",
          "ads",
          "product",
          notification.idReference,
          "image",
          notification.idGallery,
        ]);
      } else {
        this.router.navigate([
          "web",
          "home",
          "ads",
          "product",
          notification.idReference,
          "video",
          notification.idGallery,
        ]);
      }
    } else {
      this.router.navigate([
        "web",
        "home",
        "ads",
        "product",
        notification.idReference,
      ]);
    }
  }
}
