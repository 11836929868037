import { Component, Inject } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { GroupService } from '../../group.service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { MemberGroup } from 'src/app/core/models/member-group';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './members-new.component.html',
    styleUrls: ['./members-new.component.scss']
})
export class MembersNewComponent {
    memberList: User[];
    groupId: number;
    memberForm: FormGroup;
    searchUpdate = new Subject<string>();
    author: boolean;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private modalsService: ModalsService,
        private groupService:GroupService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.groupId = this.data.groupId;
        this.author = this.data.author;
        this.configForm();

        this.searchUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.searchMember();
            });
    }

    configForm() {
        this.memberForm = this.formBuilder.group({
            search: ['', [Validators.required]],
            memberId: ['']
        });
    }

    resetForm() {
        this.memberList = [];
        this.memberForm.get('search').patchValue('');
        this.memberForm.get('memberId').patchValue('');
    }

    handleMember(memberId: number) {
        this.memberForm.get('memberId').patchValue(memberId);
    }


    searchMember() {
        let search: string = this.memberForm.get('search').value;
        this.memberForm.get('memberId').patchValue('');

        if (!search || search.length < 2) {
            this.memberList = [];
            return;
        }

        this.groupService.searchMember(search).subscribe(
            (response: ScopeListDto<User>) => {
                this.memberList = response.items;
            }
        );
    }

    createMember() {
        let member:MemberGroup = {
            id:0,
            urlProfilePicture: '',
            accepted: false,
            idGroup: this.groupId,
            name: this.memberForm.get('search').value,
            idUser: this.memberForm.get('memberId').value
        }

        this.groupService.createMember(member).subscribe(
            (response) => {
                this.modalsService.openMembersGroupModal(this.groupId, this.author, true);
                this.groupService.updateMemberEvent.emit('');
            }
        );
    }

    cancel() {
        this.dialog.closeAll();
    }
}