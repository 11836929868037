import { Component } from '@angular/core';
import { Group } from 'src/app/core/models/group';
import { GroupService } from '../group.service';
import { Router } from '@angular/router';
import { GroupsUserDto } from 'src/app/core/dtos/groups-user-dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent {
    groups: Group[];
    groupsRequest: Group[];

    constructor(
        public translate:TranslateService,
        private groupService:GroupService,
        private router: Router){}
    
    ngOnInit(): void {
        this.loadGroups();
    }

    loadGroups(){
        this.groupService.getGroupByUser().subscribe(
            (response: GroupsUserDto) => {
                this.groups = response.groups;
                this.groupsRequest = response.groupsRequest;
            }
        )
    }

    goDetailGroup(id:number){
        this.router.navigate(['web', 'home','group', id]);
    }

    newGroup(){
        this.router.navigate(['web', 'home','group', 'new']);
    }

    acceptOrRefuse(groupId:number, accept:boolean){
        this.groupService.acceptOrRefuseMemberGroup(groupId, accept).subscribe(
            () => {
                this.loadGroups();
            }
        );
    }
}