import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from '../http-interceptors';
import { LoaderComponent } from './components/loader/loader.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';

@NgModule({
    declarations:[
        LoaderComponent
    ],
    imports:[
        CommonModule,
        AngularMaterialModule
    ],
    exports:[
        LoaderComponent
    ],
    providers:[
        httpInterceptorProviders
    ]
})
export class CoreModule {

}