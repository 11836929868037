import { Component, OnInit, ViewChild} from '@angular/core';
import { SidenavService } from './shared/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './core/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(
    private languageService:LanguageService,
    public translate:TranslateService){}
  
  ngOnInit(): void {
    this.translate.setDefaultLang('pt');
    let language = this.languageService.getlanguage();
    if(!language){
      language = 'pt';
      this.languageService.setLanguage(language);      
    }

    this.translate.use(language);
  }
}
