import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Comment } from 'src/app/core/models/comment';

@Component({
  templateUrl: './edit-comment-modal.component.html',
  styleUrls: ['./edit-comment-modal.component.scss']  
})
export class EditCommentModalComponent implements OnInit {
  commentForm: FormGroup;
  id: number;
  oldDescription: string;

    constructor(private formBuilder: FormBuilder,
                private sharedService: SharedService,
                private dialog:MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any){}

    ngOnInit(): void {
      this.id = this.data.id;
      this.oldDescription = this.data.oldDescription;
      this.configForm();
    }

    configForm() {
      this.commentForm = this.formBuilder.group({
          description: ['', [Validators.required]],
      });

      this.commentForm.get('description').patchValue(this.oldDescription);
    }

    editComment(){
      let comment = new Comment();
      comment.id = this.id;
      comment.description = this.commentForm.get('description').value;

      this.sharedService.editComment(this.id, comment.description).subscribe(() => {
        this.sharedService.updateCommentEvent.emit(comment);
        this.dialog.closeAll();
      });
    }

    cancel() {
      this.dialog.closeAll();
  }
}