import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GenreService } from "./genre.service";
import { Genre } from "../core/models/genre";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ToastService } from "../core/components/toast/toast.service";
import { MatListOption } from "@angular/material/list/typings/selection-list";
import { UserPreferencesService } from "../core/user-preferences/user-preferences.service";
import { StringService } from "../shared/helpers/string.service";
import { UserStorageService } from "../core/user/user-storage.service";
import { NgFormSelectorWarning } from "@angular/forms";
import { MusicalAffinitiesService } from "../musical-affinities/musical-affinities.service";
import { ScopeListDto } from "../core/dtos/scope-list-dto";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./genre.component.html",
  styleUrls: ["./genre.component.scss"],
})
export class GenreComponent implements OnInit, OnDestroy {
  id: number;
  currentRoute: string;
  genres: Genre[];
  originalGenres: Genre[];
  selectedGenres: Genre[];
  onTyping = new EventEmitter<string>();
  debounce: Subject<string> = new Subject<string>();
  newUser: boolean;
  isScreenLargerThan550: boolean = false;

  constructor(
    public translate: TranslateService,
    private routeActivated: ActivatedRoute,
    private toastService: ToastService,
    private genreService: GenreService,
    private userStorageService: UserStorageService,
    private stringService: StringService,
    private musicalAffinitiesService: MusicalAffinitiesService,
    private userPreferencesService: UserPreferencesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isScreenLargerThan550 = window.innerWidth > 550;

    window.addEventListener("resize", () => {
      this.isScreenLargerThan550 = window.innerWidth > 550;
    });

    this.routeActivated.params.subscribe((params) => {
      this.id = +params.id;
      this.currentRoute = this.router.url;
      this.loadGenres();
    });

    this.routeActivated.queryParams.subscribe((params) => {
      this.newUser = params["newUser"];
    });

    this.debounce.pipe(debounceTime(300)).subscribe((filter) => {
      this.filterGenres(filter);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadGenres();
    });
  }

  loadGenres() {
    this.genreService
      .getGenres(this.currentRoute, this.id)
      .subscribe((response: any) => {
        let genres: Genre[] = [];
        if (response.items) {
          genres = response.items;
        } else {
          genres = response;
        }

        if (this.currentRoute.indexOf("musical-affinities") > 0) {
          let genresActive = this.musicalAffinitiesService.getFilters().genres;
          if (genresActive && genresActive.length > 0) {
            genres.forEach((x) => {
              if (genresActive.map((x) => x.key).includes(x.key)) {
                x.checked = true;
              }
            });
          }
        }
        this.genres = genres.sort((a, b) => {
          return a.name.localeCompare(b.name, "pt");
        });
        /*
        this.genres = genres.sort((a, b) => {
          if (a.name < b.name) {
              return -1;
          }
          if (a.name > b.name) {
              return 1;
          }

          return 0;
        });*/
        this.originalGenres = this.genres;
      });
  }

  saveGenres() {
    if (!this.originalGenres || !this.originalGenres.some((x) => x.checked)) {
      this.toastService.error(
        "Por favor, escolha no mínimo um gênero musical."
      );
      return;
    }

    if (this.currentRoute.indexOf("musical-affinities") > 0) {
      this.musicalAffinitiesService.setGenresFilter(
        this.originalGenres.filter((x) => x.checked)
      );
      this.router.navigate(this.getRoute());
    } else {
      this.genreService
        .saveGenres(
          this.originalGenres.filter((x) => x.checked),
          this.currentRoute,
          this.id
        )
        .subscribe(() => {
          if (this.currentRoute.indexOf("genres-user-like") > 0) {
            this.afterUserLikeSuccess();
          } else if (
            this.currentRoute.indexOf("genres-user-like") <= 0 &&
            this.currentRoute.indexOf("work-genres") <= 0
          ) {
            this.toastService.success("Gêneros atualizados com sucesso.");
          }

          if (this.newUser && this.currentRoute.indexOf("work-genres") > 0) {
            this.router.navigate(
              ["web", "home", "user", this.id, "genres-user-like"],
              { queryParams: { newUser: true } }
            );
          } else {
            this.router.navigate(this.getRoute());
          }
        });
    }
  }

  clearGenres() {
    const tempGenres: Genre[] = [];
    this.originalGenres.forEach((genre) => {
      genre.checked = false;
      tempGenres.push(genre);
    });

    this.genres = tempGenres;
    this.originalGenres = tempGenres;
  }

  filterGenres(search: string) {
    const tempGenres: Genre[] = [];
    if (!search) {
      this.originalGenres.forEach((genre) => {
        tempGenres.push(genre);
      });
      this.genres = this.originalGenres;
    } else {
      search = this.stringService.removeAccents(search);

      this.originalGenres.forEach((genre) => {
        if (
          this.stringService
            .removeAccents(genre.name.toLowerCase())
            .indexOf(search.toLowerCase()) > -1
        ) {
          tempGenres.push(genre);
        }
      });

      this.genres = tempGenres;
    }
  }

  getRoute(): string[] {
    if (this.currentRoute.indexOf("event") > 0) {
      return ["web", "home", "event", this.id.toString()];
    } else if (this.currentRoute.indexOf("project") > 0) {
      return ["web", "home", "project", this.id.toString()];
    } else if (this.currentRoute.indexOf("group") > 0) {
      return ["web", "home", "group", this.id.toString()];
    } else if (this.currentRoute.indexOf("user-like") > 0) {
      return ["web", "home"];
    } else if (
      this.currentRoute.indexOf("user") > 0 ||
      this.currentRoute.indexOf("work-genres") > 0
    ) {
      return ["web", "home", "user", this.id.toString()];
    } else if (this.currentRoute.indexOf("musical-affinities") > 0) {
      return ["web", "home", "musical-affinities"];
    } else {
    }
  }

  onGenresChange(genresSelected: MatListOption[]) {
    const keys = genresSelected.map((x) => x.value);

    this.originalGenres.forEach((genre) => {
      if (keys.some((x) => x === genre.key) && !genre.checked) {
        genre.checked = true;
      }

      if (
        !keys.some((x) => x === genre.key) &&
        genre.checked &&
        this.genres.some((x) => x.key === genre.key)
      ) {
        genre.checked = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  afterUserLikeSuccess() {
    const genres = this.originalGenres.filter((x) => x.checked);

    const user = this.userStorageService.getUser();
    user.genresUserLike = genres;

    this.userPreferencesService.setGenres(
      genres.map((x) => x.name),
      genres.map((x) => x.key)
    );

    this.userStorageService.removeUser();
    this.userStorageService.setUser(user);
  }
}
