import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserConfigurationsService } from '../user-configurations.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/components/toast/toast.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './config-change-email.component.html',
    styleUrls: ['./config-change-email.component.scss']
})
export class ConfigChangeEmailComponent implements OnInit {
    configForm: FormGroup;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private userStorageService:UserStorageService,
        private toastService: ToastService,
        private userConfigurationsService: UserConfigurationsService) { }

    ngOnInit(): void {
        this.setFormValidations();
    }

    changeEmail() {
        let email = this.configForm.get('email').value;

        this.userConfigurationsService.changeEmail(email).subscribe(
            () => {
                this.updateUserEmail(email);
                this.toastService.success('E-mail alterado com sucesso.');
                this.router.navigate(['web', 'home', 'settings']);
            }
        )
    }

    updateUserEmail(email:string){
        let user = this.userStorageService.getUser();
        user.email = email;
        this.userStorageService.setUser(user);
    }

    setFormValidations() {
        this.configForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        })
    }
}