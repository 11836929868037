import { Component, OnInit, OnDestroy } from "@angular/core";
import { SidenavService } from "../sidenav.service";
import {
  Router,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
} from "@angular/router";
import { UserStorageService } from "src/app/core/user/user-storage.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { SharedService } from "../shared.service";
import { SearchUserGroupDto } from "src/app/core/dtos/search-user-group-dto";
import { Group } from "src/app/core/models/group";
import { User } from "src/app/core/models/user";
import { NotificationService } from "src/app/notification/notification-list/notification.service";
import { ScopeListDto } from "src/app/core/dtos/scope-list-dto";
import { Notification } from "src/app/core/models/notification";
import { ResponseTotalNotificaitonsDto } from "src/app/core/dtos/response-total-notifications-dto";
import { LanguageMenuDto } from "src/app/core/dtos/language-menu-dto";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/language/language.service";
import { TokenService } from "src/app/core/token/token.service";
import { UserService } from "src/app/user/user.service";

@Component({
  selector: "mscope-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnDestroy {
  id: number;
  photoUrl: string;
  fullName: string;
  searchForm: FormGroup;
  searchUpdate = new Subject<string>();
  musicians: User[];
  users: User[];
  groups: Group[];
  notifications: Notification[];
  totalNotifications: number;
  totalMessages: number;
  updateNotificationsSubscription: Subscription;
  updateUrlProfilePictureSubscription: Subscription;
  languages: LanguageMenuDto[] = [];
  languageSelected: LanguageMenuDto;
  isScreenLargerThan970: boolean = false;
  isScreenSmallerThan710: boolean = false;
  isScreenLargerThan710: boolean = false;

  constructor(
    public translate: TranslateService,
    private tokenService: TokenService,
    private languageService: LanguageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private notificationService: NotificationService,
    private sidenavService: SidenavService,
    private userStorageService: UserStorageService
  ) {}
  ngOnDestroy(): void {
    if (this.updateUrlProfilePictureSubscription) {
      this.updateUrlProfilePictureSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.isScreenLargerThan970 = window.innerWidth > 970;
    this.isScreenSmallerThan710 = window.innerWidth > 710;
    this.isScreenLargerThan710 = window.innerWidth > 710;

    window.addEventListener("resize", () => {
      this.isScreenLargerThan970 = window.innerWidth > 970;
    });

    window.addEventListener("resize", () => {
      this.isScreenSmallerThan710 = window.innerWidth > 710;
    });
    window.addEventListener("resize", () => {
      this.isScreenLargerThan710 = window.innerWidth > 710;
    });

    const user = this.userStorageService.getUser();

    this.photoUrl = user.urlProfilePicture;
    this.fullName = user.fullName.split(" ")[0];
    this.id = user.id;

    this.searchForm = this.formBuilder.group({
      search: [""],
    });

    this.updateNotifications();
    this.fillLanguages();
    this.subscribeChangeUrlProfilePicture();

    this.searchUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.search();
      });
  }

  openSideBar() {
    this.sidenavService.open();
  }

  goToHomeEvents() {
    this.router.navigate(["web", "home"]);
  }

  search() {
    let search: string = this.searchForm.get("search").value;

    if (!search || search.length < 2) {
      this.musicians = [];
      this.users = [];
      this.groups = [];
      return;
    }

    this.sharedService
      .searchUserOrGroups(search)
      .subscribe((response: SearchUserGroupDto) => {
        this.musicians = response.users.filter((x) => x.isMusician);
        this.users = response.users.filter((x) => !x.isMusician);
        this.groups = response.groups;
      });
  }

  openUser(id: number) {
    this.router.navigate(["web", "home", "user", id]);
  }

  openGroup(id: number) {
    this.router.navigate(["web", "home", "group", id]);
  }

  loadNotifications() {
    this.notificationService
      .getAll(4)
      .subscribe((response: ScopeListDto<Notification>) => {
        this.notifications = response.items;
        this.notificationService
          .isNew()
          .subscribe(() => (this.totalNotifications = 0));
      });
  }

  openNotification(notification: Notification) {
    this.notificationService.goToReference(notification);
    let index = this.notifications.findIndex((x) => x.id == notification.id);
    this.notifications[index].isRead = true;
    this.notificationService.notificationRead(notification.id).subscribe();
  }

  showAllNotifications() {
    this.router.navigate(["web", "home", "notifications"]);
  }

  openMessages() {
    this.router.navigate(["web", "home", "message"]);
  }

  yourAds() {
    this.router.navigate(["web", "home", "ads", "list"]);
  }

  musicalAffinities() {
    this.router.navigate(["web", "home", "musical-affinities"]);
  }

  updateNotifications() {
    return this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (!this.tokenService.hasToken()) return;

        this.notificationService
          .getTotalNotifications()
          .subscribe((response: ResponseTotalNotificaitonsDto) => {
            this.totalNotifications = response.numberNotifications;
            this.totalMessages = response.numberMessages;
          });
      });
  }

  fillLanguages() {
    this.languages.push(new LanguageMenuDto("pt", "brasil.png", "Portuguese"));
    this.languages.push(new LanguageMenuDto("en", "eua.png", "English"));
    this.languages.push(new LanguageMenuDto("es", "espanha.png", "Español"));
    this.languages.push(new LanguageMenuDto("fr", "franca.png", "French"));
    this.languages.push(new LanguageMenuDto("it", "italia.png", "Italian"));
    this.languages.push(new LanguageMenuDto("de", "alemanha.png", "Deutsch"));

    this.languageSelected = this.languages.find(
      (x) => x.abbreviation == this.languageService.getlanguage()
    );
  }

  changeLanguage(language: LanguageMenuDto) {
    this.languageSelected = language;
    this.translate.use(language.abbreviation);
    this.languageService.setLanguage(language.abbreviation);
    this.languageService.updateUserLanguage(language.abbreviation).subscribe();
  }

  subscribeChangeUrlProfilePicture() {
    this.updateUrlProfilePictureSubscription =
      UserService.urlProfilePictureUpdatedEvent.subscribe((url: string) => {
        let user = this.userStorageService.getUser();
        user.urlProfilePicture = this.photoUrl = url;

        this.userStorageService.setUser(user);
      });
  }
}
