import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CategoryProduct } from 'src/app/core/models/categoryProduct';
import { Period } from 'src/app/core/models/period';
import { Product } from 'src/app/core/models/product';
import { ProductService } from '../product.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';

@Component({
    templateUrl: './product-new-edit.component.html',
    styleUrls: ['./product-new-edit.component.scss']
})
export class ProductNewEditComponent {
    productForm: FormGroup;
    options: object;
    id: number;
    categories:CategoryProduct[];
    periods:Period[] = [];
    selectedPeriod: string;
    product:Product;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private stringService:StringService, 
        private router: Router,
        private route: ActivatedRoute) { }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();
        this.getProductCategories();
        this.getPeriods();        
        if (this.id) {
            this.getProduct(this.id);
        }

        this.options = {
            types: ['(cities)']
        }

        this.translate.onLangChange.pipe(delay(500)).subscribe((event: LangChangeEvent) => {
            this.getProductCategories();
            this.periods = [];
            this.getPeriods();
        })
    }

    setFormValidations() {
        this.productForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            categoryProduct: ['', Validators.required],
            period: ['', Validators.required],
            cost: ['0'],
            description: ['']
        });
    }

    saveOrEdit() {
        if (this.id) {
            this.productService.editProduct(this.id, this.generateProduct())
                .subscribe((response:any) => {
                    if(!response.data && response.toString().indexOf('http') > -1){
                        window.open(response.toString(), "_self");
                    }
                });
        } else {
            this.productService.createProduct(
                this.generateProduct()).subscribe(
                    (product: Product) => {
                        window.open(product.paymentUrl, "_self");
                    }
                )
        }
    }

    generateProduct(): Product {
        let product: Product = this.productForm.getRawValue();
        product.period = <Period>{key: this.productForm.get('period').value};
        product.categoryProduct = <CategoryProduct>{key:  this.productForm.get('categoryProduct').value};
        product.appStorePrice = "-1";
        return product;
    }

    getProduct(id: number) {
        this.productService.getProductDetail(id).subscribe(
            (product: Product) => {
                this.setValuesInForm(product);
                this.product = product;
            }
        );
    }

    setValuesInForm(product: Product) {
        this.productForm.patchValue({
            ...product,
            city: product.descriptionCity,
            period: 'KeepPeriod',
            categoryProduct: product.categoryProduct.key             
        });
    }

    handleAddressChange(param) {
        this.productForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.productForm.get('descriptionCity').patchValue(city);
        this.productForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.productForm.get('idCity').patchValue('');
        this.productForm.get('descriptionCity').patchValue('');
    }

    getProductCategories(){
        this.productService.getProductCategories().subscribe(
            (response:ScopeListDto<CategoryProduct>)=> {
                this.categories = response.items;
        });
    }

    getPeriods(){
        if(this.id){
            let KeepPeriod:Period = {key: 'KeepPeriod', name: 'Manter período'} 
            this.periods.push(KeepPeriod);
        }
        
        let fifteen:Period = {key: 'Thirty', name: this.translate.instant('TrintaDias')} 
        this.periods.push(fifteen);

        let thirty:Period = {key: 'Ninety', name: this.translate.instant('NoventaDias')} 
        this.periods.push(thirty);

        let oneYear:Period = {key: 'OneYear', name: this.translate.instant('UmAno')} 
        this.periods.push(oneYear);
    }
}