import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserPreferences } from "src/app/core/user-preferences/user-preferences";
import { UserPreferencesService } from "src/app/core/user-preferences/user-preferences.service";
import { UserStorageService } from "src/app/core/user/user-storage.service";
import { HomeUserFiltersModalService } from "./home-user-filters-modal.service";

@Component({
    templateUrl: './home-user-filters-modal.component.html',
    styleUrls: ['./home-user-filters-modal.component.scss']
})
export class HomeUserFiltersModalComponent implements OnInit {
    userPreferences: UserPreferences;
    filtersForm: FormGroup;
    
    constructor(
        public translate: TranslateService,
        private dialog: MatDialog,
        private router:Router,
        private formBuilder: FormBuilder,
        private homeUserFiltersModalService: HomeUserFiltersModalService,
        private userStorageService:UserStorageService,
        private userPreferencesService:UserPreferencesService) { }

    ngOnInit(): void {
        this.setFormValidations();
    }

    setFormValidations() {
        this.userPreferences = this.userPreferencesService.getUserPreferences();

        this.filtersForm = this.formBuilder.group({
            genres: [this.userPreferences.filterByGenresLike],
            live: [this.userPreferences.filterIncludeLives]
        });
    }

    changeGenresUserLike(){
        this.router.navigate(['web', 'home', 'user', this.userStorageService.getUser().id , 'genres-user-like']);
        this.dialog.closeAll();
    }

    save(){
        const genresFilter = this.filtersForm.get('genres').value;
        const liveFilter = this.filtersForm.get('live').value;

        this.userPreferencesService.setFilterByGenresLike(genresFilter);
        this.userPreferences.filterByGenresLike = genresFilter;

        this.userPreferencesService.setFilterIncludeLives(liveFilter);
        this.userPreferences.filterIncludeLives = liveFilter;

        this.homeUserFiltersModalService.changeFilters();
    }
}