import { NgModule } from '@angular/core';
import { GenreComponent } from './genre.component';
import { CommonModule } from '@angular/common';
import {MatListModule} from '@angular/material/list';
import { MatInputModule, MatButtonModule, MatIconModule } from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessModule } from '../business/business.module';
import { UserModule } from '../user/user.module';

@NgModule({
    declarations:[
        GenreComponent
    ],
    imports:[
        CommonModule,
        MatListModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        TranslateModule,
        UserModule,
        BusinessModule
    ]
})
export class GenreModule {

}