import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthHomeUserGuard implements CanActivate {
    constructor(private tokenService: TokenService,
                private router: Router){}
    
    canActivate(activateRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean>{
        if(!this.tokenService.hasToken()){
            this.router.navigate(['web', 'signin']);
            return false;
        }
        
        return true;
    }
}