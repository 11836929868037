import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScopeListDto } from '../core/dtos/scope-list-dto';
import { Gallery } from '../core/models/gallery';
import { UserStorageService } from '../core/user/user-storage.service';
import { OwnerEnum } from '../shared/enums/owner-enum';
import { TypeMediaEnum } from '../shared/enums/type-media-enum';
import { UploadImageTypeEnum } from '../shared/enums/upload-image-type.enum';
import { ModalsService } from '../shared/modals/modals.service';
import { UserGalleryService } from './user-gallery.service';

@Component({
    templateUrl: './user-gallery.component.html',
    styleUrls: ['./user-gallery.component.scss']
})
export class UserGalleryComponent implements OnInit {
    images:Gallery[];
    videos:Gallery[];
    userId:number;

    constructor(
        public translate:TranslateService,
        private userStorageService:UserStorageService,
        private router:Router,
        private modalsService:ModalsService,
        private userGalleryService:UserGalleryService){}
    
    ngOnInit(): void {
        this.loadGalleries();
    }

    loadGalleries(){
        let user = this.userStorageService.getUser();
        this.userId = user.id;
        this.userGalleryService.getUserGallery(user.id).subscribe(
            (response:ScopeListDto<Gallery>) => {
                this.images = response.items.filter(x => x.enumTypeMedia == TypeMediaEnum.image);
                this.videos = response.items.filter(x => x.enumTypeMedia == TypeMediaEnum.video);
            }
        )
    }

    openGallery(gallery:Gallery){
        if(gallery.enumTypeMedia == TypeMediaEnum.image){
            this.router.navigate(['web', 'home', 'user', this.userId, 'image', gallery.id]);
        }
        else{
            this.router.navigate(['web', 'home', 'user', this.userId, 'video', gallery.id]);
        }
    }

    uploadNewImage() {
        this.modalsService.openUploadImageModal(
            'Nova imagem',
            1 / 1,
            UploadImageTypeEnum.media,
            ['web','home', 'user', this.userId.toString(), 'image', 'new'],
            this.userId,
            OwnerEnum.User,
            false
        );
    }

    uploadNewvideo() {
        this.router.navigate(['web', 'home', 'user', this.userId, 'video', 'new']);
    }
}