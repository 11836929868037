import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    templateUrl: './delete-comment-modal.component.html',
    styleUrls: ['./delete-comment-modal.component.scss']
})
export class DeleteCommentModalComponent implements OnInit {
    id:number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private sharedService: SharedService) { }

    ngOnInit(): void {
        this.id = this.data.id;
    }

    cancel(){
        this.dialog.closeAll();
    }

    delete() {
        this.sharedService.deleteComment(this.id).subscribe((obj) => {
            this.sharedService.deleteCommentEvent.emit(this.id);
            this.dialog.closeAll();
        });
    }
}