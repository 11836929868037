import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Project } from '../core/models/project';


@Injectable({providedIn: 'root'})
export class ProjectService {

    constructor(private http: HttpClient){}

    getProjectDetail(id:number){
        return this.http.get(`project/${id}`);
    }

    getProjectsByUser(){
        return this.http.get('project');
    }

    createProject(project:Project){
        return this.http.post('project', project);
    }

    editProject(id:number, project:Project){
        return this.http.put(`project/${id}`, project);
    }

    delete(id:number){
        console.log('deletando');
        return this.http.delete(`project/${id}`);
    }

    updateCoverImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'project-image-cover');

        return this.http.post(`project/updatepicture/${id}/cover`, formData);
    }

    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'project-image-profile');

        return this.http.post(`project/updatepicture/${id}/profile`, formData);
    }

    toggleFollowProject(id:number){
        let queryParams = new HttpParams().set('projectId', id.toString());
        return this.http.patch('follow/togglefollowproject', '', {params: queryParams});
    }

    acceptOrRefuseParticipation(eventId:number, accept:boolean, isGroup:boolean, groupId?:number){
        if(isGroup){
            return this.http.patch(`project/groupproject/${eventId}/accept/${accept}?groupid=${groupId}`, null);
        }
        
        return this.http.patch(`project/userproject/${eventId}/accept/${accept}`, null);
    }   
}