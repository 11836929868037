import { NgModule } from '@angular/core';
import { MusicalAffinitiesComponent } from './musical-affinities.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { BusinessModule } from '../business/business.module';

@NgModule({
    declarations:[
        MusicalAffinitiesComponent
    ],

    imports:[
        CommonModule,
        MatTabsModule,
        MatIconModule,
        TranslateModule,
        UserModule,
        BusinessModule
    ]
})
export class MusicalAffinitiesModule {

}