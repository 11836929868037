import { NgModule } from '@angular/core';
import { FilterSearchMusiciansComponent } from './filter-search-musicians/filter-search-musicians.component';
import { ResultSearchMusiciansComponent } from './result-search-musicians/result-search-musicians.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule, MatFormFieldModule, MatListModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        FilterSearchMusiciansComponent,
        ResultSearchMusiciansComponent
    ],
    imports:[
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatListModule,
        TranslateModule
    ]
    
})
export class SearchMusiciansModule {
    
}