import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { EventModel } from 'src/app/core/models/event';
import { DateEventDto } from 'src/app/core/dtos/dateEventDto';
import { DateService } from 'src/app/shared/helpers/date.service';

@Injectable({providedIn: 'root'})
export class HomeUserEventsService {
    constructor(private http:HttpClient,
                private dateService: DateService){}
    
    getEventsList(idCity: string, genres: string [], includeLives: boolean){
        let params = new HttpParams()
                .append("page", "1")
                .append("pageSize", "10000")
                .append("includeliveevents", String(includeLives)) 
                .append("idCity", idCity);                

        if(genres){
            genres.forEach(x => params = params.append("genres", x.toString()));
        }
                
        return this.http.get("event", {params});
    }

    public generateEventList(eventResponse: ScopeListDto<EventModel>): DateEventDto[]  {
        let dateEventList:DateEventDto[] = [];
        let allDateList:Date[] = [];
        
        if (eventResponse) {
            eventResponse.items.forEach(even => {
                let dates = this.dateService.getRangeDate(even.eventDate, even.endDate);
                dates = dates.filter(d => d >= this.dateService.removeTime(new Date()));
                dates.forEach(date => {
                    if (!allDateList.find(x => x.getTime() == date.getTime())) {
                        allDateList.push(date);
                    }
                })
            });

            allDateList = allDateList.sort((d1, d2) => {
                return d1 > d2 ? 1 : -1;
            });

            allDateList.forEach(date => {            
                const obj:DateEventDto = {
                    date: date,
                    eventList: eventResponse.items.filter(x => this.dateService.getRangeDate(x.eventDate, x.endDate).map(x=> x.getTime()).includes(date.getTime()))
                };

                dateEventList.push(<DateEventDto>obj);
            })

            return dateEventList;
        }
    }
}