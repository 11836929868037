import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/core/models/gallery';
import { OwnerEnum } from 'src/app/shared/enums/owner-enum';
import { Router, ActivatedRoute } from '@angular/router';
import { ImagesService } from '../images.service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { UploadImageTypeEnum } from 'src/app/shared/enums/upload-image-type.enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    templateUrl: './image-list.component.html',
    styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
    imageList: Gallery[] = [];
    ownerId: number;
    ownerEnum: OwnerEnum;
    isAuthor: boolean;
    comeBackTitle:string;

    constructor(
        public translate:TranslateService,
        private route: ActivatedRoute,
        private sharedService:SharedService,
        private router: Router,
        private modalsService: ModalsService,
        private imagesService: ImagesService) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.ownerId = +params['id'];
            this.ownerEnum = this.getOwnerEnum();
            this.getImages();
            this.getComeBackTitle();
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getComeBackTitle();
        })
    }

    getImages() {
        this.imagesService.getAll(this.ownerId, this.ownerEnum).subscribe(
            (response: ScopeListDto<Gallery>) => {
                this.imageList = response.items;
                this.isAuthor = response.isAuthor;
            }
        );
    }

    uploadNewImage() {
        let url = this.getBaseRedirectionUrl();
        url.push('new');

        this.modalsService.openUploadImageModal(
            'Nova imagem',
            1 / 1,
            UploadImageTypeEnum.media,
            url,
            this.ownerId,
            this.ownerEnum,
            false
        );
    }

    openImage(id: number) {
        let url = this.getBaseRedirectionUrl();
        url.push(id.toString());
        
        this.router.navigate(url);
    }

    private getOwnerEnum() {
        let url = this.router.url;
        if (url.indexOf('event') > -1) {
            return OwnerEnum.Event;
        } else if (url.indexOf('project') > -1) {
            return OwnerEnum.Project;
        } else if (url.indexOf('group') > -1) {
            return OwnerEnum.Group;
        } else if (url.indexOf('product') > -1) {
            return OwnerEnum.Product;
        } else if (url.indexOf('service') > -1) {
            return OwnerEnum.Service;
        } else {
            return OwnerEnum.User;
        }
    }

    getBaseRedirectionUrl(): string[] {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                return ['web', 'home', 'event', this.ownerId.toString(), 'image'];
            case OwnerEnum.Project:
                return ['web', 'home', 'project', this.ownerId.toString(), 'image'];
            case OwnerEnum.Group:
                return ['web', 'home', 'group', this.ownerId.toString(), 'image'];
            case OwnerEnum.Service:
                return ['web', 'home', 'ads', 'service', this.ownerId.toString(), 'image'];
            case OwnerEnum.Product:
                return ['web', 'home', 'ads', 'product', this.ownerId.toString(), 'image'];
            case OwnerEnum.User:
                return ['web', 'home', 'user', this.ownerId.toString(), 'image'];
        }
    }

    getComeBackTitle() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.translate.get("VoltarEvento").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Project:
                this.translate.get("VoltarProjeto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Group:
                this.translate.get("VoltarGrupo").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Service:
                this.translate.get("VoltarServico").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.Product:
                this.translate.get("VoltarProduto").subscribe(x => this.comeBackTitle =  x);
            case OwnerEnum.User:
                this.translate.get("VoltarUsuario").subscribe(x => this.comeBackTitle =  x);
        }
    }

    comeBack() {
        switch (this.ownerEnum) {
            case OwnerEnum.Event:
                this.router.navigate(['web', 'home', 'event', this.ownerId]);
                break;
            case OwnerEnum.Project:
                this.router.navigate(['web', 'home', 'project', this.ownerId]);
                break;
            case OwnerEnum.Group:
                this.router.navigate(['web', 'home', 'group', this.ownerId]);
                break;
            case OwnerEnum.Service:
                this.router.navigate(['web', 'home', 'ads', 'service', this.ownerId]);
                break;
            case OwnerEnum.Product:
                this.router.navigate(['web', 'home', 'ads', 'product', this.ownerId]);
                break;
            case OwnerEnum.User:
                this.router.navigate(['web', 'home', 'user', this.ownerId]);
                break;
        }
    }

    buyPhotos(){
        this.sharedService.buyphotos().subscribe(
            (response) => {
                if(response){
                    window.open(response.toString(), "_self");
                }
            } 
        )
    }
}