import { Component, OnInit } from '@angular/core';
import { EventService } from '../event.service';
import { EventModel } from 'src/app/core/models/event';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './event-stored-list.component.html',
    styleUrls: ['./event-stored-list.component.scss']
})
export class EventStoredListComponent implements OnInit {
    events:EventModel[];

    constructor(
        public translate:TranslateService,
        private eventService:EventService,
        private router:Router){}

    ngOnInit(): void {
        this.getEvents();
    }

    getEvents(){
        this.eventService.getStoredEvents().subscribe(
            (response:ScopeListDto<EventModel>) => {
                this.events = response.items;
            }
        )
    }

    openEvent(id:number){
        this.router.navigate(['web', 'home', 'event', id]);
    }
}