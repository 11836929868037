import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { AdTypeEnum } from '../shared/enums/ad-type-enum';

@Injectable({providedIn: 'root'})
export class AdsService {

    renewProductSubject = new EventEmitter(null);
    renewServiceSubject = new EventEmitter(null);
    
    constructor(private http: HttpClient) { }

    getAdsFromUser(){
        const serviceRequest = this.http.get(`service/getallbyuser`);
        const productRequest = this.http.get(`product/getallbyuser`);

        return forkJoin([serviceRequest, productRequest]);
    }

    renewAd(ownerId:number, adTypeEnum:AdTypeEnum, period:string){
        if(adTypeEnum == AdTypeEnum.product){
            return this.http.patch(`product/renewperiod/${ownerId}/${period}?appstore=-1`, null);
        }

        return this.http.patch(`service/renewperiod/${ownerId}/${period}?appstore=-1`, null);
    }
}