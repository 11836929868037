import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Notification } from "src/app/core/models/notification";
import { NotificationService } from "./notification.service";
import { ScopeListDto } from "src/app/core/dtos/scope-list-dto";
import { NotificationTypeEnum } from "src/app/shared/enums/notification-type-enum";
import { NotificationEnum } from "src/app/shared/enums/notification-enum";
import { UserStorageService } from "src/app/core/user/user-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.scss"],
})
export class NotificationListComponent implements OnInit {
  notifications: Notification[] = [];
  idUser: number;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private userStorageService: UserStorageService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.idUser = this.userStorageService.getUser().id;
    console.log(this.idUser);
  }

  getNotifications() {
    this.notificationService
      .getAll()
      .subscribe((response: ScopeListDto<Notification>) => {
        this.notifications = response.items;
        console.log(this.notifications);
      });
  }

  openNotification(notification: Notification) {
    this.notificationService.goToReference(notification);
    let index = this.notifications.findIndex((x) => x.id == notification.id);
    this.notifications[index].isRead = true;
    this.notificationService.notificationRead(notification.id).subscribe();
  }
}
