import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'src/app/core/models/message';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { MessageService } from '../message.service';

@Component({
    templateUrl: './message-list.component.html',
    styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
    messages:Message[];
    ownerId:number; 
    
    constructor(
        public translate:TranslateService,
        private messageService:MessageService,
        private userStorageService:UserStorageService,
        private router:Router){}

    ngOnInit(): void {
        this.ownerId = this.userStorageService.getUser().id;
        this.loadMessages();
    }

    loadMessages(){
        this.messageService.getAllMessages().subscribe(
            (response:Message[]) => {
                this.messages = response;
            }
        )
    }

    openMessageDetails(message:Message){
       const id = this.userStorageService.getUser().id == message.idUser ? message.idUserReceiver : message.idUser;

        this.router.navigate(['web', 'home', 'message', id]);
        let index = this.messages.findIndex(x => x.id == message.id);
        this.messages[index].isRead = true;
        this.messageService.messageRead(id).subscribe();
    }

    isRead(message:Message){
        return message.idUser == this.ownerId || (message.idUser != this.ownerId && message.isRead);
    }
}