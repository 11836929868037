import { Injectable } from '@angular/core';
import { AuthService, FacebookLoginProvider } from "angularx-social-login";

@Injectable({
    providedIn: 'root'
})
export class FacebookAuthService{
    constructor(private authService: AuthService){}

    signIn(): any{
        return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
            (response) => {
                return response;
            }
        );
    }

    signOut(){
        this.authService.signOut();
    }
}