import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { TokenService } from '../token/token.service';

export class TokenInterceptor implements HttpInterceptor{
    
    constructor(private tokenService: TokenService){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this.tokenService.hasToken() && req.url.indexOf('google') == -1){
            const token = this.tokenService.getToken();
            req = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}` 
                }
            })
        }    
        
        return next.handle(req);
    }
}
