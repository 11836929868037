import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../business.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/core/models/product';
import { Service } from 'src/app/core/models/service';
import { CategoryProduct } from 'src/app/core/models/categoryProduct';
import { CategoryService } from 'src/app/core/models/categoryService';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { ScopeListDto } from 'src/app/core/dtos/scope-list-dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { LocationModalService } from 'src/app/shared/modals/components/location-modal/location-modal.service';
import { GooglePlaceDto } from 'src/app/core/dtos/google-place-dto';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './business-page.component.html',
    styleUrls: ['./business-page.component.scss']
})
export class BusinessPageComponent implements OnInit {
    products: Product[] = [];
    services: Service[] = [];
    productCategories: CategoryProduct[] = [];
    serviceCategories: CategoryService[] = [];
    productForm: FormGroup;
    serviceForm: FormGroup;
    cityId: string;
    descriptionCity: string;

    searchProductSubject = new Subject<string>();
    searchServicesubject = new Subject<string>();
    changeLocationSubscription: Subscription;

    constructor(
        public translate:TranslateService,
        private userStorageService: UserStorageService,
        private modalsService: ModalsService,
        private formBuilder: FormBuilder,
        private businessService: BusinessService,
        private router: Router) { }

    ngOnInit(): void {
        this.initializeForms();
        this.initialize();
    }

    initialize() {
        this.cityId = this.userStorageService.getUser().idCity;
        this.descriptionCity = this.userStorageService.getUser().descriptionCity;

        this.businessService.initialRequests(this.cityId).subscribe(
            (response: any) => {
                this.products = response[2].items;
                this.services = response[3].items;
                this.productCategories = response[0].items;
                this.serviceCategories = response[1].items;
            }
        )
    }

    initializeForms() {
        this.productForm = this.formBuilder.group({
            search: [''],
            category: []
        });

        this.serviceForm = this.formBuilder.group({
            search: [''],
            category: []
        });

        this.searchProductSubject.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.searchProduct();
            });

        this.searchServicesubject.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.searchService();
            });
    }

    openProduct(id: number) {
        this.router.navigate(['web', 'home', 'ads', 'product', id]);
    }

    openService(id: number) {
        this.router.navigate(['web', 'home', 'ads', 'service', id]);
    }

    searchProduct() {
        let search = this.productForm.get('search').value;
        let category = this.productForm.get('category').value;
        console.log(search);
        this.businessService.searchProducts(search, category, this.cityId).subscribe(
            (response: ScopeListDto<Product>) => {
                this.products = response.items;
            }
        )
    }

    changeCity() {
        this.changeLocationSubscription = LocationModalService.cityChangedEvent.subscribe((city: GooglePlaceDto) => {
            this.cityId = city.id;
            this.descriptionCity = city.description;
            
            this.searchService();
            this.searchProduct();

            this.modalsService.closeModal();
        });

        this.modalsService.openLocationModal();
    }

    searchService() {
        let search = this.serviceForm.get('search').value;
        let category = this.serviceForm.get('category').value;

        this.businessService.searchServices(search, category, this.cityId).subscribe(
            (response: ScopeListDto<Service>) => {
                this.services = response.items;
            }
        )
    }
}