import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../core/angular-material/angular-material.module';
import { SignInComponent } from './signin/signin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../core/components/form-error/form-error.module';
import { CoreModule } from '../core/core.module';
import { FacebookAuthModule } from '../core/facebook-auth/facebook-auth.module';
import { HomeRoutingModule } from './home.routing.module';
import { RouterModule } from '@angular/router';
import { ForgotPasswordModalComponent } from './forgot-password/modal/forgot-password-modal.component';
import { ChangePasswordComponent } from './forgot-password/change-password/change-password.component';
import { RegisterComponent } from './register/register.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatSelectModule, MatSlideToggleModule } from '@angular/material';
import { RegisterByFacebookComponent } from './register-by-facebook/register-by-facebook.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations:[
        HomeComponent,
        SignInComponent,
        ForgotPasswordModalComponent,
        ChangePasswordComponent,
        RegisterComponent,
        RegisterByFacebookComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        FormErrorModule,        
        CoreModule,
        FacebookAuthModule,
        RouterModule,
        HomeRoutingModule,
        GooglePlaceModule,
        MatSlideToggleModule, 
        TranslateModule,
        MatSelectModule       
    ],
    entryComponents: [ForgotPasswordModalComponent]   
})
export class HomeModule{

}