import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Comment } from '../core/models/comment';
import { NumberSymbol } from '@angular/common';
import { OwnerEnum } from './enums/owner-enum';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    updateCommentEvent = new EventEmitter<any>();
    deleteCommentEvent = new EventEmitter<any>();

    constructor(private http: HttpClient) { }

    searchUserOrGroups(search: string) {
        return this.http.get(`searchusersorgroups?searchdata=${search}`);
    }

    saveComment(message: string, ownerEnum: number, galleryId: number, typeGallery: string) {
        let comment = new Comment();
        comment.description = message;
        comment.idGallery = galleryId;
        comment.typeGallery = typeGallery;

        return this.http.post(`userinteraction/comment/${ownerEnum}`, comment);
    }

    editComment(commentId: number, message: string) {
        let comment = new Comment();
        comment.description = message;

        return this.http.put(`userinteraction/comment/${commentId}`, comment);
    }

    deleteComment(commentId: number) {
        return this.http.delete(`userinteraction/comment/${commentId}`);
    }

    toggleLike(ownerEnum: number, galleryId: number) {
        return this.http.patch(`userinteraction/togglelike/${ownerEnum}/${galleryId}`, null);
    }

    buyphotos() {
        let body = { price: -1 };

        return this.http.post('user/buypackagephotos', body);
    }

    sendComplaint(description: string, ownerEnum: OwnerEnum, ownerId: number) {
        let body = { 
            description: description,
            parentEnum: ownerEnum
         };

        switch (ownerEnum) {
            case OwnerEnum.Event:
                body['idEvent'] = ownerId;
                break;
            case OwnerEnum.Group:
                body['idGroup'] = ownerId;
                break;
            case OwnerEnum.Product:
                body['idProduct'] = ownerId;
                break;
                case OwnerEnum.Service:
                body['idService'] = ownerId;
                break;
                case OwnerEnum.User:
                body['idUserComplainted'] = ownerId;
                break;
        }

        return this.http.post('complaint', body);
    }
}