import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateService {
    public removeTime(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    getRangeDate(startDate: Date, endDate: Date): Date[] {
        const dateList: moment.Moment[] = [];
        let currentDate = moment(startDate);
        let finalDate = moment(endDate);
        while (currentDate <= finalDate) {
            dateList.push(moment(currentDate));
            currentDate = moment(currentDate).add(1, 'days');
        }

        return dateList.map(x => this.removeTime(x.toDate()));
    }

    joinDateAndTime(date:Date, time: string): Date {
        let fullDate = moment.utc(date);
        let hour = parseInt(time.split(':')[0]);
        let minutes = parseInt(time.split(':')[1]);

        fullDate = fullDate.hours(hour);
        fullDate = fullDate.minutes(minutes);

        return fullDate.toDate();
    }
}