import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/core/models/product';

@Injectable({providedIn: 'root'})
export class ProductService{
    
    constructor(private http: HttpClient){}

    getProductDetail(id:number){
        return this.http.get(`product/${id}`);
    }

    getProcuctsByUser(){
        return this.http.get('product/getallbyuser');
    }

    createProduct(event:Product){
        return this.http.post('product', event);
    }

    editProduct(id:number, product:Product){
        return this.http.put(`product/${id}`, product);
    }

    delete(id:number){
        return this.http.delete(`product/${id}`);
    }
    
    updateProfileImage(id:number, blob:Blob){
        let formData = new FormData();
        formData.append('file', blob, 'product-image-profile');

        return this.http.post(`product/updatepicture/${id}`, formData);
    }

    getProductCategories(){
        return this.http.get(`categoriesproduct`);
    }    
}