import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GroupService } from '../group.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from 'src/app/core/models/group';
import { StringService } from 'src/app/shared/helpers/string.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './group-new-edit.component.html',
    styleUrls: ['./group-new-edit.component.scss']
})
export class GroupNewEditComponent implements OnInit {
    groupForm: FormGroup;
    options: object;
    id: number;

    constructor(
        public translate:TranslateService,
        private formBuilder: FormBuilder,
        private groupService: GroupService,
        private stringService:StringService, 
        private router: Router,
        private route: ActivatedRoute) { }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });

        this.setFormValidations();

        if (this.id) {
            this.getGroup(this.id);
        }

        this.options = {
            types: ['(cities)']
        }
    }

    setFormValidations() {
        this.groupForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            city: ['', Validators.required],
            idCity: ['', Validators.required],
            descriptionCity: ['', Validators.required],
            definition: [''],
            url: [''],
            biography: ['']
        });
    }

    saveOrEdit() {
        if (this.id) {
            this.groupService.editGroup(this.id, this.generateGroup())
                .subscribe((group: Group) => {
                    this.router.navigate(['web', 'home', 'group', this.id]);
                });
        } else {
            this.groupService.createGroup(
                this.generateGroup()).subscribe(
                    (group: Group) => {
                        this.router.navigate(['web', 'home', 'group', group.id, 'genres']);
                    }
                )
        }
    }

    generateGroup(): Group {
        let group: Group = this.groupForm.getRawValue();

        return group;
    }

    getGroup(id: number) {
        this.groupService.getGroupDetail(id).subscribe(
            (group: Group) => {
                this.setValuesInForm(group);
            }
        );
    }

    setValuesInForm(group: Group) {
        this.groupForm.patchValue({
            ...group,
            city: group.descriptionCity             
        });
    }

    handleAddressChange(param) {
        this.groupForm.get('idCity').patchValue(param.place_id);
        let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
        this.groupForm.get('descriptionCity').patchValue(city);
        this.groupForm.get('city').patchValue(city);
    }

    resetCityData() {
        this.groupForm.get('idCity').patchValue('');
        this.groupForm.get('descriptionCity').patchValue('');
    }
}