import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/core/token/token.service';
import { UserPreferencesService } from 'src/app/core/user-preferences/user-preferences.service';
import { StringService } from 'src/app/shared/helpers/string.service';
import { UserStorageService } from 'src/app/core/user/user-storage.service';
import { CreateUserResponseDto } from 'src/app/core/dtos/create-user-response-dto';
import { User } from 'src/app/core/models/user';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/language/language.service';

@Component({
    templateUrl: './register-by-facebook.component.html',
    styleUrls: ['../home.component.scss', './register-by-facebook.component.scss']
})
export class RegisterByFacebookComponent implements OnInit {
    registerForm:FormGroup;
    createUser:User = new User();

    constructor(
        public translate:TranslateService,
        private languageService: LanguageService,
        private formBuilder: FormBuilder,
        private userService:UserService,
        private router:Router,
        private tokenService:TokenService,
        private userPreferences:UserPreferencesService,
        private stringService:StringService,
        private userStorageService:UserStorageService){}

        ngOnInit(): void {
            this.setFormValidations();
            this.createUser.appId = history.state.data.id;
            this.createUser.fullName = history.state.data.fullName;
            this.createUser.email = history.state.data.email;
            this.createUser.urlProfilePicture = history.state.data.photoUrl;
        }
    
        setFormValidations() {
            this.registerForm = this.formBuilder.group({
                city: ['', Validators.required],
                idCity: ['', Validators.required],
                descriptionCity: ['', Validators.required],
                isMusician: [false, Validators.required]
            });
        }

        create(): void {
            const user = this.registerForm.getRawValue() as User;
            user.appId = this.createUser.appId;
            user.fullName = this.createUser.fullName;
            user.email = this.createUser.email;
            user.urlProfilePicture = this.createUser.urlProfilePicture;
            user.socialNetwork = true;
            user.language = this.languageService.getlanguage();
    
            this.userService.createUser(user)
                .subscribe(
                    (response: CreateUserResponseDto) => {
                        this.successDefaultLogin(response, user.isMusician);
                    }                
                );
        }

        handleAddressChange(param) {
            this.registerForm.get('idCity').patchValue(param.place_id);
            let city = this.stringService.removeNeighborhoodFromPetropolis(param.formatted_address);
            this.registerForm.get('descriptionCity').patchValue(city);
            this.registerForm.get('city').patchValue(city);
        }
    
        resetCityData() {
            this.registerForm.get('idCity').patchValue('');
            this.registerForm.get('descriptionCity').patchValue('');
        }

        generateUser(response: CreateUserResponseDto){
            let user = {
                id: response.id,
                email: response.email,
                fullName: response.fullName,
                urlProfilePicture: response.urlProfilePicture,
                idCity: response.idCity,
                descriptionCity: response.descriptionCity,
                isMusician: response.isMusician
            }
    
            return <User>user;
        }

        private successDefaultLogin(response: CreateUserResponseDto, isMusician:boolean): void {
            this.tokenService.setToken(response.token);
            let user = this.generateUser(response);
            this.userStorageService.setUser(user);
            this.userPreferences.setUserPreferences(user);
    
            this.router.navigate(['web', 'home', 'user', response.id, 'instruments'], { queryParams: { newUser: true } }); 
        }
}