import { NgModule } from '@angular/core';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupNewEditComponent } from './group-new-edit/group-new-edit.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatFormFieldModule, MatButtonModule, MatIconModule, MatAutocompleteModule } from '@angular/material';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MembersListComponent } from './members/members-list/members-list.component';
import { MembersNewComponent } from './members/members-new/members-new.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { BusinessModule } from '../business/business.module';

@NgModule({
  declarations: [
      GroupDetailComponent,
      GroupListComponent,
      GroupNewEditComponent,
      MembersListComponent,
      MembersNewComponent
  ],
  imports:[
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    GooglePlaceModule,
    MatAutocompleteModule,
    TranslateModule,
    UserModule,
    BusinessModule
]  
})
export class GroupModule{

}